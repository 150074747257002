import React from 'react'
import ActionsContainer from './actions/ActionsContainer'

const TableRow = (props) => {
  const { fields, tableFields, row, renderTableRowClass, model, updateTable, page, reload, config, configs } = props
  return (
    <tr className={renderTableRowClass(row)}>
      {tableFields.map((field, key) => {
        field = Object.assign(field, fields.find(f => field === f.alias))
        return (
          <td key={key}>
            {
              ((field.alias === 'actions') && <ActionsContainer {...props} reload={reload} config={config} configs={configs} updateTable={updateTable} page={page} model={model} row={row} actions={field.actions} tableFields={tableFields} />) ||
              (field.render && field.render(row, configs)) ||
              props.row[field.alias]}
          </td>
        )
      })}
    </tr>
  )
}
export default TableRow
