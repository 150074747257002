import React, { Component } from 'react';
import RichTextEditor from 'react-rte';
import { translate as tl } from '../../../../utils/translation';

export default class WYSIWYG extends Component {
  constructor(props) {
    super(props);
    // console.log(props.value);
    this.state = {
      value: RichTextEditor.createEmptyValue()
    };

    this.onChange = this.onChange.bind(this)
  }

  onChange(value) {
    this.setState({ value });
    if (this.props.handleChange) {
      // Send the changes up to the parent component as an HTML string.
      this.props.handleChange(value.toString('html'));
      // console.log(value);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value === undefined && this.props.value) {
      this.setState({
        value: RichTextEditor.createValueFromString(this.props.value, 'html')
      });
    }
  }

  render() {
    const { headline, value } = this.props;

    if (value !== this.state.value) {
    }

    return (
      <div>
        <b>{tl(headline)}</b>
        <div className="form-group">
          <div className="input-group">
            <RichTextEditor
              value={this.state.value}
              onChange={this.onChange}
              className=""
            />
          </div>
        </div>
      </div>
    );
  }
}
