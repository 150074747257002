import React from 'react'
import api from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import {translate as tl} from '../../../../utils/translation'

class Annotation extends React.Component {
  constructor(props) {
    super(props)
    this.row = props.row 
    this.model = props.model 
    this.updateTable = props.updateTable
    this.page = props.page
    this.state = {annotation: this.row.annotation}

    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }
  
  handleChange(event) {
    this.setState({annotation: event.target.value})
  }
  
  handleClick = async () => {
    const data = {'annotation': this.state.annotation}
    try {
      const response = await api(`/${this.model}/${this.row._id}`, 'PUT', null, data)
      notifySuccess(response.data.message)
      this.updateTable({page: this.page})
    } catch (error) {
      handleError(error)
    }
  }

  render() { 
    return (
        <span>
          <div className='modal fade' id={(this.row) ? `annotation${this.row._id}` : 'annotation'} tabIndex='-1' role='dialog'>
            <div className='modal-dialog' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <button style={{position: 'relative', zIndex: 10}} type='button' className='close' data-dismiss='modal'>&times;</button>
                </div>
                <div className='modal-body'>
                  <label>{tl('backend_label_annotation')}:</label>
                  <div>
                    <textarea onChange={this.handleChange} value={this.state.annotation} style={{width: '400px', height: '200px'}} />
                  </div>
                  <div style={{overflow: 'hidden'}}>
                    <button onClick={this.handleClick} className="btn btn-primary" data-dismiss='modal' style={{marginTop: '10px', float: 'right'}}>{tl('backend_field_send')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a>
            <i style={{ 'cursor': 'pointer' }} title={tl('backend_action_annotation')} data-toggle='modal' data-target={`#annotation${this.row._id}`} className='material-icons medium'>{
              this.state.annotation ? 'report_problem' : 'sentiment_satisfied'}
            </i>
          </a>
        </span>
      )
    }
}
export default Annotation
