import React, {Component} from 'react'
import api from '../../utils/api'
import handleError from '../../utils/handleError'
import {translate as tl} from '../../utils/translation'
import Container from '../helpers/Container'

export default class CoreConfigModules extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      active: 0
    }
  }

  async componentDidMount () {
    await this.getData()
  }

  async getData () {
    try {
      const {data: {data}} = await api('/core/admin/companies', 'GET')
      this.setState({
        data: data
      })
    } catch (error) {
      handleError(error, this.props)
    }
  }

  activatePanel(key, e) {
    this.setState({
      active: key
    })
  }

  render () {
    const {active, data: {configs}} = this.state
    return (
      <Container title={tl('backend_page_name_core_companies')}>
        <div className="panel-group" role="tablist" aria-multiselectable="true">
          {
            configs && configs.map((company, key) => {
              return (
                <div key={key} className="panel panel-primary">
                  <div className="panel-heading" role="tab">
                    <h4 className="panel-title">
                      <a role="button" data-toggle="collapse" data-parent="#accordion_1" onClick={(e) => this.activatePanel(key, e)} aria-expanded="true" aria-controls={`collapseOne_${key+1}`}>
                        {company.name}
                      </a>
                    </h4>
                  </div>
                  { active === key &&
                    <div className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_1" aria-expanded="true">
                      <div className="panel-body">
                        <h4>Modules</h4>
                        {company.modules.map((item, index) => `${item}${index < company.modules.length -1 ? ',': ''} `)}
                        <h4>Configs</h4>
                        <table className='table' style={{tableLayout: 'fixed'}}>
                          <thead>
                            <tr>
                              <th>key</th>
                              <th>value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              Object.entries(company.configs).map(([key, value]) => ({key,value})).map((row, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{row.key}</td>
                                    <td style={{wordWrap: 'break-word'}}>{`${row.value}`}</td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                </div>
              )
            })
          }
        </div>
        <div className='clearfix' />
      </Container>
    )
  }
}