import React from 'react'
import api, {removeItem} from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import {translate as tl} from '../../../../utils/translation'

const Delete = (props) => {
  const handleClick = async () => {
    if(props.row.status === 'canceled') {
      return
    }

    const {model, row, updateTable, page} = props

    if (model === 'crm/customers') {
      const data = {'action': 'delete'}
      try {
        const response = await api(`/${model}/${row._id}`, 'PUT', null, data)
        notifySuccess(response.data.message)
        updateTable({page})
      } catch (error) {
        handleError(error, props)
      }
    } else {
      await removeItem(model, row._id)
    }
    updateTable({page})
  }

  return (
    <a>
      <i 
        title={tl('backend_action_delete')} 
        style={{ 
          cursor: props.row.status === 'canceled' ? 'default' : 'pointer',
          color: props.row.status === 'canceled' ? 'gray' : 'inherit',
          opacity: props.row.status === 'canceled' ? 0.5 : 1,
        }} 
        onClick={(e) => { handleClick() }} 
        className='material-icons medium col-pink'
      >
          delete_forever
      </i>
    </a>
  )
}
export default Delete
