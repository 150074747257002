import React from 'react'
import TableRow from './TableRow'
import {translate as tl} from '../../../utils/translation'

const TableBody = (props) => {
  const {config, tableFields, fields, data, renderTableRowClass, model, updateTable, page, reload} = props
  return (
    <tbody>
      {(data.length === 0) && <tr><td colSpan={tableFields.length}>{tl('backend_no_data_available')}</td></tr>}
      {
        data.map((row, key) => {
          return (
            <TableRow {...props} reload={reload} updateTable={updateTable} config={config} page={page} key={row._id} model={model} tableFields={tableFields} fields={fields} row={row} renderTableRowClass={renderTableRowClass} />
          )
        })
      }
    </tbody>
  )
}
export default TableBody
