import React from 'react'
import {translate as tl} from '../../utils/translation'

const BreadCrumbs = ({currentPath, getFiles}) => {
  const handleClick = (index) => {
    const links = currentPath.split('/').filter(link => link)
    const linkUrl = links.filter((link, i) => { return i <= index }).join('/')
    getFiles(`/${linkUrl}`)
  }

  const links = currentPath.split('/').filter(link => link)

  const style = {
    'cursor': 'pointer'
  }

  return (
    <div className='pull-left'>
      <ol className='breadcrumb breadcrumb-col-cyan'>
        <li><a style={style} onClick={() => getFiles('/')}>{tl('backend_files')}</a></li>
        {
          links.map((link, i) => {
            return <li key={i}><a style={style} onClick={() => handleClick(i)}>{link}</a></li>
          })
        }
      </ol>
    </div>
  )
}
export default BreadCrumbs
