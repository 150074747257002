import React from 'react'
import {translate as tl} from '../../../utils/translation'

const TableHead = ({fields, tableFields, sort, order, updateTable}) => {
  return (
    <thead>
      <tr>
        {tableFields.map((field, key) => {
          field = Object.assign(field, fields.find(f => field === f.alias))
          const style = {
            cursor: 'pointer',
            opacity: sort !== field.alias ? 0.3 : 0.8
          }
          return (
            <th key={key} onClick={() => field.sortable && updateTable({
              sort: field.alias,
              order: order === 'asc' ? 'desc' : 'asc',
              page: 1
            })}>
              {tl(field.name)}
              {
                (field.sortable) &&
                (<span className={'glyphicon pull-right' + ((sort !== field.alias) ? ' glyphicon-sort'
                  : (order === 'asc' ? ' glyphicon-sort-by-attributes' : ' glyphicon-sort-by-attributes-alt'))
                } style={style} aria-hidden='true' />)
              }
            </th>
          )
        })}
      </tr>
    </thead>
  )
}
export default TableHead
