import React, { Component } from 'react'
import Modal from '../modal/Modal'
import EditForm from '../form/EditForm'
import api from '../../../utils/api'
import ReactExport from 'react-data-export';
import csv from 'csvtojson'
import downloadjs from 'downloadjs'
import { notifySuccess, notifyError } from '../../../utils/notifications'
import { translate as tl } from '../../../utils/translation'
import cookie from 'react-cookies'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class CardHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadEdit: false,
      exportData: false,
      showCodesImportDialog: false,
      showImportReturnDebitDialog: false,
      showImportSpecialReturnDebitDialog: false,
      readingCodesFile: false,
      filePath: '',
      showFileUpdateDialog: false,
      showUploadDalsonLeadDialog: false,
      fileUpdatePath: '',
      fileReturnDebitPath: '',
      fileReturnSpecialDebitPath: '',
      customerId: -1,
      time: -1,
      salutation: -1,
      firstname: -1,
      lastname: -1,
      date: -1,
      postcode: -1,
      city: -1,
      country: -1,
      address1: -1,
      address2: -1,
      address3: -1,
      address4: -1,
      phoneFixed: -1,
      phoneMobile: -1,
      email: -1,
      list: -1,
      campaign: -1,
      source: -1,
      maxigains: -1,
      costPer000: -1,
      segment: -1,
    }
    this.toggleEdit = this.toggleEdit.bind(this)
    this.handleImportFileChanged = this.handleImportFileChanged.bind(this)
    this.handleImportCodesSubmit = this.handleImportCodesSubmit.bind(this)
    this.onCodesFileReaded = this.onCodesFileReaded.bind(this)
    this.exportLeads = this.exportLeads.bind(this)
    this.updateLeads = this.updateLeads.bind(this)
    this.handleUpdateFileChanged = this.handleUpdateFileChanged.bind(this)
    this.handleUpdateReturnDebitFileChanged = this.handleUpdateReturnDebitFileChanged.bind(this)
    this.handleUpdateSpecialReturnDebitFileChanged = this.handleUpdateSpecialReturnDebitFileChanged.bind(this)
    this.handleUpdateSubmit = this.handleUpdateSubmit.bind(this)
    this.handleImportReturnDebitSubmit = this.handleImportReturnDebitSubmit.bind(this)
    this.handleImportSpecialReturnDebitSubmit = this.handleImportSpecialReturnDebitSubmit.bind(this)
    this.onFileReadedUpdate = this.onFileReadedUpdate.bind(this)
    this.onReturnDebibFileReadedUpdate = this.onReturnDebibFileReadedUpdate.bind(this)
    this.onReturnSpecialDebibFileReadedUpdate = this.onReturnSpecialDebibFileReadedUpdate.bind(this)
    this.uploadDalsonLeads = this.uploadDalsonLeads.bind(this)
    this.onExcelCellSelectChange = this.onExcelCellSelectChange.bind(this)
    this.handleDalsonUploadSubmit = this.handleDalsonUploadSubmit.bind(this)
    this.closeUploadDalsonLeads = this.closeUploadDalsonLeads.bind(this)
    this.importCodes = this.importCodes.bind(this)
    this.exportReport = this.exportReport.bind(this)
    this.importReturnDebit = this.importReturnDebit.bind(this)
    this.importSpecialReturnDebit = this.importSpecialReturnDebit.bind(this)
    this.exportMediaCodeCustomers = this.exportMediaCodeCustomers.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    for (let i = 0; i < 26; ++i) {
      window.$(`#select-${i}`).selectpicker()
    }
  }

  async componentWillMount() {
    await this.setState({
      reload: this.props.reload,
      loadEdit: false
    })
  }

  toggleEdit(bool) {
    this.setState({
      loadEdit: bool
    })
  }

  async exportData(model) {
    let data = await api(`/${model}`, 'GET')

    data.data.data.map(i => {
      Object.keys(i).map(key => {
        i[key] = (i[key] || '').toString()
      })
    })

    this.setState({ export: true, exportData: data.data.data })
  }

  async importCodes() {
    this.setState({ showCodesImportDialog: true })
  }

  async importReturnDebit() {
    this.setState({ showImportReturnDebitDialog: true })
  }

  async importSpecialReturnDebit() {
    this.setState({ showImportSpecialReturnDebitDialog: true })
  }

  async exportReport() {
    try {
      const url = await api('/easyplus_de/report', 'GET', {}, {}, 'easyplus-de')

      window.open(url.data.url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      return notifyError(error.message)
    }
  }

  async exportMediaCodeCustomers() {
    try {
      const url = await api('/easyplus_de/export-media-code-customers', 'GET', {}, {}, 'easyplus-de')

      window.open(url.data.url, '_blank', 'noopener,noreferrer')
    } catch (error) {
      return notifyError(error.message)
    }
  }

  handleImportFileChanged(file) {
    this.setState({ filePath: file })
  }

  async onCodesFileReaded(e) {
    const result = await (await csv({ delimiter: ',' }).fromString(e.target.result)).filter(row => row)

    this.setState({
      readingCodesFile: true,
      showCodesImportDialog: false,
      filePath: '-'
    })

    try {
      await api('/bonusplan24/registrationcodes/import', 'POST', {}, result)
    } catch (error) {
      return notifyError(error.message)
    }

    notifySuccess('Codes uploaded')
    this.props.updateTable()
  }

  handleImportCodesSubmit(e) {
    this.setState({
      readingCodesFile: true
    })
    e.preventDefault()

    if (!this.state.filePath) {
      return
    }

    const fileReader = new FileReader()
    fileReader.onloadend = this.onCodesFileReaded
    fileReader.readAsText(this.state.filePath)
  }

  async exportLeads() {
    try {
      const content = await api('/easyplus_de/export/ongoingmedialeads', 'GET')
      downloadjs(content.data, 'export.csv', 'text/plain')
    } catch (error) {
      notifyError(error.message)
    }
  }

  updateLeads() {
    this.setState({ showFileUpdateDialog: true })
  }

  handleUpdateFileChanged(file) {
    this.setState({ fileUpdatePath: file })
  }

  handleUpdateReturnDebitFileChanged(file) {
    this.setState({ fileReturnDebitPath: file })
  }

  handleUpdateSpecialReturnDebitFileChanged(file) {
    this.setState({ fileReturnSpecialDebitPath: file })
  }

  async onFileReadedUpdate(e) {
    const result = await csv({ delimiter: ';' }).fromString(e.target.result)

    let customerIds = result.reduce((ids, id) => {
      if (id.Status === 'cancelled') {
        ids.push(id.CustomerID)
      }
      return ids
    }, [])

    //remove empty rows
    customerIds = customerIds.filter(n => n)

    try {
      await api('/easyplus_de/cancel/ongoingmedialeads', 'POST', {}, { customerIds })
    } catch (error) {
      return notifyError(error.message)
    }

    notifySuccess('Leads updated')
    this.props.updateTable()
  }

  async onReturnDebibFileReadedUpdate(e) {
    const result = await csv({ delimiter: ',' }).fromString(e.target.result)

    //remove empty rows
    const returnDebits = result.filter(n => n)

    try {
      await api('/easyplus_de/import-return-debits', 'POST', {}, { returnDebits })
    } catch (error) {
      return notifyError(error.message)
    }

    notifySuccess('Customers updated')
    this.props.updateTable()
  }

  async onReturnSpecialDebibFileReadedUpdate(e) {
    const result = await csv({ delimiter: ',' }).fromString(e.target.result)

    //remove empty rows
    const returnDebits = result.filter(n => n)
    
    for(const debit of returnDebits) {
      debit['Customer number'] = debit['TRANSACTION INFORMATION'].slice(0, 7)
      debit['File name'] = debit['IMPORTED FILE NAME'].replace(/ 1\.csv$/, ".csv")
      debit['Chargeback date special'] = debit['CHARGEBACK DATE']
      debit['Chargeback reason'] = debit['ERROR REASON']
    }

    try {
      await api('/easyplus_de/import-return-debits', 'POST', {}, { returnDebits })
    } catch (error) {
      return notifyError(error.message)
    }
    
    notifySuccess('Customers updated')
    this.props.updateTable()
  }

  uploadDalsonLeads() {
    this.setState({ showUploadDalsonLeadDialog: true })
  }

  closeUploadDalsonLeads() {
    this.setState({ showUploadDalsonLeadDialog: false })
  }

  handleUpdateSubmit(e) {
    e.preventDefault()
    this.setState({ showFileUpdateDialog: false })

    if (!this.state.fileUpdatePath) {
      return
    }

    const fileReader = new FileReader()
    fileReader.onloadend = this.onFileReadedUpdate
    fileReader.readAsText(this.state.fileUpdatePath)
  }

  handleImportReturnDebitSubmit(e) {
    e.preventDefault()
    this.setState({ showImportReturnDebitDialog: false })

    if (!this.state.fileReturnDebitPath) {
      return
    }

    const fileReader = new FileReader()
    fileReader.onloadend = this.onReturnDebibFileReadedUpdate
    fileReader.readAsText(this.state.fileReturnDebitPath)
  }

  handleImportSpecialReturnDebitSubmit(e) {
    e.preventDefault()
    this.setState({ showImportSpecialReturnDebitDialog: false })

    if (!this.state.fileReturnSpecialDebitPath) {
      return
    }

    const fileReader = new FileReader()
    fileReader.onloadend = this.onReturnSpecialDebibFileReadedUpdate
    fileReader.readAsText(this.state.fileReturnSpecialDebitPath)
  }

  onExcelCellSelectChange(e) {
    this.setState({ [e.target.name]: parseInt(e.target.value) })
  }

  selectsForExcelCells() {
    const backendFields = [
      'customerId', 'time', 'salutation', 'firstname', 'lastname', 'date', 'postcode', 'city', 'country',
      'address1', 'address2', 'address3', 'address4', 'phoneFixed', 'phoneMobile', 'email', 'list',
      'campaign', 'source', 'maxigains', 'costPer000', 'segment'
    ]

    return backendFields.map((field, idx) => (
      <div key={`excel_select_${idx}`} className='form-group'>
        <div>
          <label htmlFor={field} style={{ marginTop: '10px' }}>{tl(`backend_field_${field}`) + ':'}</label>
        </div>
        <div className="form-group">
          <select id={`select-${idx}`} value={this.state[field]} name={field} onChange={this.onExcelCellSelectChange} className="selectpicker">
            <option value=''>{tl('backend_unfilled')}</option>
            <option value='0'>1</option>
            <option value='1'>2</option>
            <option value='2'>3</option>
            <option value='3'>4</option>
            <option value='4'>5</option>
            <option value='5'>6</option>
            <option value='6'>7</option>
            <option value='7'>8</option>
            <option value='8'>9</option>
            <option value='9'>10</option>
            <option value='10'>11</option>
            <option value='11'>12</option>
            <option value='12'>13</option>
            <option value='13'>14</option>
            <option value='14'>15</option>
            <option value='15'>16</option>
            <option value='16'>17</option>
            <option value='17'>18</option>
            <option value='18'>19</option>
            <option value='19'>20</option>
            <option value='20'>21</option>
            <option value='21'>22</option>
            <option value='22'>23</option>
            <option value='23'>24</option>
            <option value='24'>25</option>
            <option value='25'>26</option>
          </select>
        </div>
      </div>
    ))
  }

  async handleDalsonUploadSubmit(e) {
    e.preventDefault()

    const fileReader = new FileReader()
    fileReader.onloadend = async () => {
      try {
        const buffer = fileReader.result
        const response = await api(`/dalson_trade/leads/import`, 'POST', undefined, {
          buffer,
          customerId: this.state.customerId,
          time: this.state.time,
          salutation: this.state.salutation,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          date: this.state.date,
          postcode: this.state.postcode,
          city: this.state.city,
          country: this.state.country,
          address1: this.state.address1,
          address2: this.state.address2,
          address3: this.state.address3,
          address4: this.state.address4,
          phoneFixed: this.state.phoneFixed,
          phoneMobile: this.state.phoneMobile,
          email: this.state.email,
          list: this.state.list,
          campaign: this.state.campaign,
          source: this.state.sources,
          maxigains: this.state.maxigains,
          costPer000: this.state.costPer000,
          segment: this.state.segment,
        })
        notifySuccess(response.message)

        this.setState({ showUploadDalsonLeadDialog: false })
      } catch (error) {
        notifyError(error.message)
      }
    }
    fileReader.readAsText(this.state.fileUpdatePath)
  }

  render() {
    const { title, createTitle, exportTitle, exportLeadsTitle, reportTitle, exportMediaCodeCustomers, importReturnDebitTitle, updateLeadsTitle, importCodesTitle, uploadDalsonLeadsTitle, config, updateTable } = this.props

    return (
      <div className='header'>
        <style>
          {`
            #headerTitle {
              color: black  !important;
              }
          `}
        </style>
        <h2 id='headerTitle'>{title}</h2>
        {(createTitle || exportTitle  || reportTitle || exportMediaCodeCustomers || importReturnDebitTitle || importCodesTitle || exportLeadsTitle || updateLeadsTitle || uploadDalsonLeadsTitle) &&
          <span>
            <ul className='header-dropdown m-r--5'>
              <li className='dropdown'>
                <a data-toggle='dropdown' role='button' aria-haspopup='true' aria-expanded='false' className='dropdown-toggle'>
                  <i className='material-icons'>more_vert</i>
                </a>
                <ul className='dropdown-menu pull-right'>
                  {createTitle &&
                    <li className='waves-effect waves-block'>
                      <a href='#createModal' onClick={() => { this.toggleEdit(true) }} title={'create'} data-toggle='modal'>
                        <i className='material-icons'>note_add</i>
                        <span>{createTitle}</span>
                      </a>
                    </li>
                  }
                  {exportTitle &&
                    <li className='waves-effect waves-block'>
                      <a onClick={() => { this.exportData(config.model) }}>
                        <i className='material-icons'>note_add</i>
                        <span>{exportTitle}</span>
                      </a>
                    </li>
                  }
                  {reportTitle && cookie.load('__co') === 'easyplus-de' &&
                    <li className='waves-effect waves-block'>
                      <a onClick={() => { this.exportReport() }}>
                        <i className='material-icons'>note_add</i>
                        <span>{reportTitle}</span>
                      </a>
                    </li>
                  }
                  {exportMediaCodeCustomers && cookie.load('__co') === 'easyplus-de' &&
                    <li className='waves-effect waves-block'>
                      <a onClick={() => { this.exportMediaCodeCustomers() }}>
                        <i className='material-icons'>note_add</i>
                        <span>{exportMediaCodeCustomers}</span>
                      </a>
                    </li>
                  }
                  {importReturnDebitTitle && cookie.load('__co') === 'easyplus-de' &&
                    <li className='waves-effect waves-block'>
                      <a onClick={() => { this.importReturnDebit() }}>
                        <i className='material-icons'>note_add</i>
                        <span>{importReturnDebitTitle}</span>
                      </a>
                    </li>
                  }
                  {importReturnDebitTitle && cookie.load('__co') === 'easyplus-de' &&
                    <li className='waves-effect waves-block'>
                      <a onClick={() => { this.importSpecialReturnDebit() }}>
                        <i className='material-icons'>note_add</i>
                        <span>{tl('return_debit_special')}</span>
                      </a>
                    </li>
                  }
                  {importCodesTitle &&
                    <li className='waves-effect waves-block'>
                      <a onClick={() => { this.importCodes() }}>
                        <i className='material-icons'>note_add</i>
                        <span>{importCodesTitle}</span>
                      </a>
                    </li>
                  }
                  {exportLeadsTitle &&
                    <li className='waves-effect waves-block'>
                      <a onClick={this.exportLeads}>
                        <i className='material-icons'>note_add</i>
                        <span>{exportLeadsTitle}</span>
                      </a>
                    </li>
                  }
                  {updateLeadsTitle &&
                    <li className='waves-effect waves-block'>
                      <a onClick={() => { this.updateLeads(config.model) }}>
                        <i className='material-icons'>note_add</i>
                        <span>{updateLeadsTitle}</span>
                      </a>
                    </li>
                  }
                  {uploadDalsonLeadsTitle &&
                    <li className='waves-effect waves-block'>
                      <a onClick={() => { this.uploadDalsonLeads(config.model) }}>
                        <i className='material-icons'>note_add</i>
                        <span>{uploadDalsonLeadsTitle}</span>
                      </a>
                    </li>
                  }
                </ul>
              </li>
            </ul>
            <Modal create={'create'} toggleEdit={this.toggleEdit} model={config.model}>
              {
                (this.state.loadEdit) ? <EditForm updateTable={updateTable} create={'create'} toggleEdit={this.toggleEdit} model={config.model} config={config} /> : null
              }
            </Modal>
          </span>
        }
        {
          this.state.export &&
          <ExcelFile hideElement={true} filename={config.model}>
            <ExcelSheet data={this.state.exportData} name={config.model}>
              <ExcelColumn label='_id' value='_id' />
              {
                config.editFields.map((row, key) => {
                  return (
                    <ExcelColumn key={key} label={row} value={row} />
                  )
                })
              }
            </ExcelSheet>
          </ExcelFile>
        }
        {
          <div>
            <div className='import-modal' style={{ display: this.state.showFileUpdateDialog ? 'block' : 'none' }}>
              <form onSubmit={this.handleUpdateSubmit} className='import-modal-content'>
                <input type='file' accept='.csv' onChange={e => this.handleUpdateFileChanged(e.target.files[0])} />
                <button className='import-button' type='submit'>Upload</button>
                <button className='import-button' type='button' onClick={() => this.setState({ showFileUpdateDialog: false })}>Cancel</button>
              </form>
            </div>
            <div className='import-modal' style={{ display: this.state.showUploadDalsonLeadDialog ? 'block' : 'none' }}>
              <form onSubmit={this.handleDalsonUploadSubmit} className='import-modal-content'>
                <button type="button" className="close" data-dismiss="modal" onClick={this.closeUploadDalsonLeads}>×</button>
                <h2 id='headerTitle' style={{ marginBottom: '40px' }}>Upload file</h2>
                <input style={{ marginBottom: '20px' }} type='file' accept='.csv' onChange={e => this.handleUpdateFileChanged(e.target.files[0])} />
                {this.selectsForExcelCells()}
                <button className='btn btn-primary' type='submit'>Upload</button>
                <button className='btn btn-primary pull-right' type='button' onClick={() => this.setState({ showUploadDalsonLeadDialog: false })}>Cancel</button>
              </form>
            </div>
            <div className='import-modal' style={{ display: this.state.showCodesImportDialog ? 'block' : 'none' }}>
              <form onSubmit={this.handleImportCodesSubmit} className='import-modal-content'>
                <input type='file' accept='.csv' readonly={this.state.readingCodesFile} onChange={e => this.handleImportFileChanged(e.target.files[0])} />
                <button className='import-button' type='submit' disabled={this.state.readingCodesFile}>{this.state.readingCodesFile ? 'Loading...' : 'Upload'}</button>
                <button className='import-button' type='button' disabled={this.state.readingCodesFile} onClick={() => this.setState({ showCodesImportDialog: false })}>Cancel</button>
              </form>
            </div>
            <div className='import-modal' style={{ display: this.state.showImportReturnDebitDialog ? 'block' : 'none' }}>
              <form onSubmit={this.handleImportReturnDebitSubmit} className='import-modal-content'>
                <input type='file' accept='.csv' onChange={e => this.handleUpdateReturnDebitFileChanged(e.target.files[0])} />
                <button className='import-button' type='submit'>Upload</button>
                <button className='import-button' type='button' onClick={() => this.setState({ showImportReturnDebitDialog: false })}>Cancel</button>
              </form>
            </div>
            <div className='import-modal' style={{ display: this.state.showImportSpecialReturnDebitDialog ? 'block' : 'none' }}>
              <form onSubmit={this.handleImportSpecialReturnDebitSubmit} className='import-modal-content'>
                <input type='file' accept='.csv' onChange={e => this.handleUpdateSpecialReturnDebitFileChanged(e.target.files[0])} />
                <button className='import-button' type='submit'>Upload</button>
                <button className='import-button' type='button' onClick={() => this.setState({ showImportSpecialReturnDebitDialog: false })}>Cancel</button>
              </form>
            </div>
          </div>
        }
      </div>
    )
  }
}
