import React from 'react'
import {translate as tl} from '../../utils/translation'

const Footer = (props) => {
  return (
    <div className='legal'>
      <div className='copyright'>© {new Date().getFullYear()}
        <a href='/'> {props.config.title}</a>
      </div>
      <div className='version'></div>
    </div>
  )
}
export default Footer
