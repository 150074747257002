import cookie from 'react-cookies'
import {notifyError} from './notifications'
import {translate as tl} from './translation'

const handleError = (error, props) => {
  const {status} = error.response || error
  if (status === 401) {
    cookie.remove('token', {path: '/'})
    notifyError(`${tl('backend_authentication_error')}`)
    props.history.push('/login')
  } else if(status === 403) {
    notifyError(`${tl('core_permission_denied')}`)
  } else {
    notifyError(error)
  }
}
export default handleError
