import React, {Component} from 'react'
import cookie from 'react-cookies'
import apiUrl from '../../config/apiUrl'
import setConfig from '../../config/app'
import {notifySuccess} from '../../utils/notifications'
import {translate as tl} from '../../utils/translation'

export default class Dropzone extends Component {
  constructor (props) {
    super(props)

    this.state = {
      app: {}
    }
  }

  async componentDidMount () {
    const app = await setConfig()
    this.setState({app})
    const {getFiles} = this.props
    window.Dropzone.options.mydropzone = {
      headers: {
        'Authorization': cookie.load('token'),
        'access-token': app.key
      },
      init: function () {
        this.on('processing', function (file) {
          const baseUrl = window.$('#mydropzone').attr('action')
          this.options.url = baseUrl
        })
        this.on('success', function (file) {
          setTimeout(() => { this.removeFile(file) }, 2000)
          if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
            notifySuccess()
            getFiles()
          }
        })
      }
    }
    window.$('#mydropzone').dropzone()
  }

  render () {
    const {currentPath} = this.props
    
    const companyName = cookie.load('__co')
    const action = `${apiUrl}/files/files?path=${currentPath}`
    
    const style = {
      visibility: 'hidden',
      position: 'absolute',
      top: '0px',
      left: '0px',
      height: '0px',
      width: '0px'
    }

    return (
      <div id='container'>
        <form action={action} method='POST' id='mydropzone' className='dropzone dz-clickable'>
          <div className='dz-message'>
            <div className='drag-icon-cph'>
              <i className='material-icons'>touch_app</i>
            </div>
            <h3>{tl('backend_dropzone_instructions')}</h3>
          </div>
        </form>
        <input type='file' multiple='multiple' className='dz-hidden-input' style={style} />
      </div>
    )
  }
}
