
import React from 'react'
import { withRouter } from 'react-router-dom'

import Steps from '../../statics/helpers/steps/Steps.jsx'
import configs from './config/index.js'


const StepsLayout = ({ module, match }) => {

    const { params } = match

    const stepsData = configs[module.name] ? configs[module.name](module, params) : false

    return stepsData ? <Steps {...stepsData} /> : null

}

export default withRouter(StepsLayout)