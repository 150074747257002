import React, {Component} from 'react'
import api from '../../utils/api'
import handleError from '../../utils/handleError'
import {translate as tl} from '../../utils/translation'
// import {dateWithTime as date} from '../../utils/formatDate'

import Container from '../helpers/Container'

export default class crmEasymillionsStatistics extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      rows: [],
      date: new Date()
    }
  }

  async componentDidMount () {
    await this.getData()
  }

  async getData () {
    try {
      const {data: {data, date}} = await api('/easyplus_fr/statistics/crm/easymillions', 'GET')
      this.setState({
        date: new Date(date),
        data: data,
        rows: data.reduce((object, row) => {
          Object.keys(row).forEach(key => {
            object[key] = object[key] ? [...object[key], row[key]] : [row[key]]
          })
          return object
        }, {})
      })
    } catch (error) {
      handleError(error, this.props)
    }
  }

  toPercent = (value, total) => {
    return ((value / total) * 100).toFixed(2)
  }

  render () {
    return (
      <div>
        <p>{tl('backend_statistics_updated')}: {this.state.date.toLocaleString()}</p>
        <Container title={tl('backend_statistics_title_leads/customers')}>
          <table className='table'>
            <thead>
              <tr>
                <th>{tl('backend_field_campaign')}</th>
                <th>{tl('backend_field_leads')}</th>
                <th>{tl('backend_field_emails')}</th>
                <th>{tl('backend_field_opened_mails')}</th>
                <th>{tl('backend_field_clicked_mails')}</th>
                <th>{tl('backend_field_customers')}</th>
								<th>{tl('backend_field_blacklisted_emails')}</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.data.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>{row.name}</td>
                      <td>{row.leadsCount}</td>
                      <td>{row.emailCount}</td>
                      <td>{row.openedCount + ' (' + (row.emailCount !== 0 ? this.toPercent(row.openedCount, row.emailCount) + '%' : '-') + ')'}</td>
                      <td>{row.clickedCount + ' (' + (row.openedCount !== 0 ? this.toPercent(row.clickedCount, row.openedCount) + '%' : '-') + ')'}</td>
                      <td>{row.customerCount + ' (' + (row.emailCount !== 0 ? this.toPercent(row.customerCount, row.emailCount) + '%' : '-') + ')'}</td>
											<td>{row.blacklistCount  + ' (' + (row.emailCount !== 0 ? this.toPercent(row.blacklistCount, row.emailCount) + '%' : '-') + ')'}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <div className='clearfix' />
        </Container>
      </div>
    )
  }
}
