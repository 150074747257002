import React, {Component} from 'react'
import api from '../../utils/api'
import handleError from '../../utils/handleError'
import {translate as tl} from '../../utils/translation'
import Chart from 'chart.js'

import Container from '../helpers/Container'

export default class crmStatistics extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      rows: [],
      date: new Date()
    }
  }

  async componentDidMount () {
    await this.getData()
    try {
      void new Chart(document.getElementById('line-chart-customer'), {
        type: 'line',
        data: {
          labels: this.state.rows.date,
          datasets: [
            {
              data: this.state.rows.countCustomers,
              label: `${tl('backend_statistics_label_customer')}`,
              borderColor: '#03A9F4',
              fill: false
            }, {
              data: this.state.rows.countContactrequests,
              label: `${tl('backend_statistics_label_contactrequest')}`,
              borderColor: '#377D22',
              fill: false
            }
          ]
        },
        options: {}
      })
    } catch (err) {
      // do nothing
    }
  }

  async getData () {
    try {
      const {data: {data, date}} = await api('/crm/statistics', 'GET')
      this.setState({
        date: new Date(date),
        data: data,
        rows: data.reduce((object, row) => {
          Object.keys(row).forEach(key => {
            object[key] = object[key] ? [...object[key], row[key]] : [row[key]]
          })
          return object
        }, {})
      })
    } catch (error) {
      handleError(error, this.props)
    }
  }

  render () {
    return (
      <div>
        <p>{tl('backend_statistics_updated')}: {this.state.date.toLocaleString()}</p>
        <Container title={tl('backend_statistics_title_customers')}>
          <canvas id='line-chart-customer' />
        </Container>
        <Container title={tl('backend_statistics_title_customers')}>
          <table className='table'>
            <thead>
              <tr>
                <th>{tl('backend_field_date')}</th>
                <th>{tl('backend_field_customers')}</th>
                <th>{tl('backend_field_contactrequests')}</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.data.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>{row.date}</td>
                      <td>{row.countCustomers}</td>
                      <td>{row.countContactrequests}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <div className='clearfix' />
        </Container>
      </div>
    )
  }
}
