import React from 'react'
import {translate as tl} from '../utils/translation'
import cookie from 'react-cookies'
import api from '../utils/api'
import date from '../utils/formatDate'
import currency from '../utils/formatCurrency'

/*******************************************************************************
 * name: name of the function                                                  *
 * func: function without parameters to generate                               *
 * generateFunc: functions with parameters to generate                         *
 *******************************************************************************/

const functions = [
  //data:
  {
    name: 'api_call',
    generateFunc: url => async () => (await api(url, 'GET')).data.data
  },
  {
    name: 'api_call_brands',
    func: async () => (await api('/cashback/brands', 'GET', {active: true})).data
  },
  {
    name: 'api_call_total_count',
    generateFunc: url => async () => (await api(url, 'GET', {status: 'open'})).data.totalCount
  },

  //renderTableRowClass:
  {
    name: 'table_row_empty',
    func: row => {return ''}
  },
  {
    name: 'table_row_status_open_orange_error_red',
    func: row => {
      if(row.status === 'error') return 'bg-red'
      if(row.status === 'open') return 'bg-orange'
      return ''
    }
  },
  {
    name: 'table_row_not_active_orange',
    func: row => {
      if (!row.active) return 'bg-orange'
        return ''
    }
  },
  {
    name: 'table_row_not_active_orange',
    func: row => {
      if (!row.active) return 'bg-orange'
      return ''
    }
  },
  {
    name: 'table_row_deleted_deactivated',
    func: row => {
      if (row.status === 'deleted') return 'bg-red'
      if (row.status === 'deactivated') return 'bg-orange'
      if (row.status === 'canceled') return 'bg-grey'
      return ''
    }
  },
  {
    name: 'table_row_closed_progress',
    func: row => {
      if (row.status === 'closed') return 'bg-green'
      if (row.status === 'in_progress') return 'bg-orange'
      return ''
    }
  },
  {
    name: 'table_row_ard_faqquestion',
    func: row => {
      if(row.active) return 'bg-green'
      return 'bg-grey'
    }
  },

  //render:
  {
    name: 'row_empty_func',
    func: row => {}
  },
  {
    name: 'row_affiliate',
    func: row => {
      if(row.awinId) {
        return 'Awin'
      }

      if(row.tradetrackerId) {
        return 'Tradetracker'
      }

      if(row.belboonId) {
        return 'Belboon'
      }

      return '-'
    }
  },
  {
    name: 'row_amount',
    func: row => `${row.amount} ${tl('dashboard_points')}`
  },
  {
    name: 'row_status_color',
    func: row => {
      const colors = {
        paidoff: {
          value: 'done_all',
          color: 'col-green'
        },
        open: {
          value: 'done',
          color: 'col-grey'
        }
      }
      return (
        <i title={row.status} className={`material-icons ${colors[row.status].color}`}>
          {colors[row.status].value}
        </i>
      )
    }
  },
  {
    name: 'row_status_color_expanded',
    func: row => {
      const colors = {
        open: {
          value: 'done',
          color: 'col-grey'
        },
        paidoff: {
          value: 'done_all',
          color: 'col-green'
        },
        payoff: {
          value: 'done_all',
          color: 'col-grey'
        },
        forecast: {
          value: 'done',
          color: 'col-grey'
        },
        canceled: {
          value: 'cancel',
          color: 'col-pink'
        }
      }
      return (
        <i title={row.status} className={`material-icons ${colors[row.status].color}`}>
          {colors[row.status].value}
        </i>
      )
    }
  },
  {
    name: 'row_bank_paypal',
    func: row => {
      if (row.type === 'bank') {
        return (
          <div>
            <strong>{tl('backend_bank')}</strong><br />
            {tl('backend_owner')}: {row.bankOwner}<br />
            Iban: {row.bankIban}<br />
            Bic: {row.bankBic}
          </div>
        )
      }
      if (row.type === 'paypal') {
        return (
          <div>
            <strong>PayPal</strong><br />
            {tl('backend_owner')}: {row.paypalOwner}<br />
            {tl('backend_email')}: {row.paypalEmail}
          </div>
        )
      }
      return ''
    }
  },
  {
    name: 'row_cancelled',
    func: row => {
      if(row.status === 'deactivated') {
        return 'terminated'
      }
      return row.status
    }
  },
  {
    name: 'row_created_at',
    generateFunc: (withSpan) => row => { return withSpan ? (<span>{`${date(row.createdAt)}`}</span>) : date(row.createdAt)}
  },
  {
    name: 'row_created_at_with_time',
    generateFunc: (withSpan) => row => { return withSpan ? (<span>{`${new Date(row.createdAt).toLocaleString('de-DE')}`}</span>) : date(row.createdAt)}
  },
  {
    name: 'row_updated_at',
    generateFunc: (withSpan) => row => { return withSpan ? (<span>{`${date(row.updatedAt)}`}</span>) : date(row.updatedAt)}
  },
  {
    name: 'row_custom_date',
    generateFunc: (attribute) => row => (<span>{`${date(row[attribute])}`}</span>)
  },
  {
    name: 'row_title',
    generateFunc: (attribute) => row => (<span>{row && row[attribute] ? row[attribute].title : '-'}</span>)
  },
  {
    name: 'row_yes_no',
    generateFunc: (attribute) => row => { return (<span>{row && row[attribute] ? `${tl('backend_yes')}` : `${tl('backend_no')}`}</span>) }
  },
  {
    name: 'row_brand_title',
    func: row => row.cashback_brand?.title ? row.cashback_brand.title : '-'
  },
  {
    name: 'row_cashback_customer_customer_customerId',
    func: row => row.cashback_customer.crm_customer.customerId
  },
  {
    name: 'row_email_campaign_title',
    func: row => row.email_campaign && row.email_campaign.title
  },
  {
    name: 'row_mediaCode',
    func: row => row.mediaCode && row.mediaCode.code
  },
  {
    name: 'row_welcomeEmailStatus',
    func: row => row.welcomeEmail && row.welcomeEmail.status
  },
  {
    name: 'row_currency',
    generateFunc: (attribute) => (row, configs) =>  currency(row[attribute], configs.find(co => co.key === cookie.load('__co')))
  },
  {
    name: 'row_sum',
    generateFunc: values => (row, configs) =>  <div>{values.reduce((sum, value) => {sum += row[value] ? row[value] : 0; return sum}, 0)}</div>
  },
  {
    name: 'row_currency_balance',
    func: (row, configs) =>  { return (<div>{ currency(row.cashback_customer.balance, configs.find(co => co.key === cookie.load('__co')))}</div>) }
  },
  {
    name: 'row_currency_price',
    func: (row, configs) => { return (<span>{row && currency(row.price, configs.find(co => co.key === cookie.load('__co')))}</span>) }
  },
  {
    name: 'row_username',
    func: row => {
      if (!row.core_user) {
        return '-'
      }

      return row.core_user.username
    }
  },
  {
    name: 'row_status_open_red',
    func: row => row.status === 'open' ? 'bg-red' : ''
  },
  {
    name: 'row_firstname_lastname',
    generateFunc: (withSalutation) => row => {
      if(withSalutation) return (<span>{`${row.salutation || ''} ${row.firstname || ''} ${row.lastname || ''}`}</span>)
      return (<span>{`${row.firstname || ''} ${row.lastname || ''}`}</span>)
    }
  },
  {
    name: 'row_alias',
    func: row => { return (<span>{`${row.alias || ''}/${row.alias2 || ''}/${row.alias3 || ''}`}</span>) }
  },
  {
    name: 'row_used',
    func: row => { return (<span>{row.used ? 'Yes' : 'No'}</span>) }
  },
  {
    name: 'row_raffle_title',
    func: row => row.raffle.title
  },
  {
    name: 'row_date',
    func: row => { return (<span>{`${date(row.date)}`}</span>) },
  },
  {
    name: 'row_purchased_at',
    func: row => { return (<span>{`${date(row.purchasedAt)}`}</span>) },
  },
  {
    name: 'row_duration',
    func: row => (<span>{row.end ? (new Date(row.end) - new Date(row.start)) / 1000 + 's': '-' }</span>) 
  },
  {
    name: 'row_attribute',
    generateFunc: (attributes) => row => (<span>{row && row[attributes[0]] && row[attributes[0]][attributes[1]]}</span>)
  },
  {
    name: 'row_number',
    generateFunc: (attribute) => row => (<span>{row && row[attribute] ? row[attribute].toString() : '-'}</span>)
  },

  //condition:
  {
    name: 'condition_true',
    func: row => true
  },
  {
    name: 'condition_status_is',
    generateFunc: (status) => row => row.status === status
  },
  {
    name: 'condition_media_code',
    func: row => row.mediaCode !== undefined
  },
  {
    name: 'condition_status_is_not',
    generateFunc: (status) => row => row.status !== status
  },
  {
    name: 'condition_not',
    generateFunc: (attribute) => row => !row[attribute]
  },
]

export default functions
