import React, { Component } from 'react'
import api from '../../utils/api'
import handleError from '../../utils/handleError'
import { translate as tl } from '../../utils/translation'

import Container from '../helpers/Container'

export default class EmailCampaignsStatistics extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      date: new Date()
    }
  }

  async componentDidMount() {
    try {
      const { data: { data, date } } = (await api('/email/statistics/campaigns', 'GET'))

      this.setState({
        data: data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
        date: new Date(date)
      })
    } catch (error) {
      handleError(error, this.props)
    }
  }


  toPercent = (value, total) => {
    return ((value / total) * 100).toFixed(2)
  }

  render() {
    return (
      <div>
        <p>{tl('backend_statistics_updated')}: {this.state.date.toLocaleString()}</p>
        <Container title={tl('backend_statistics_title_email/campaigns')}>
          <table className='table'>
            <thead>
              <tr>
                <th>{tl('backend_field_campaign')}</th>
                <th>{tl('backend_field_emails')}</th>
                <th>{tl('backend_field_opened_mails')}</th>
                <th>{tl('backend_field_clicked_mails')}</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.data.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>{row.name}</td>
                      <td>{row.emailCount}</td>
                      <td>{row.openedCount + ' (' + (row.emailCount !== 0 ? this.toPercent(row.openedCount, row.emailCount) + '%' : '-') + ')'}</td>
                      <td>{row.clickedCount + ' (' + (row.openedCount !== 0 ? this.toPercent(row.clickedCount, row.openedCount) + '%' : '-') + ')'}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <div className='clearfix' />
        </Container>
      </div>
    )
  }
}
