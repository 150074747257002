import React, {Component} from 'react'
import Dropzone from './Dropzone'
import FileTable from './FileTable'
import api from '../../utils/api'
import swal from 'sweetalert'
import {notifySuccess} from '../../utils/notifications'
import handleError from '../../utils/handleError'
import {translate as tl} from '../../utils/translation'
import Container from '../helpers/Container'

export default class FilesContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      model: props.config.model,
      currentPath: '',
      files: []
    }
    this.getFiles = this.getFiles.bind(this)
    this.deleteFile = this.deleteFile.bind(this)
  }

  async componentWillMount () {
    await this.getFiles(`/${this.state.currentPath}`)
  }

  async getFiles (path = this.state.currentPath) {
    try {
      const response = await api('/files/files', 'GET', {path})
      this.setState({
        currentPath: path,
        files: response.data.data.files
      })
    } catch (error) {
      handleError(error, this.props)
    }
  }

  async deleteFile (path, file) {
    const fullPath = `${path}/${file}`
    const willDelete = await swal({
      title: `${tl('backend_are_you_sure')}?`,
      text: `${tl('backend_are_you_sure_you_want_to_delete')} /${file} ${tl('backend_from')} ${(path === '/') ? '/files' : path}?`,
      icon: 'warning',
      buttons: true,
      dangerMode: true
    })

    if (!willDelete) {
      return
    }

    try {
      const response = await api('/files/files', 'DELETE', {path: fullPath})
      notifySuccess(response)
      this.getFiles(path)
    } catch (error) {
      handleError(error, this.props)
    }
  }

  render () {
    const {title, createTitle, config} = this.props
    const {currentPath, files} = this.state

    return (
      <div>
        <Container updateTable={this.updateTable} title={title} createTitle={createTitle} config={this.props.config}>
          <Dropzone currentPath={currentPath} getFiles={this.getFiles} /><br />
          <FileTable config={config} files={files} currentPath={currentPath} getFiles={this.getFiles} deleteFile={this.deleteFile} />
        </Container>
      </div>
    )
  }
}
