import React, {Component} from 'react'
import TableSearch from '../helpers/table/TableSearch'
import TableHead from '../helpers/table/TableHead'
import BreadCrumbs from './BreadCrumbs'
import {notifySuccess} from '../../utils/notifications'
import {translate as tl} from '../../utils/translation'

export default class FileTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      query: ''
    }
    this.searchTable = this.searchTable.bind(this)
  }

  async searchTable (obj) {
    const query = obj.search
    await this.setState({query})
  }

  copyRoute (text) {
    const dummyInput = document.createElement('input')
    document.body.appendChild(dummyInput)
    dummyInput.setAttribute('id', 'dummy_id')
    document.getElementById('dummy_id').value = text
    dummyInput.select()
    document.execCommand('Copy')
    document.body.removeChild(dummyInput)
    notifySuccess('Route copied to clipboard')
  }

  render () {
    let {files, getFiles, deleteFile, currentPath} = this.props
    const {tableFields, fields, renderTableRowClass} = this.props.config
    const {query} = this.state

    const style = {
      cursor: 'pointer',
      padding: '3px'
    }

    if (query) {
      files = files.filter(file => ~file.alias.toLowerCase().indexOf(query.toLowerCase()))
    }

    const showPreview = (fileUrl) => {
      const acceptedFormats = ['jpg', 'jpeg', 'png', 'gif']
      if (fileUrl) {
        return acceptedFormats.find(format => format === fileUrl.slice((fileUrl.lastIndexOf('.') - 1 >>> 0) + 2))
      }
      return false
    }

    return (
      <div>
        <BreadCrumbs files={files} currentPath={currentPath} getFiles={getFiles} />
        <TableSearch updateTable={this.searchTable} />
        <div className='table-responsive'>
          <table className='table col-xs-12 table-hover'>
            <TableHead tableFields={tableFields} fields={fields} />
            <tbody>
              {(files.length === 0) && <tr><td colSpan={tableFields.length}>{tl('backend_no_data_available')}</td></tr>}
              {
                files.map((file, index) => {
                  const fullPath = `${currentPath}${(currentPath === '/') ? `${file.alias}` : `/${file.alias}`}`
                  return (
                    <tr className={renderTableRowClass()} key={index}>
                      {tableFields.map((field, key) => {
                        field = Object.assign(field, fields.find(f => field === f.alias))
                        return (
                          <td key={key}>
                            {
                              ((field.alias === 'preview' && showPreview(file.url)) && <a href={file.url} target='_blank'><img style={{'width': '50px'}} src={file.url} alt={file.url} className='img-responsive' /></a>) ||
                            ((field.alias === 'file') && ((file.url) ? <span>{file.alias}</span>
                              : <a style={style} onClick={() => getFiles(fullPath)}>{file.alias}</a>)) ||
                            ((field.alias === 'actions') &&
                            <span>
                              {
                                file.url && <a><i title={tl('backend_action_copy_clipboard')} style={style} onClick={(e) => { this.copyRoute(file.url) }} className='material-icons medium'>content_copy</i></a>
                              }
                              <a><i title={tl('backend_action_delete')} style={style} onClick={(e) => { deleteFile(currentPath, file.alias) }} className='material-icons medium col-pink'>delete_forever</i></a>
                            </span>
                            )
                            }
                          </td>
                        )
                      })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <div className='clearfix' />
      </div>
    )
  }
}
