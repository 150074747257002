import React from 'react'

const Userinfo = ({user: { firstname, lastname, username }}) => {
  return (
    <div className='user-info'>
      <div className='image'>
        <img src='/images/user.png' width='48' height='48' alt='User' />
      </div>
      <div className='info-container'>
        <div className='name'>{firstname} {lastname}</div>
        <div className='username'>{username}</div>
      </div>
    </div>
  )
}

export default Userinfo
