import React from 'react'
import date from '../../../../utils/formatDate'
import {translate as tl} from '../../../../utils/translation'

const DatePicker = ({ headline, name, value, isDisabled, handleChange, datePickerInit }) => {
  return (
    <div>
      <b>{tl(headline)}</b>
      <div className='form-group'>
        <div className='input-group'>
          <div className='form-line'>
            <input ref={datePickerInit} type='text' id={name} name={name} value={(value && date(value)) || ''} disabled={isDisabled} onChange={handleChange} className='form-control date-picker' aria-invalid='false' />
          </div>
        </div>
      </div>
    </div>
  )
}
export default DatePicker
