import React from 'react'
import {translate as tl} from '../../../utils/translation'

const TableNavigation = ({ page, totalCount, pagesize, limits, updateTable }) => {
  const style = {
    cursor: 'pointer',
    userSelect: 'none'
  }

  const pages = Math.ceil(totalCount / pagesize)
  const firstItem = page * pagesize - pagesize + 1
  const lastItem = page * pagesize <= totalCount ? page * pagesize : totalCount
  const previousButtonClass = 'paginate_button previous ' + (page === 1 || totalCount === 0 ? 'disabled' : '')
  const previousButton = (
    <li className={previousButtonClass} onClick={() => updateTable({page: page - 1})}><a>{tl('backend_previous')}</a></li>
  )

  const nextButtonClass = 'paginate_button next ' + (page === pages || totalCount === 0 ? 'disabled' : '')
  const nextButton = (
    <li className={nextButtonClass} onClick={() => { updateTable({page: page + 1}) }}><a>{tl('backend_next')}</a></li>
  )

  let paginationList = []

  // Small size pagination
  if (pages <= 5) {
    paginationList = Array(pages).fill(0).map((x, i) => {
      return {value: i + 1, className: page - 1 === i ? ' active' : ''}
    })
  }
  // Left edge pagination
  else if (page >= 1 && page < 5) {
    paginationList = Array(5).fill(0).map((x, i) => {
      return {value: i + 1, className: page - 1 === i ? ' active' : ''}
    })
    paginationList.push({value: '...', className: ' disabled'})
    paginationList.push({value: pages})
  }
  // Right edge pagination
  else if (page > pages - 4 && page <= pages) {
    paginationList = Array(5).fill(0).map((x, i) => {
      return {value: pages - i, className: page + i === pages ? ' active' : ''}
    })
    paginationList.push({value: '...', className: ' disabled'})
    paginationList.push({value: 1})

    paginationList.reverse()
  }
  // Middle pagination
  else {
    paginationList.push({value: 1})
    paginationList.push({value: '...', className: ' disabled'})
    paginationList.push({value: page - 1})
    paginationList.push({value: page, className: ' active'})
    paginationList.push({value: page + 1})
    paginationList.push({value: '...', className: ' disabled'})
    paginationList.push({value: pages})
  }

  return (
    <div>
      <p className='col-xs-12'>
        {limits.map((value, key) => {
          return (
            <span style={style} key={key} className={'label' + (pagesize === value ? ' bg-light-blue' : ' bg-grey')} onClick={() => updateTable({pagesize: value, page: 1})}>
              {value}
            </span>)
        })
        }
        <span className='pull-right'>{tl('backend_pagination_x1_x2_x3', [{key: 1, value: firstItem}, {key: 2, value: lastItem}, {key: 3, value: totalCount}])}</span>
      </p>
      <nav className='col-xs-12'>
        <ul className='pagination pull-right'>
          {previousButton}
          {paginationList.map((element, i) => {
            return (
              <li key={i} className={'paginate_button' + element.className} onClick={() => updateTable({page: element.value})}>
                <a style={style}>
                  {element.value}
                </a>
              </li>
            )
          })}
          {nextButton}
        </ul>
      </nav>
    </div>
  )
}
export default TableNavigation
