import React from 'react'
import CardHeader from '../card/CardHeader'
import {translate as tl} from '../../../utils/translation'

const Modal = ({model, row, create, toggleEdit, children}) => {
  return (
    <div className='modal fade' id={(row) ? row._id : 'createModal'} tabIndex='-1' role='dialog'>
      <div className='modal-dialog modal-lg' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <button onClick={() => toggleEdit(false)} style={{position: 'relative', zIndex: 10}} type='button' className='close' data-dismiss='modal'>&times;</button>
            <CardHeader title={`${(create) ? `${tl('backend_modal_form_create')}` : `${tl('backend_modal_form_edit')}`} ${model}`} />
          </div>
          <div className='modal-body'>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Modal
