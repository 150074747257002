import React from 'react'
import {translate as tl} from '../../../../utils/translation'

const Link = (props) => {
  return (
    <a href={props.row.url} target="_blank" rel="noopener noreferrer">
      <i title={tl('backend_field_link')} style={{ 'cursor': 'pointer' }} className='material-icons medium'>open_in_new</i>
    </a>
  )
}
export default Link
