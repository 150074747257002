import React from 'react'
import api from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import {translate as tl} from '../../../../utils/translation'

const ResendConfirmation = (props) => {
  const handleClick = async () => {
    const {model, row, updateTable, page} = props
    const data = {'action': 'resend_confirmation'}
    try {
      const response = await api(`/${model}/${row._id}`, 'PUT', null, data)
      notifySuccess(response.data.message)
      updateTable({page})
    } catch (error) {
      handleError(error, props)
    }
  }

  return (
    <a>
      <i title={tl('backend_action_resend_confirmation')} style={{ 'cursor': 'pointer' }} onClick={(e) => { handleClick() }} className='material-icons medium col-green'>mail</i>
    </a>
  )
}
export default ResendConfirmation
