import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import cookie from 'react-cookies'
import api from '../utils/api'
import {notifySuccess} from '../utils/notifications'
import { translate as tl } from '../utils/translation'

export default class Login extends Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.apiLogin = this.apiLogin.bind(this)
  }

  componentWillMount () {
    this.props.setupPage({title: 'Thunderchild', favicon: '/favicon.ico'})
  }

  handleFormChange (element) {
    if (element.username) {
      this.setState({username: element.username.value})
    }

    if (element.password) {
      this.setState({password: element.password.value})
    }
  }

  async apiLogin (e) {
    e.preventDefault()

    try {
      const response = await api(`/core/admin/login`, 'POST', {}, {
        username: this.state.username,
        password: this.state.password
      }, 'thunderchild-de')

      const current = new Date()
      current.setHours(current.getHours() + 6)
      cookie.save('token', response.data.data, {path: '/', expires: current})

      this.setState({error: undefined})

      notifySuccess(`${tl('backend_login_correct')}`)
    } catch (error) {
      this.setState({
        error: 'backend_wrong_username_password'
      })
    }
  }

  render () {
    if (cookie.load('token')) {
      return <Redirect to='/dashboard' />
    }

    return (
      <div className='login-page-wrapper'>
        <div className='login-page'>
          <div className='login-box'>
            <div className='logo'>
              <a>
                Thunderchild
              </a>
            </div>
            <div className='card'>
              <div className='body'>
                {this.state.error && <div className='alert bg-red'>{tl(this.state.error)}</div>}
                <form id='sign-in' noValidate='novalidate'>
                  <div className='msg'>Login</div>
                  <div className='input-group'>
                    <span className='input-group-addon'>
                      <i className='material-icons'>person</i>
                    </span>
                    <div className='form-line'>
                      <input type='text' name='username' placeholder={`${tl('backend_form_username')}`} value={this.state.username} onChange={event => this.handleFormChange({username: {value: event.target.value}})} required autoFocus aria-required='true' className='form-control' />
                    </div>
                  </div>
                  <div className='input-group'>
                    <span className='input-group-addon'>
                      <i className='material-icons'>lock</i>
                    </span>
                    <div className='form-line'>
                      <input type='password' placeholder={`${tl('backend_form_password')}`} required aria-required='true' className='form-control' value={this.state.password} onChange={event => this.handleFormChange({password: {value: event.target.value}})} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-xs-8'></div>
                    <div className='col-xs-4'>
                      <button type='submit' className='btn btn-block bg-light-blue waves-effect' onClick={this.apiLogin}>{tl('backend_button_login')}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
