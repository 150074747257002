import React, {Component} from 'react'
import cookie from 'react-cookies'
import CardHeader from '../../card/CardHeader'
import date from '../../../../utils/formatDate'
import currency from '../../../../utils/formatCurrency'
import api from '../../../../utils/api'
import {translate as tl} from '../../../../utils/translation'
import handleError from '../../../../utils/handleError'
import autosize from 'autosize'

export default class ViewOrder extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  async loadData (id) {
    try {
      let orderData
      let productappointments = []

      const response = await api(`/shop_mediamarkt/orders/${id}`, 'GET')
      orderData = response.data.data

      for (let item of orderData.productappointments) {
        const response2 = await api(`/shop_mediamarkt/productappointments/${item.productappointment}`, 'GET', {populate: 'product'})
        const data = response2.data.data

        productappointments.push({count: item.count, discount: item.discount, ...data})
      }

      this.setState({
        productappointments: productappointments
      })
    } catch (error) {
      handleError(error, this.props)
    }
  }

  render () {
    const {$} = window
    autosize($('textarea'))

    const {row} = this.props
    const {productappointments} = this.state

    const ListItem = ({title, data}) => {
      const style = {
        display: 'inline-block',
        minWidth: '20%'
      }
      return <li className='list-group-item'><span style={style}>{tl(title)}:</span> {data}</li>
    }

    const company = this.props.configs.find(i => i.key === cookie.load('__co'))
    return (
      <span>
        <div className='modal fade' id={(row) ? `view${row._id}` : 'viewCustomerModal'} tabIndex='-1' role='dialog'>
          <div className='modal-dialog modal-lg' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button style={{position: 'relative', zIndex: 10}} type='button' className='close' data-dismiss='modal'>&times;</button>
                <CardHeader title={`${(row.salutation) ? row.salutation : ''} ${(row.firstname) ? row.firstname : ''} ${(row.lastname) ? row.lastname : ''}`} />
              </div>
              <div className='modal-body'>
                <ul className='nav nav-tabs tab-nav-right' role='tablist'>
                  <li role='presentation' className='active'>
                    <a href={`#basicInfo${row._id}`} data-toggle='tab' aria-expanded='true'>{tl('backend_view_order_title_basic_information')}</a>
                  </li>
                  <li role='presentation' className=''>
                    <a href={`#order${row._id}`} data-toggle='tab' aria-expanded='false'>{tl('backend_view_order_title_orders')}</a>
                  </li>
                </ul>
                <div className='tab-content'>
                  <div role='tabpanel' className='tab-pane animated fadeIn active' id={`basicInfo${row._id}`}>
                    <ul className='list-group'>
                      <ListItem title='backend_field_id' data={row.orderId} />
											<ListItem title='backend_field_clubnumber' data={row.clubnumber} />
                      <ListItem title='backend_field_firstname' data={row.firstname} />
                      <ListItem title='backend_field_lastname' data={row.lastname} />
                      <ListItem title='backend_field_address' data={row.street} />
                      <ListItem title='backend_field_city' data={row.city} />
                      <ListItem title='backend_field_postcode' data={row.postcode} />
                      <ListItem title='backend_field_country' data={row.city} />
                      <ListItem title='backend_field_email' data={row.email} />
                      <ListItem title='backend_field_birthdate' data={date(row.dateOfBirth)} />
                      <ListItem title='backend_field_phone' data={row.phone} />
                      <ListItem title='backend_field_created_at' data={date(row.createdAt)} />
                      <ListItem title='backend_field_status' data={row.status} />
                    </ul>
                  </div>
                  <div role='tabpanel' className='tab-pane animated fadeIn' id={`order${row._id}`}>
                    <ul className='list-group'>
                      <ListItem title='Price' data={currency(row.price, company)} />
                    </ul>
                    <CardHeader title='Products' />
                    <div className='table-responsive'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>{tl('backend_field_product')}</th>
                            <th>{tl('backend_field_date')}</th>
                            <th>{tl('backend_field_time')}</th>
                            <th>{tl('backend_field_count')}</th>
                            <th>{tl('backend_field_price')}</th>
                            <th>{tl('backend_field_discount')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (productappointments && productappointments.length > 0) ? productappointments.map((productappointment, key) => {
                              return (
                                <tr key={key}>
                                  <td>{productappointment.product && productappointment.product.title}</td>
                                  <td>{date(productappointment.date)}</td>
                                  <td>{productappointment.information}</td>
                                  <td>{productappointment.count}</td>
                                  <td>{currency(productappointment.product && productappointment.product.price, company)}</td>
                                  <td>{currency(productappointment.discount, company)}</td>
                                </tr>
                              )
                            })
                              : <tr><td colSpan='4'>{tl('backend_no_data_available')}</td></tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a>
          <i onClick={() => this.loadData(row._id)} style={{ 'cursor': 'pointer' }} title={tl('backend_view_order')} data-toggle='modal' data-target={`#view${row._id}`} className='material-icons medium'>visibility</i>
        </a>
      </span>
    )
  }
}
