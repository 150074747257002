import React, {Component} from 'react'
import api from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import {translate as tl} from '../../../../utils/translation'

export default class ViewPermission extends Component {
  constructor(props) {
    super(props)

    this.state = {
      permissions: [],
      companies: []
    }

    this.showPermissions = this.showPermissions.bind(this)
    this.showCompanies = this.showCompanies.bind(this)
    this.handlePermissionChange = this.handlePermissionChange.bind(this)
    this.handleComapanyChange = this.handleComapanyChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async componentWillMount() {
    const {row, configs} = this.props
    try {
      const responseAvailablePermissions = await api('/core/admin/availablePermissions', 'GET')

      const permissions = responseAvailablePermissions.data.data.map(permission => {
        const userPermission = row.permissions ? row.permissions.find(userPermission => userPermission.area === permission) : undefined
        const accessLevel = userPermission ? userPermission.accessLevel : ''
        return { area: permission, accessLevel }
      })

      const companies = configs.map(config => {
        return {
          name: config.key,
          activated: !!row.companies.find(company => company === config.key)
        }
      })

      this.setState({
        permissions,
        companies
      })
    } catch (error) {
      handleError(error, this.props)
    }
  }

  handlePermissionChange(area) {
    return newValue => {
      this.setState({
        permissions: this.state.permissions.map(permission => {
          return permission.area === area ? {
            area: permission.area,
            accessLevel: newValue.target.value
          }
          : permission
        })
      })
    }
  }

  handleComapanyChange(companyName) {
    return newValue => {
      this.setState({
        companies: this.state.companies.map(company => {
          return company.name === companyName ? {
            name: company.name,
            activated: newValue.target.checked
          }
          : company
        })
      })
    }
  }

  showPermissions() {
    return this.state.permissions.map((permission, index) => (<div key={index} style={{width: '100%'}}>
      <label style={{width: '40%'}}>{permission.area}</label>
      <select style={{width: '50%'}} onChange={this.handlePermissionChange(permission.area)} value={permission.accessLevel}>
        <option value=''></option>
        <option value='read'>Read</option>
        <option value='write'>Write</option>
      </select>
    </div>))
  }

  showCompanies() {
    return this.state.companies.map((company, index) => (<div key={index} style={{width: '100%'}}>
      <label style={{width: '60%'}}>{company.name}</label>
      <input type='checkbox' checked={company.activated} onChange={this.handleComapanyChange(company.name)}/>
    </div>))
  }

  async handleSubmit() {
    const {row} = this.props

    try {
      const userPermissions = this.state.permissions.filter(permission => permission.accessLevel)
      const userCompanies = this.state.companies.filter(company => company.activated)
                                                .map(company => company.name)

      await api('/core/users/' + row._id, 'PUT', {}, {
        ...row,
        permissions: userPermissions,
        companies: userCompanies
      })
      notifySuccess()
    } catch(error) {
      handleError(error, this.props)
    }
  }

  render() {
    const {row} = this.props
    return (<span>
      <div className='modal fade' id={(
          row)
          ? `viewpermission${row._id}`
          : 'viewPermissionModal'} tabIndex='-1' role='dialog'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content' style={{paddingBottom: '30px'}}>
            <div className='modal-header'>
              <h2>Permissions</h2>
              <button style={{
                  position: 'relative',
                  zIndex: 10
                }} type='button' className='close' data-dismiss='modal'>&times;</button>
            </div>
            <div className='modal-body'>
              <h3>Groups</h3>
              {this.showPermissions()}
              <h3>Companies</h3>
              {this.showCompanies()}
              <button className='btn btn-primary pull-right' onClick={this.handleSubmit}>Save</button>
            </div>
          </div>
        </div>
      </div>
      <a>
        <i style={{
            'cursor' : 'pointer'
          }} title={tl('backend_action_preview_log')} data-toggle='modal' data-target={`#viewpermission${row._id}`} className='material-icons medium'>lock</i>
      </a>
    </span>)
  }
}
