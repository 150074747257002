
import React, { useEffect, useState } from 'react'
import Checkbox from '../../../statics/helpers/form/field/Checkbox'
import api from '../../../utils/api'
import { notifySuccess, notifyError } from '../../../utils/notifications'
import { translate as tl } from '../../../utils/translation'


const useDalsonTrade = (module, params) => {

    const [config, setConfig] = useState(null)
    const [reload, setReload] = useState(false)

    //UTILS
    const onStepUpdate = async (stepName) => {
        try {
            await api(`/dalson_trade/leadProcess/${params.id}`, 'put', undefined, { status: stepName })
            setReload(!reload)
        } catch (error) {
            notifyError(error.message)
        }
    }

    // STEPS COMPONENTS
    const UploadStep = () => {

        const [formValues, setFormValues] = useState({})
        const [filePath, setFilePath] = useState(false)
        const [checked, setChecked] = useState(false)

        useEffect(() => {
            for (let i = 0; i < 26; ++i) {
                window.$(`#select-${i}`).selectpicker()
            }
        }, [])

        const backendFields = [
            'customerId', 'time', 'salutation', 'firstname', 'lastname', 'date', 'postcode', 'city', 'country',
            'address1', 'address2', 'address3', 'address4', 'phoneFixed', 'phoneMobile', 'email', 'list',
            'campaign', 'source', 'maxigains', 'costPer000', 'segment'
        ]

        const handleChage = (el) => {
            el.persist()

            setFormValues(prev => {
                const val = ({ ...prev, [el.target.name]: parseInt(el.target.value) })
                return val
            })
        }

        const handleUpload = async (e) => {

            e.preventDefault()

            const formUpdatedValues = backendFields.reduce((accum, item) => {
                accum[item] = formValues[item]
                return accum
            }, {})

            formUpdatedValues.leadProcess = params.id

            const fileReader = new FileReader()
            fileReader.onloadend = async () => {
                try {
                    const buffer = fileReader.result
                    const response = await api(`/dalson_trade/leads/import`, 'POST', undefined, {
                        buffer,
                        ...formUpdatedValues,
                        ignoreHeader: checked
                    })
                    await onStepUpdate('Normalize')
                    notifySuccess(response.message)

                } catch (error) {
                    notifyError(error.message)
                }
            }
            fileReader.readAsText(filePath)

        }
        const handleChangeFilePath = (e) => { setFilePath(e.target.files[0]) }

        const styles = {
            wrapper: { padding: '10px' },
            title: { marginBottom: '40px' },
            uploadInput: { marginBottom: '20px' },
            elementsContainer: { display: 'flex', flexWrap: 'wrap', flexGrow: 1 },
            element: { padding: '10px' },
            elementLabel: { marginTop: '10px' },
            uploadButton: { padding: '10px', marginTop: '5px', marginRight: '10px' },
            butttonContainer: { display: 'flex', marginTop: '10px', flexDirection: 'row', alignItems: 'baseline' }
        }


        return <div style={styles.wrapper}>
            <form onSubmit={handleUpload} >
                <h2 id='headerTitle' style={styles.title}>Upload file</h2>
                <input style={styles.uploadInput} type='file' accept='.csv' onChange={(handleChangeFilePath)} />
                <div style={styles.elementsContainer}>
                    {backendFields.map((field, index) =>
                        <div key={`excel_select_${index}`} style={styles.element}>
                            <div>
                                <label htmlFor={field} style={styles.elementLabel}>{tl(`backend_field_${field}`) + ':'}</label>
                            </div>
                            <div >
                                <select
                                    id={`select-${index}`}
                                    value={formValues[field]}
                                    name={field}
                                    onChange={(e) => handleChage(e)}
                                    className="selectpicker">
                                    <option value=''>{tl('backend_unfilled')}</option>
                                    {Array(26).fill().map((item, index) => <option key={index} value={index}>{index + 1}</option>)}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
                <div style={styles.butttonContainer}>
                    <button
                        style={styles.uploadButton}
                        disabled={!filePath}
                        className='btn btn-primary'
                        type='submit'>
                        Upload
                    </button>
                    <Checkbox headline={tl('backend_field_ignoreHeader')} checked={checked} handleChange={() => setChecked(!checked)}></Checkbox>
                </div>
            </form>
        </div>
    }

    const NormalizeStep = () => {

        const [totalLeads, setTotalLeads] = useState(0)

        useEffect(() => {
            const getTotalLeads = async () => {
                try {
                    const { data: { count } = {} } = await api('/dalson_trade/leadProcess/count', 'POST', undefined, { leadProcess: params.id })
                    setTotalLeads(count || 0)
                } catch (error) {
                    notifyError(error.message)
                }
            }
            getTotalLeads()
        }, [])
        return <h1>{tl('backend_field_leadsTotalCount', [{ key: 'count', value: totalLeads }])}</h1>
    }

    //CUSTOM HOOK SETUP
    useEffect(() => {

        const buildConfig = async () => {
            try {

                const { data: { data } } = await api(`/dalson_trade/leadProcess/${params.id}`, 'GET')

                if (data) {

                    const providedSteps = [
                        { name: 'Upload', value: data.status === 'Upload' ? <UploadStep /> : null },
                        { name: 'Normalize', value: data.status === 'Normalize' ? <NormalizeStep /> : null },
                        { name: 'Confirmation', value: <h1>TO BE DEFINED</h1> },
                    ]

                    const prevStep = providedSteps.findIndex(item => item.name === data.status)
                    const defaultStep = (prevStep > -1) ? prevStep : 0

                    return setConfig({
                        defaultStep,
                        title: "Lead process",
                        steps: providedSteps,
                        handleSave: () => console.log('finished'),
                        onStepUpdate
                    })
                }

            } catch (error) {
                notifyError(error.message)
            }
        }

        buildConfig()

    }, [reload])

    //MAIN RETURN
    return config
}

export default useDalsonTrade
