import React from 'react'
import api from '../../../../utils/api'
import { notifySuccess } from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import { translate as tl } from '../../../../utils/translation'

const ResendWelcomeMail = (props) => {
    const { row, updateTable, page } = props

    const handleClick = async () => {
        if(props.row.status === 'canceled') {
            return
        }

        try {
            await api(`/easyplus_de/resed-welcome-email/${row._id}`, 'PUT')
            notifySuccess(tl('message_email_sucessfully_sent'))
            updateTable({ page })
        } catch (error) {
            handleError(error, props)
        }
    }
    
    return (
        <a>
            <i 
                className='material-icons' 
                style={{ 
                    cursor: props.row.status === 'canceled' ? 'default' : 'pointer',
                    color: props.row.status === 'canceled' ? 'gray' : 'inherit',
                    opacity: props.row.status === 'canceled' ? 0.5 : 1,
                }} 
                title={tl('backend_action_send_welcome_mail')} 
                onClick={(e) => { handleClick() }}
            >
                mail_symbol
            </i>
        </a>
    )
}
export default ResendWelcomeMail
