import React, {Component} from 'react'
import {translate as tl} from '../../../../utils/translation'
import uniqid from 'uniqid';

export default class Select extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      selected: (props.multiple) ? [] : '',
      randomId: uniqid()
    }
    this.handleSelect = this.handleSelect.bind(this)
  }

  async componentDidMount () {
    if (this.props.options) {
      await this.setState({
        selected: this.props.values
      })
      window.$(`#${this.state.randomId}`).selectpicker()
    } else {
      // do nothing
    }
  }

  componentDidUpdate (prevProps, prevState) {
    window.$(`#${this.state.randomId}`).selectpicker()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.values !== this.state.selected) {
      this.setState({
        selected: nextProps.values
      })
    }
  }

  async handleSelect (event) {
    let selOptions = Array.from(event.target.children).filter(opt => opt.selected).map(opt => opt.value)
    if (selOptions.length === 1 && !this.props.multiple) {
      await this.setState({selected: selOptions[0]})
    } else {
      await this.setState({selected: selOptions})
    }
    this.props.handleChange(this.state.selected)
  }

  render () {
    const {selected} = this.state
    const {headline, alias, options, multiple, row, blank} = this.props
    const rowID = row && row._id
   
    if(blank && options) {
      options.unshift({})
    }

    return (
      <div>
        <b>{tl(headline)}</b>
        <div className='form-group'>
          <select id={this.state.randomId} multiple={multiple} name={alias} className='selectpicker' value={selected} onChange={this.handleSelect}>
            <option data-hidden='true' />
            {
              (options) &&
               options.filter(opt => opt.key !== rowID).map((opt, i) => {
                 return (
                   <option key={i} value={opt.key}>{opt.value}</option>
                 )
               })
            }
          </select>
        </div>
      </div>
    )
  }
}
