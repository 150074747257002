import React from 'react'
import { translate as tl } from '../../../../utils/translation'


export default ({ history, config, row }) => {

    const handleRedirect = () =>
        history.push(config.stepsUrl.replace(`:${config.stepsUrlParam}`, `${row[config.stepsParam]}`))

    return <i title={tl('backend_field_link')} onClick={() => handleRedirect()} style={{ 'cursor': 'pointer' }} className='material-icons medium'>open_in_new</i>

}