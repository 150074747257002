import React, { Component, useState } from 'react'
import cookie from 'react-cookies'
import CardHeader from '../../card/CardHeader'
import date from '../../../../utils/formatDate'
import currency from '../../../../utils/formatCurrency'
import api from '../../../../utils/api'
import { notifySuccess } from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import { translate as tl } from '../../../../utils/translation'
import autosize from 'autosize'
import modules from '../../../../config/modulesFactory'

export default class ViewCustomer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cashbackData: '',
      cashbackTransactions: '',
      cashbackPayoffs: '',
      bonusSystemCustomer: '',
      dalsonCustomer: {
        idinsert: '',
        idmailingcc: '',
        cc: '',
        agentcc: '',
        mktcodecc: '',
        datafinalcc: '',
        dataInsercio: '',
        descfinal: '',
        MarketingCampaign: '',
        partner: ''
      },
      easymillionscustomer: {
        personalTips: '',
        customerID: '',
        externalCustomerId: '',
        salutation: '',
        title: '',
        firstName: '',
        lastName: '',
        birthName: '',
        birthday: '',
        addressLine1: '',
        addressLine2: '',
        zipCode: '',
        city: '',
        country: '',
        UpdateCustomer: '',
        status: '',
        contract_contractID: '',
        contract_contractPartner: '',
        contract_mediaCode: '',
        contract_contractType: '',
        contract_paymentMethodType: '',
        contract_basePrice: '',
        contract_fee: '',
        contract_entryFee: '',
        contract_orderDate: '',
        contract_orderCreateDate: '',
        contract_startDate: '',
        contract_endDate: '',
        contract_confirmationDate: '',
        contract_agentId: '',
        contract_callCenter: '',
        contract_phone: '',
        contract_mobile: '',
        contract_fax: '',
        contract_email: '',
        contract_marketingCode: '',
        contract_marketingCampaign: '',
        contract_notificationType: '',
        contract_periods: '',
        contract_totalWinAmount: '',
        contract_totalDebitAmount: '',
        IBAN: '',
        BIC: '',
        CancellationOnDate: '',
        CancellationToDate: '',
        UpdateContract: '',
        blackList: '',
        boutiqueCount: '',
      },
      bonusTransactions: '',
      contactMessages: '',
      newMessage: {
        message: '',
        type: 'outgoing'
      },
      note: '',
      notes: [],
      noteKey: (Math.random() + 1).toString(36).substring(7)
    }

    this.onNoteFormSubmit = this.onNoteFormSubmit.bind(this)
  }

  async loadData(id) {
    const availableModules = await modules()
    const renderModules = availableModules.map(module => module.name)
    await this.setState({ renderModules })
    try {
      if (renderModules.includes('cashback')) {
        var { data: { data: [cashbackData] } } = await api('/cashback/customers', 'GET', { crm_customer: id, populate: 'favoriteCategories favoriteBrands' })
        var { data: { data: cashbackTransactions } } = await api('/cashback/transactions', 'GET', { cashback_customer: cashbackData._id, populate: 'cashback_brand' })
        var { data: { data: cashbackPayoffs } } = await api('/cashback/payoffs', 'GET', { cashback_customer: cashbackData._id })
      }
      if (renderModules.includes('bonussystem')) {
        var { data: { data: [bonusSystemCustomer] } } = await api('/bonussystem/customers', 'GET', { crm_customer: id, populate: 'crm_customer' })
        var { data: { data: bonusTransactions } } = await api('/bonussystem/transactions', 'GET', { bonussystem_customer: bonusSystemCustomer._id })
      }
      if (renderModules.includes('easyplus-fr')) {
        var { data: { data: [dalsonCustomer] } } = await api(`/easyplus_fr/dalsoncustomers`, 'GET', { crm_customer: id })
        if (!dalsonCustomer) dalsonCustomer = this.state.dalsonCustomer
      }
      if (renderModules.includes('easymillions')) {
        var { data: { data: [easymillionscustomer] } } = await api(`/easymillions/customers`, 'GET', { crm_customer: id })
        if (!easymillionscustomer) easymillionscustomer = this.state.easymillionscustomer
      }
      const notes = await api(`/crm/notes/${this.props.row._id}`, 'GET')

      const { data: { data: contactMessages } } = await api('/crm/contactmessages', 'GET', { crm_customer: id })
      const newState = {
        cashbackData,
        cashbackTransactions,
        cashbackPayoffs,
        bonusSystemCustomer,
        dalsonCustomer,
        easymillionscustomer,
        bonusTransactions,
        contactMessages,
        newMessage: {
          message: '',
          type: 'outgoing',
          crm_customer: this.props.row._id
        },
        notes: notes.data
      }
      this.setState(newState)
    } catch (error) {
      handleError(error, this.props)
    }
  }

  async handleChange(key, value) {
    let newMessage = Object.assign({}, this.state.newMessage)
    newMessage[key] = value
    await this.setState({ newMessage })
  }

  async onFormSubmit() {
    const newMessage = Object.assign({ crm_customer: this.props.row._id }, this.state.newMessage)
    try {
      const response = await api(`/crm/contactmessages`, 'POST', null, newMessage)
      notifySuccess(response.data.message)
      this.loadData(this.props.row._id)
    } catch (error) {
      handleError(error, this.props)
    }
  }

  async onNoteFormSubmit(e) {
    e.preventDefault()
    try {
      await api(`/crm/createnote`, 'POST', null, {
        note: this.state.note,
        customerId: this.props.row._id
      })
      notifySuccess(tl('backend_view_customer_note_created'))

      const notes = await api(`/crm/notes/${this.props.row._id}`, 'GET')

      this.setState({ notes: notes.data, note: '', noteKey: (Math.random() + 1).toString(36).substring(7) })
    } catch (error) {
      handleError(error)
    }
  }

  async deleteMessage(id) {
    try {
      const response = await api(`/crm/contactmessages/${id}`, 'DELETE')
      notifySuccess(response.data.message)
      this.loadData(this.props.row._id)
    } catch (error) {
      handleError(error, this.props)
    }
  }

  render() {
    autosize(window.$('textarea'))

    const { row } = this.props
    const { cashbackData, cashbackTransactions, cashbackPayoffs, bonusSystemCustomer, dalsonCustomer, easymillionscustomer, bonusTransactions, contactMessages, renderModules } = this.state

    const ListItem = ({ title, data }) => {
      const style = {
        display: 'inline-block',
        minWidth: '20%'
      }
      return <li className='list-group-item'><span style={style}>{`${tl(title)}:`}</span> {data}</li>
    }

    const colors = {
      paidoff: {
        value: 'done_all',
        color: 'col-green'
      },
      payoff: {
        value: 'done_all',
        color: 'col-grey'
      },
      open: {
        value: 'done',
        color: 'col-grey'
      },
      canceled: {
        value: 'cancel',
        color: 'col-pink'
      },
      forecast: {
        value: 'done',
        color: 'col-grey'
      }
    }

    const company = this.props.configs.find(i => i.key === cookie.load('__co'))

    return (
      <span>
        <div className='modal fade' id={(row) ? `view${row._id}` : 'viewCustomerModal'} tabIndex='-1' role='dialog'>
          <div className='modal-dialog modal-lg' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button style={{ position: 'relative', zIndex: 10 }} type='button' className='close' data-dismiss='modal'>&times;</button>
                <CardHeader title={`${(row.salutation) ? row.salutation : ''} ${(row.firstname) ? row.firstname : ''} ${(row.lastname) ? row.lastname : ''}`} />
              </div>
              <div className='modal-body'>
                <ul className='nav nav-tabs tab-nav-right' role='tablist'>
                  <li role='presentation' className='active'>
                    <a href={`#basicInfo${row._id}`} data-toggle='tab' aria-expanded='true'>{tl('view_customer_title_basic_information')}</a>
                  </li>
                  {renderModules && renderModules.includes('cashback') &&
                    <li role='presentation' className=''>
                      <a href={`#cashback${row._id}`} data-toggle='tab' aria-expanded='false'>{tl('view_customer_title_cashback')}</a>
                    </li>
                  }
                  {renderModules && renderModules.includes('bonussystem') &&
                    <li role='presentation' className=''>
                      <a href={`#bonusSystem${row._id}`} data-toggle='tab' aria-expanded='false'>{tl('view_customer_title_bonussystem')}</a>
                    </li>
                  }
                  {renderModules && renderModules.includes('easyplus-fr') &&
                    <li role='presentation' className=''>
                      <a href={`#dalson${row._id}`} data-toggle='tab' aria-expanded='false'>{tl('view_customer_title_dalson')}</a>
                    </li>
                  }
                  {renderModules && renderModules.includes('easymillions') &&
                    <li role='presentation' className=''>
                      <a href={`#easymillions${row._id}`} data-toggle='tab' aria-expanded='false'>{tl('view_customer_title_easymillions')}</a>
                    </li>
                  }
                  <li role='presentation' className=''>
                    <a href={`#contactmessages${row._id}`} data-toggle='tab' aria-expanded='false'>{tl('view_customer_title_contactmessages')}</a>
                  </li>
                  <li role='presentation' className=''>
                    <a href={`#notes${row._id}`} data-toggle='tab' aria-expanded='false'>{tl('view_customer_title_notes')}</a>
                  </li>
                </ul>
                <div className='tab-content'>
                  <div role='tabpanel' className='tab-pane animated fadeIn active' id={`basicInfo${row._id}`}>
                    <ul className='list-group'>
                      <ListItem title='backend_field_id' data={row.customerId} />
                      <ListItem title='backend_field_salutation' data={row.salutation} />
                      <ListItem title='backend_field_firstname' data={row.firstname} />
                      <ListItem title='backend_field_lastname' data={row.lastname} />
                      <ListItem title='backend_field_address' data={row.street} />
                      <ListItem title='backend_field_city' data={row.city} />
                      <ListItem title='backend_field_postcode' data={row.postcode} />
                      <ListItem title='backend_field_country' data={row.country} />
                      <ListItem title='backend_field_email' data={row.email} />
                      <ListItem title='backend_field_birthdate' data={date(row.dateOfBirth)} />
                      <ListItem title='backend_field_phone' data={row.phoneFixed} />
                      <ListItem title='backend_field_mobile' data={row.phoneMobile} />
                      <ListItem title='backend_field_created_at' data={date(row.createdAt)} />
                      <ListItem title='backend_field_status' data={row.status} />
                      <ListItem title='backend_field_supplier' data={row.supplier} />
                    </ul>
                  </div>
                  {renderModules && renderModules.includes('cashback') &&
                    <div role='tabpanel' className='tab-pane animated fadeIn' id={`cashback${row._id}`}>
                      <ul className='list-group'>
                        <ListItem title='backend_field_balance' data={currency(cashbackData.balance, company)} />
                        <ListItem title='backend_field_bank_owner' data={(cashbackData.bankOwner) ? cashbackData.bankOwner : 'N/A'} />
                        <ListItem title='backend_field_iban' data={(cashbackData.bankIban) ? cashbackData.bankIban : 'N/A'} />
                        <ListItem title='backend_field_bic' data={(cashbackData.bankBic) ? cashbackData.bankBic : 'N/A'} />
                        <ListItem title='backend_field_paypal_owner' data={(cashbackData.paypalOwner) ? cashbackData.paypalOwner : 'N/A'} />
                        <ListItem title='backend_field_newsletter_news' data={(cashbackData.newsletterNews) ? 'Yes' : 'No'} />
                        <ListItem title='backend_field_newsletter_brand' data={(cashbackData.newsletterBrand) ? 'Yes' : 'No'} />
                        <ListItem title='backend_field_newsletter_special' data={(cashbackData.newsletterSpecial) ? 'Yes' : 'No'} />
                        <ListItem title='backend_field_favorite_categories' data={
                          <ul>
                            {(cashbackData.favoriteCategories && cashbackData.favoriteCategories.length > 0) ? (cashbackData.favoriteCategories.map((category, key) => <li key={key}>{category.title}</li>)) : 'N/A'}
                          </ul>} />
                        <ListItem title='backend_field_favorite_brands' data={
                          <ul>
                            {(cashbackData.favoriteBrands && cashbackData.favoriteBrands.length > 0) ? (cashbackData.favoriteBrands.map((brand, key) => <li key={key}>{brand.title}</li>)) : 'N/A'}
                          </ul>} />
                        <ListItem title='backend_field_cashback_clicks' data={cashbackData.cashbackClicks} />
                      </ul>
                      <CardHeader title={tl('backend_view_customer_title_cashbacktransactions')} />
                      <div className='table-responsive'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>{tl('backend_field_id')}</th>
                              <th>{tl('backend_field_brand')}</th>
                              <th>{tl('backend_field_order_id')}</th>
                              <th>{tl('backend_field_purchased_at')}</th>
                              <th>{tl('backend_field_created_at')}</th>
                              <th>{tl('backend_field_price')}</th>
                              <th>{tl('backend_field_cashback')}</th>
                              <th>{tl('backend_field_status')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (cashbackTransactions && cashbackTransactions.length > 0) ? cashbackTransactions.map((transaction, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{transaction.transactionId}</td>
                                    <td>{transaction.cashback_brand.title}</td>
                                    <td>{transaction.orderId}</td>
                                    <td>{date(transaction.purchasedAt)}</td>
                                    <td>{date(transaction.createdAt)}</td>
                                    <td>{currency(transaction.price, company)}</td>
                                    <td>{currency(transaction.cashback, company)}</td>
                                    <td><i title={transaction.status} className={`material-icons ${colors[transaction.status].color}`}>{colors[transaction.status].value}</i></td>
                                  </tr>
                                )
                              })
                                : <tr><td colSpan='4'>{tl('backend_no_data_available')}</td></tr>
                            }
                          </tbody>
                        </table>
                      </div>
                      <CardHeader title={tl('backend_view_customer_title_cashbackpayoffs')} />
                      <div className='table-responsive'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>{tl('backend_field_id')}</th>
                              <th>{tl('backend_field_payment_information')}</th>
                              <th>{tl('backend_field_amount')}</th>
                              <th>{tl('backend_field_created_at')}</th>
                              <th>{tl('backend_field_status')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (cashbackPayoffs && cashbackPayoffs.length > 0) ? cashbackPayoffs.map((payoff, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{payoff.payoffId}</td>
                                    <td>{(payoff.type === 'bank')
                                      ? (<div>
                                        <strong>{tl('backend_field_bank')}</strong><br />
                                        {tl('backend_field_owner')}: {payoff.bankOwner}<br />
                                        Iban: {payoff.bankIban}<br />
                                        Bic: {payoff.bankBic}
                                      </div>
                                      )
                                      : (
                                        <div>
                                          <strong>PayPal</strong><br />
                                          {tl('backend_field_owner')}: {payoff.paypalOwner}<br />
                                          {tl('backend_field_email')}: {payoff.paypalEmail}
                                        </div>
                                      )}</td>
                                    <td>{currency(payoff.amount, company)}</td>
                                    <td>{date(payoff.createdAt)}</td>
                                    <td><i title={payoff.status} className={`material-icons ${colors[payoff.status].color}`}>{colors[payoff.status].value}</i></td>
                                  </tr>
                                )
                              })
                                : <tr><td colSpan='4'>{tl('backend_no_data_available')}</td></tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                  {renderModules && renderModules.includes('bonussystem') &&
                    <div role='tabpanel' className='tab-pane animated fadeIn' id={`bonusSystem${row._id}`}>
                      <ul className='list-group'>
                        <ListItem title='Balance:' data={`${bonusSystemCustomer.balance} Points`} />
                      </ul>
                      <CardHeader title={tl('backend_view_customer_title_bonussystemtransactions')} />
                      <div className='table-responsive'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>{tl('backend_field_type')}</th>
                              <th>{tl('backend_field_amount')}</th>
                              <th>{tl('backend_field_created_at')}</th>
                              <th>{tl('backend_field_status')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (bonusTransactions && bonusTransactions.length > 0)
                                ? bonusTransactions.map((bonustransaction, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{bonustransaction.type}</td>
                                      <td><span>{bonustransaction.amount} {tl('backend_points')}</span></td>
                                      <td>{date(bonustransaction.createdAt)}</td>
                                      <td><i title={bonustransaction.status} className={`material-icons ${colors[bonustransaction.status].color}`}>{colors[bonustransaction.status].value}</i></td>
                                    </tr>
                                  )
                                })
                                : <tr><td colSpan='4'>{tl('backend_no_data_available')}</td></tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                  {renderModules && renderModules.includes('easyplus-fr') &&
                    <div role='tabpanel' className='tab-pane animated fadeIn' id={`dalson${row._id}`}>
                      <ul className='list-group'>
                        <ListItem title='backend_field_idinsert' data={dalsonCustomer.idinsert} />
                        <ListItem title='backend_field_idmailingcc' data={dalsonCustomer.idmailingcc} />
                        <ListItem title='backend_field_cc' data={dalsonCustomer.cc} />
                        <ListItem title='backend_field_agentcc' data={dalsonCustomer.agentcc} />
                        <ListItem title='backend_field_mktcodecc' data={dalsonCustomer.mktcodecc} />
                        <ListItem title='backend_field_datafinalcc' data={dalsonCustomer.datafinalcc} />
                        <ListItem title='backend_field_dataInsercio' data={dalsonCustomer.dataInsercio} />
                        <ListItem title='backend_field_descfinal' data={dalsonCustomer.descfinal} />
                        <ListItem title='backend_field_MarketingCampaign' data={date(dalsonCustomer.MarketingCampaign)} />
                        <ListItem title='backend_field_partner' data={dalsonCustomer.partner} />
                      </ul>
                    </div>
                  }
                  {renderModules && renderModules.includes('easymillions') &&
                    <div role='tabpanel' className='tab-pane animated fadeIn' id={`easymillions${row._id}`}>
                      <ul className='list-group'>
                        <ListItem title='backend_field_personalTips' data={easymillionscustomer.personalTips && easymillionscustomer.personalTips.length > 1 ? easymillionscustomer.personalTips.join(', ') : ''} />
                        <ListItem title='backend_field_somioTips' data={easymillionscustomer.somioTips && easymillionscustomer.somioTips.length > 1 ? easymillionscustomer.somioTips.join(', ') : ''} />
                        <ListItem title='backend_field_customerID' data={easymillionscustomer.customerID} />
                        <ListItem title='backend_field_externalCustomerId' data={easymillionscustomer.externalCustomerId} />
                        <ListItem title='backend_field_salutation' data={easymillionscustomer.salutation} />
                        <ListItem title='backend_field_title' data={easymillionscustomer.title} />
                        <ListItem title='backend_field_firstName' data={easymillionscustomer.firstName} />
                        <ListItem title='backend_field_lastName' data={easymillionscustomer.lastName} />
                        <ListItem title='backend_field_birthName' data={easymillionscustomer.birthName} />
                        <ListItem title='backend_field_birthday' data={date(easymillionscustomer.birthday)} />
                        <ListItem title='backend_field_addressLine1' data={easymillionscustomer.addressLine1} />
                        <ListItem title='backend_field_addressLine2' data={easymillionscustomer.addressLine2} />
                        <ListItem title='backend_field_zipCode' data={easymillionscustomer.zipCode} />
                        <ListItem title='backend_field_city' data={easymillionscustomer.city} />
                        <ListItem title='backend_field_country' data={easymillionscustomer.country} />
                        <ListItem title='backend_field_UpdateCustomer' data={date(easymillionscustomer.UpdateCustomer)} />
                        <ListItem title='backend_field_status' data={easymillionscustomer.status} />
                        <ListItem title='backend_field_contract_contractID' data={easymillionscustomer.contract_contractID} />
                        <ListItem title='backend_field_contract_contractPartner' data={easymillionscustomer.contract_contractPartner} />
                        <ListItem title='backend_field_contract_mediaCode' data={easymillionscustomer.contract_mediaCode} />
                        <ListItem title='backend_field_contract_contractType' data={easymillionscustomer.contract_contractType} />
                        <ListItem title='backend_field_contract_paymentMethodType' data={easymillionscustomer.contract_paymentMethodType} />
                        <ListItem title='backend_field_contract_basePrice' data={easymillionscustomer.contract_basePrice} />
                        <ListItem title='backend_field_contract_fee' data={easymillionscustomer.contract_fee} />
                        <ListItem title='backend_field_contract_entryFee' data={easymillionscustomer.contract_entryFee} />
                        <ListItem title='backend_field_contract_orderDate' data={date(easymillionscustomer.contract_orderDate)} />
                        <ListItem title='backend_field_contract_orderCreateDate' data={date(easymillionscustomer.contract_orderCreateDate)} />
                        <ListItem title='backend_field_contract_startDate' data={date(easymillionscustomer.contract_startDate)} />
                        <ListItem title='backend_field_contract_endDate' data={date(easymillionscustomer.contract_endDate)} />
                        <ListItem title='backend_field_contract_confirmationDate' data={date(easymillionscustomer.contract_confirmationDate)} />
                        <ListItem title='backend_field_contract_agentId' data={easymillionscustomer.contract_agentId} />
                        <ListItem title='backend_field_contract_callCenter' data={easymillionscustomer.contract_callCenter} />
                        <ListItem title='backend_field_contract_phone' data={easymillionscustomer.contract_phone} />
                        <ListItem title='backend_field_contract_mobile' data={easymillionscustomer.contract_mobile} />
                        <ListItem title='backend_field_contract_fax' data={easymillionscustomer.contract_fax} />
                        <ListItem title='backend_field_contract_email' data={easymillionscustomer.contract_email} />
                        <ListItem title='backend_field_contract_marketingCode' data={easymillionscustomer.contract_marketingCode} />
                        <ListItem title='backend_field_contract_marketingCampaign' data={easymillionscustomer.contract_marketingCampaign} />
                        <ListItem title='backend_field_contract_notificationType' data={easymillionscustomer.contract_notificationType} />
                        <ListItem title='backend_field_contract_totalWinAmount' data={easymillionscustomer.contract_totalWinAmount} />
                        <ListItem title='backend_field_contract_totalDebitAmount' data={easymillionscustomer.contract_totalDebitAmount} />
                        <ListItem title='backend_field_IBAN' data={easymillionscustomer.IBAN} />
                        <ListItem title='backend_field_BIC' data={easymillionscustomer.BIC} />
                        <ListItem title='backend_field_CancellationOnDate' data={date(easymillionscustomer.CancellationOnDate)} />
                        <ListItem title='backend_field_CancellationToDate' data={date(easymillionscustomer.CancellationToDate)} />
                        <ListItem title='backend_field_UpdateContract' data={date(easymillionscustomer.UpdateContract)} />
                        <ListItem title='backend_field_blackList' data={easymillionscustomer.blackList} />
                      </ul>
                    </div>
                  }
                  <div role='tabpanel' className='tab-pane animated fadeIn' id={`contactmessages${row._id}`}>
                    <CardHeader title={tl('backend_view_customer_title_contactmessages')} />
                    <div className='table-responsive'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th>{tl('backend_field_message')}</th>
                            <th>{tl('backend_field_type')}</th>
                            <th>{tl('backend_field_created_at')}</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {
                            (contactMessages && contactMessages.length > 0) ? contactMessages.map((message, key) => {
                              return (
                                <tr key={key}>
                                  <td>{message.message}</td>
                                  <td>{message.type}</td>
                                  <td>{new Date(message.createdAt).toLocaleString('de-DE')}</td>
                                  <style>
                                    {`
                                      #deleteMessageIcon {
                                        color: red  !important;
                                        }
                                    `}
                                  </style>
                                  <td><i id='deleteMessageIcon' title='delete' style={{ 'cursor': 'pointer' }} onClick={(e) => { this.deleteMessage(message._id) }} className='material-icons medium'>delete_forever</i></td>
                                </tr>
                              )
                            })
                              : <tr><td colSpan='4'>{tl('backend_no_data_available')}</td></tr>
                          }
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <hr />
                      <h4>{tl('backend_customer_view_create_new_message')}</h4>
                      <form onSubmit={this.onFormSubmit}>
                        <div className='form-group'>
                          <div className='row clearfix'>
                            <div className='col-sm-6'>
                              <div className='form-line'>
                                <textarea type='text' placeholder={tl('backend_message')} className='form-control no-resize' value={this.state.newMessage.message} onChange={(e) => this.handleChange('message', e.target.value)} />
                              </div>
                            </div>
                            <div className='col-sm-6'>
                              <input name={`${row._id}outgoing`} type='radio' id={`${row._id}outgoing`} onChange={(e) => this.handleChange('type', 'outgoing')} checked={this.state.newMessage.type === 'outgoing'} className='radio-col-blue' /><label htmlFor={`${row._id}outgoing`}>{tl('backend_outgoing')}</label>
                              <input name={`${row._id}incoming`} type='radio' id={`${row._id}incoming`} onChange={(e) => this.handleChange('type', 'incoming')} checked={this.state.newMessage.type === 'incoming'} className='radio-col-blue' /><label htmlFor={`${row._id}incoming`}>{tl('backend_incoming')}</label>
                            </div>
                            <div className='pull-right'>
                              <button style={{ marginRight: '8px' }} title='send' type='button' onClick={() => { this.onFormSubmit() }} className='btn bg-teal'><i className='material-icons'>check</i> {tl('backend_save_button')}</button>
                              <button style={{ marginRight: '8px' }} title='cancel' type='button' onClick={() => { this.setState({ newMessage: { message: '', type: 'outgoing', user: row._id } }) }} className='btn btn-danger'><i className='material-icons'>close</i> {tl('backend_clear_button')}</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div role='tabpanel' className='tab-pane animated fadeIn' id={`notes${row._id}`} >
                    <CardHeader title={tl('view_customer_title_notes')} style={{ marginBottom: '10px' }} />
                    <h1 />
                    {
                      this.state.notes.map((note, index) => (
                        <div key={index} style={{ marginBottom: '15px' }}>
                          <label>{`${note.creator} (${new Date(note.createdAt).toLocaleString('de-DE')}):`}</label>
                          <p dangerouslySetInnerHTML={{__html: note.note.replace(/\n/g, "<br />") }}>
                          </p>
                        </div>
                      ))
                    }
                    <b>{tl('view_customer_title_new_note')}</b>
                    <div>
                      <form onSubmit={this.onNoteFormSubmit}>
                        <div className='form-group'>
                          <div className='input-group'>
                            <div className='form-line'>
                              <textarea key={this.state.noteKey} type='text' value={this.state.note} onChange={(event) => this.setState({ note: event.target.value })} className='tinymce form-control' />
                            </div>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                            <button style={{ marginRight: '8px' }} title='send' type='submit' className='btn bg-teal'><i className='material-icons'>check</i> {tl('backend_save_button')}</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a>
          <i onClick={() => this.loadData(row._id)} style={{ 'cursor': 'pointer' }} title={tl('backend_action_view_customer')} data-toggle='modal' data-target={`#view${row._id}`} className='material-icons medium'>visibility</i>
        </a>
      </span>
    )
  }
}
