
import React, { useEffect, useMemo, useState } from 'react'


const Steps = ({ title, steps, handleSave, onStepUpdate, defaultStep }) => {


    const [selectedStep, setSelectedStep] = useState(null)

    useEffect(() => {
        steps && setSelectedStep(defaultStep + 1)
    }, [steps, defaultStep])


    const elements = {
        button: (text, callback) => {

            try {
                const buttonStyles = {
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'rgb(1, 169, 244)',
                    color: 'white',
                    padding: '1em 1em',
                    cursor: 'pointer',
                    height: '40px',
                    marginLeft: '10px'
                }

                return <button
                    style={buttonStyles}
                    onClick={callback}>{text}
                </button>

            } catch ({ message }) {
                console.log("ERROR_STEPS_button", message)
            }
        },

        label: (text, index) => {

            try {
                const optionIsSelected = (index === selectedStep - 1)
                const isFirstOption = (index === 0)

                const containerStyles = {
                    display: 'flex',
                    flexGrow: 1,
                    marginLeft: isFirstOption ? 'auto' : '10px',
                    flexDirection: 'row',
                    height: '60px',
                    backgroundColor: optionIsSelected ? 'rgb(1, 169, 244)' : 'lightgray',
                    color: optionIsSelected ? 'white' : 'grey',
                    padding: '1em 1em',

                }
                return <div key={index} style={containerStyles}>{text}</div>

            } catch ({ message }) {
                console.log("ERROR_STEPS_optionButton", message)
            }
        }
    }

    //STEPS CONTENT
    const stepContent = useMemo(() => {

        const buildStepContent = () => {
            try {
                const wrapperStyles = {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px'
                }

                const childrenContainer = {
                    border: 'solid 1px lightgray',
                    flexGrow: 1,
                    padding: '20px'
                }

                return <div style={wrapperStyles}>
                    <div style={childrenContainer}>
                        {steps[selectedStep - 1].value}
                    </div>
                </div>

            } catch ({ message }) {
                console.log("ERROR_STEPS_buildStepsContent()", message)
            }
        }

        return selectedStep && buildStepContent()

    }, [selectedStep, steps])


    // CARD TITLE
    const cardTitle = useMemo(() => {

        const titleStyles = {
            padding: '20px',
            display: 'flex',
            fontSize: '18px'
        }
        const dividerStyles = {
            height: '0.5px',
            width: '100%',
            backgroundColor: 'lightgray'
        }

        return <>
            <div style={titleStyles}>
                <p>{title}</p>
            </div>
            <div style={dividerStyles} />
        </>
    }, [title])


    //STEPS INFO
    const availableSteps = useMemo(() => {

        const buildAvailableSteps = () => {

            try {

                const containerStyles = {
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '20px',
                    flexWrap: 'wrap',
                    alignItems: 'center'
                }

                const elementContainerStyles = {
                    display: 'flex',
                    alignItems: 'baseline'
                }

                const elementStyles = { fontSize: '1.5em' }

                return <div style={containerStyles}>
                    {steps.map((item, index) => {

                        const text = <div style={elementContainerStyles}>
                            <p style={elementStyles}>{index + 1}</p>.<p>{item.name}</p>
                        </div>

                        return elements.label(text, index)
                    })}

                </div>

            } catch ({ message }) {
                console.log("ERROR_STEPS_buildAvailableSteps()", message)
            }
        }
        return selectedStep && buildAvailableSteps()

    }, [selectedStep])


    //NAVIGATION BUTTON
    const navigation = useMemo(() => {

        const buildNavigation = () => {

            try {
                const containerStyles = {
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '20px',
                    justifyContent: 'flex-end'
                }

                const itsLastStep = (selectedStep === steps.length)

                const handleOnClick = () => {

                    if (itsLastStep) return handleSave()

                    setSelectedStep(prev => {
                        const updatedValue = prev + 1
                        onStepUpdate(steps[updatedValue - 1].name)
                        return updatedValue
                    })
                }

                const value = itsLastStep ? 'Finish' : 'Next' //TO ADD TRANSLATIONS

                return <div style={containerStyles}>
                    {elements.button(value, handleOnClick)}
                </div>

            } catch ({ message }) {
                console.log("ERROR_STEPS_buildNavigation()", message)
            }
        }

        return selectedStep && buildNavigation()
    }, [selectedStep])


    return <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', boxShadow: '0 2px 10px rgb(0 0 0 / 20%)' }}>
        {cardTitle}
        {availableSteps}
        {stepContent}
        {navigation}
    </div>


}

export default Steps