import React from 'react'

const CardBody = (props) => {
  return (
    <div className='body'>
      {props.children}
    </div>
  )
}
export default CardBody
