import React, {Component} from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import api from '../../utils/api'
import {translate as tl} from '../../utils/translation'


export default class SearchCustomer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      loading: false,
      pages: -1,
      page: 1,
      customerID: '',
      externalCustomerId: '',
      firstName: '',
      lastName: '',
      zipCode: '',
      pagesize: 20
    }

    this.fetchEasymillionscustomer = this.fetchEasymillionscustomer.bind(this)
    this.setPage = this.setPage.bind(this)
    this.setPageSize = this.setPageSize.bind(this)
    this.handleChangeCustomerID = this.handleChangeCustomerID.bind(this)
    this.handleChangeExternalCustomerId = this.handleChangeExternalCustomerId.bind(this)
    this.handleChangeFirstName = this.handleChangeFirstName.bind(this)
    this.handleChangeLastName = this.handleChangeLastName.bind(this)
    this.handleChangeZipCode = this.handleChangeZipCode.bind(this)
  }

  async fetchEasymillionscustomer() {
    this.setState({loading: true})
    const result = await api('/easymillions/search/customers', 'GET', {
      pagesize: this.state.pagesize, 
      page: this.state.page,
      customerID: this.state.customerID,
      externalCustomerId: this.state.externalCustomerId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      zipCode: this.state.zipCode,
      populate: 'crm_customer' 
    })
    this.setState({
      data: result.data.data,
      loading: false,
      pages: Math.round(result.data.totalCount / 20),
    })
  }

  handleChangeCustomerID(e) {
    console.log(e.target.value)
    this.setState({
      customerID: e.target.value,
      page: 1
    }, this.fetchEasymillionscustomer)
  }

  handleChangeExternalCustomerId(e) {
    this.setState({
      externalCustomerId: e.target.value,
      page: 1
    }, this.fetchEasymillionscustomer)
  }

  handleChangeFirstName(e) {
    this.setState({
      firstName: e.target.value,
      page: 1
    }, this.fetchEasymillionscustomer)
  }

  handleChangeLastName(e) {
    this.setState({
      lastName: e.target.value,
      page: 1
    }, this.fetchEasymillionscustomer)
  }

  handleChangeZipCode(e) {
    this.setState({
      zipCode: e.target.value,
      page: 1
    }, this.fetchEasymillionscustomer)
  }

  setPage(index) {
    this.setState({page: index+1})
  }

  setPageSize(size) {
    this.setState({pagesize: size}, this.fetchEasymillionscustomer)
  }

  render () {
    return (
      <div>
        <div>
          <div className='search-field'>
              <label className='search-label' htmlFor='customerID'>{`${tl('backend_field_customerID')}:`}</label>
              <input className='search-input' type='input' name='customerId' id='customerID' value={this.state.customerID} onChange={this.handleChangeCustomerID} />  
          </div>
          <div className='search-field'>
              <label className='search-label' htmlFor='externalCustomerId'>{`${tl('backend_field_externalCustomerId')}:`}</label>
              <input className='search-input' type='input' name='externalCustomerId' id='externalCustomerId' value={this.state.externalCustomerId} onChange={this.handleChangeExternalCustomerId} />  
          </div>
          <div className='search-field'>
              <label className='search-label' htmlFor='firstName'>{`${tl('backend_field_firstName')}:`}</label>
              <input className='search-input' type='input' name='firstName' id='firstName' value={this.state.firstName} onChange={this.handleChangeFirstName} />  
          </div>
          <div className='search-field'>
              <label className='search-label' htmlFor='lastName'>{`${tl('backend_field_lastName')}:`}</label>
              <input className='search-input' type='input' name='lastName' id='lastName' value={this.state.lastName} onChange={this.handleChangeLastName} />  
          </div>
          <div className='search-field'>
              <label className='search-label' htmlFor='zipCode'>{`${tl('backend_field_zipCode')}:`}</label>
              <input className='search-input' type='input' name='zipCode' id='zipCode' value={this.state.zipCode} onChange={this.handleChangeZipCode} />  
          </div>
        </div>
        <div style={{marginTop: '20px'}}>
          <ReactTable
            data={this.state.data} // should default to []
            pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
            loading={this.state.loading}
            sortable={false}
            resizable={false}
            pageSizeOptions={[20]}
            columns={[{
              Header: tl('backend_field_id'),
              accessor: 'crm_customer.customerId'
            }, {
              Header: tl('backend_field_customerID'),
              accessor: 'customerID'
            }, {
              Header: tl('backend_field_externalCustomerId'),
              accessor: 'externalCustomerId'
            }, {
              Header: tl('backend_field_firstName'),
              accessor: 'firstName'
            }, {
              Header: tl('backend_field_lastName'),
              accessor: 'lastName'  
            }, {
              Header: tl('backend_field_zipCode'),
              accessor: 'zipCode'
            }]}
            manual // informs React Table that you'll be handling sorting and pagination server-side
            onFetchData={this.fetchEasymillionscustomer}
            onPageChange={this.setPage}
            onPageSizeChange={this.setPageSize}
          />
        </div>
      </div>
    )
  }
}
