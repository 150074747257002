
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { translate as tl } from '../../utils/translation'


const Menu = (props) => {

	const { availableModules } = props

	const [navigationRoutes, setNavigationRoutes] = useState(false)
	const [selectedRoute, setSelectedRoute] = useState(false)

	// ON LOAD ROUTES
	useEffect(() => {
		const buildNavigation = () => {

			const defaultRoutes = () => [
				{
					label: 'backend_dashboard_label',
					alias: 'dashboard',
					path: '/dashboard',
					icon: 'home'
				},
				{
					label: 'backend_content_management_label',
					alias: 'content-management',
					icon: 'web',
					subsites: []
				},
				{
					label: 'backend_customer_care_label',
					alias: 'customer-care',
					icon: 'face',
					subsites: []
				},
				{
					label: 'backend_marketing_label',
					alias: 'marketing',
					icon: 'donut_large',
					subsites: []
				},
				{
					label: 'backend_administration_label',
					alias: 'administration',
					icon: 'security',
					subsites: []
				}
			]

			setSelectedRoute(false)
			setNavigationRoutes(() => {

				const updatedValues = defaultRoutes()

				availableModules.forEach(module => {

					if (module.pages && module.pages.length) {

						module.pages.forEach(page => {

							if (page.navigation) {

								updatedValues.forEach(item => {

									if (props.location.pathname === item.path) item.active = true

									if ((item.alias === page.navigation) && !item.subsites.find(item => item.name === page.name))
										item.subsites.push(page)
								})
							}
						})
					}
				})

				return updatedValues
			})
		}
		availableModules && buildNavigation()
	}, [availableModules])

	// ON ROUTE CHANGE
	useEffect(() => {
		const handleSelectedRoute = () => {
			const { mainRouteIndex, showSubsites, subRouteIndex } = selectedRoute

			setNavigationRoutes((prev) => {

				const updatedNavigation = [...prev]
				updatedNavigation.forEach((route, routeIndex) => {

					route.active = (mainRouteIndex === routeIndex)
					route.showSubsites = (route.active && showSubsites)

					if (route.showSubsites) {
						route.subsites.forEach((siteRoute, siteRouteIndex) => {
							siteRoute.active = (subRouteIndex === siteRouteIndex)
						})
					}
				})

				return updatedNavigation
			})
		}
		selectedRoute && handleSelectedRoute()
	}, [selectedRoute])


	const getRouteElement = (route, index, subRouteIndex) => {

		const { subsites = [] } = route

		//LINK TO SUB-ROUTE OPTION
		if (subRouteIndex === 0 || subRouteIndex) {

			const callback = () =>
				setSelectedRoute({ mainRouteIndex: index, subRouteIndex, showSubsites: Boolean(subsites) })

			const path = route.path
			const key = `${index - subRouteIndex}`
			const listSClassName = (route.active ? 'active' : '')
			const linkClassName = (route.active ? 'toggled' : '')
			const name = tl(route.name)

			return <li key={key} onClick={callback} className={listSClassName}>
				<Link to={path} className={linkClassName} >
					{name}
				</Link>
			</li>
		}

		//SHOWS SUB-ROUTE OPTIONS
		if (subsites.length) {

			const callback = () => {
				const showSubsites = Boolean((selectedRoute.mainRouteIndex === index) ? (!selectedRoute.showSubsites && subsites) : subsites)
				setSelectedRoute({ mainRouteIndex: index, showSubsites: showSubsites })
			}

			const icon = route.icon
			const label = tl(route.label)
			const key = `${index - label}`

			return <a key={key} className='waves-effect waves-block' onClick={callback}>
				<i className='material-icons'>{icon}</i>
				<span>{label}</span>
			</a>
		}

		//LINK TO ROUTE OPTION WITHOUT SUB-ROUTES
		if (route.path) {

			const callback = () => setSelectedRoute({ mainRouteIndex: index })
			const path = route.path
			const icon = route.icon
			const label = tl(route.label)
			const key = `${index - label}`

			return <Link key={key} to={path} onClick={callback}>
				<i className='material-icons'>{icon}</i>
				<span>{label}</span>
			</Link>
		}
		return null
	}

	return navigationRoutes
		? <div className='menu'>
			<ul className='list'>
				{navigationRoutes.map((element, index) => {

					const routeClassName = ((element.active || element.highlight) ? 'active' : '')
					const elementStyles = { display: element.showSubsites ? 'block' : 'none' }

					return <li key={index} className={routeClassName}>

						{getRouteElement(element, index)}

						<ul className='ml-menu' style={elementStyles}>

							{element.subsites && element.subsites
								.sort((a, b) => a.menuOrder - b.menuOrder)
								.map((subelement, _index) => getRouteElement(subelement, index, _index))}
						</ul>
					</li>
				})}
			</ul>
		</div>
		: null
}

export default Menu
