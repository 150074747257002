import React, {Component} from 'react'
import api from '../../../../utils/api'
import handleError from '../../../../utils/handleError'
import {translate as tl} from '../../../../utils/translation'
import Text from './Text'
import Array from './Array'
import Textarea from './Textarea'
import Select from './Select'
import Checkbox from './Checkbox'
import DatePicker from './DatePicker'

export default class ObjectArray extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
    this.handleChange = this.handleChange.bind(this)
  }

  async componentWillMount () {
    const {fields} = this.props
    let tempState = {}
    const populateFields = fields.filter(y => y.type === 'select' && y.options_callback)
    for (let field of populateFields) {
      try {
        const fieldResponse = await api(`/${field.options_callback[0]}`, 'GET', {
          fields: field.options_callback[1]
        })
        tempState = Object.assign(tempState, {
          [field.alias]: fieldResponse.data.data.map(item => {
            return {key: item._id, value: item[field.options_callback[1]]}
          })
        })
        this.setState(tempState)
      } catch (error) {
        handleError(error, this.props)
      }
    }
  }

  handleChange (index, alias, value) {
    let {items} = this.props
    items[index][`${alias}`] = value
    this.props.handleChange(this.props.name, items)
  }

  deleteElement (index) {
    let {items} = this.props
    items.splice(index, 1)
    this.props.handleChange(this.props.name, items)
  }

  createElement () {
    let {items, fields} = this.props

    // Items can sometimes be undefined
    if (!items) {
      items = []
    }

    let newObject = {}
    for (const field of fields) newObject[field.alias] = ''
    items.unshift(newObject)
    this.props.handleChange(this.props.name, items)
  }

  render () {
    const {items} = this.props
    const {fields, headline} = this.props
    return (
      <div>
        <b>{tl(headline)}</b><i onClick={(e) => this.createElement()} title={tl('backend_action_create')} style={{color: 'green', cursor: 'pointer'}} className='material-icons'>add_box</i>
        {items && items.map((item, index) => {
          return (
            <div key={index} className='container-fluid'>
              <div className='form-group object-array-form'>
                {fields.map((field, key) => {
                  if (field.type === 'text') {
                    return <Text key={index + key} headline={field.name} name={field.alias} handleChange={(e) => this.handleChange(index, field.alias, e.target.value)} value={item[`${field.alias}`]} />
                  }
                  if (field.type === 'array') {
                    return <Array key={item._id + key} headline={field.name} name={field.alias} handleChange={this.handleChange} value={item[`${field.alias}`]} isDisabled={field.isDisabled} />
                  }
                  if (field.type === 'textarea') {
                    return <Textarea key={item._id + key} headline={field.name} name={field.alias} value={item[`${field.alias}`]} handleChange={(e) => this.handleChange(index, field.alias, e.target.value)} />
                  }
                  if (field.type === 'select') {
                    return <Select multiple={field.multiple} key={`${field.alias}${item._id + key}`} headline={field.name} alias={`${field.alias}${item._id + key}`} options={this.state[`${field.alias}`]} create={this.props.create} values={item[`${field.alias}`]} handleChange={(values) => this.handleChange(index, field.alias, values)} />
                  }
                  if (field.type === 'checkbox') {
                    return <Checkbox key={item._id + key} headline={field.name} name={field.alias} checked={item[`${field.alias}`]} handleChange={(e) => this.handleChange(index, field.alias, (!this.state.item[`${field.alias}`]))} />
                  }
                  if (field.type === 'date') {
                    return <DatePicker key={item._id + key} headline={field.name} name={field.alias} checked={item[`${field.alias}`]} value={item[`${field.alias}`]} datePickerInit={this.datePickerInit} handleChange={(e) => this.handleChange(index, field.alias, e.target.value)} />
                  }

                  if (field.type === 'objectArray') {
                    return <ObjectArray key={item._id + key} headline={field.name} fields={field.fields} name={field.alias} items={item[`${field.alias}`]} handleChange={this.handleChange} create={this.props.create} />
                  }
                  return ''
                })}
                <i title={tl('backend_action_delete')} onClick={(e) => this.deleteElement(index)} style={{color: 'red', cursor: 'pointer'}} className='material-icons'>delete_forever</i>
              </div>
            </div>
          )
        })
        }
      </div>
    )
  }
}
