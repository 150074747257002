import React, {Component} from 'react'
import api from '../../../utils/api'
import handleError from '../../../utils/handleError'
import TableHead from './TableHead'
import TableBody from './TableBody'
import TableNavigation from './TableNavigation'
import TableSearch from './TableSearch'
import Container from '../Container'

export default class Table extends Component {
  constructor (props) {
    super(props)
    const {model} = props.config
    const populate = props.config.fields.filter(field => field.populate).map(populated => populated.alias).join(' ')

    this.state = {
      model,
      data: [],
      pagesize: 20,
      page: 1,
      search: '',
      sort: '',
      order: 'asc',
      limits: [20, 50, 100],
      totalCount: 1,
      populate
    }

    this.updateTable = this.updateTable.bind(this)
  }

  async componentWillMount () {
    const {pagesize, page} = this.state
    await this.updateTable({pagesize, page})
  }

  async componentDidUpdate (prevProps, prevState) {
    if (prevProps.reload !== this.props.reload) {
      const {page, pagesize, search, sort, order, populate} = this.state
      await this.updateTable({page, pagesize, search, sort, order, populate})
    }
  }

  async updateTable (value = {}) {
    const totalCount = this.state.totalCount || 1
    const page = value.page !== undefined ? value.page : this.state.page
    const pagesize = value.pagesize !== undefined ? value.pagesize : this.state.pagesize
    const search = value.search !== undefined ? value.search : this.state.search
    const sort = value.sort !== undefined ? value.sort : this.state.sort
    const order = value.order !== undefined ? value.order : this.state.order
    const {populate} = this.state

    const params = {page, pagesize, search, sort, order, populate}

    if (isNaN(page) || page < 1 || page > Math.ceil(totalCount / pagesize)) {
      return
    }

    try {
      const response = await api(`/${this.state.model}`, 'GET', params)
      const {data, totalCount} = response.data
      this.setState({data, totalCount, page, pagesize, search, sort, order})
    } catch (error) {
      handleError(error, this.props)
    }
  }

  render () {
    let {tableFields, displayFields} = this.props.config
    if (displayFields) tableFields = displayFields
    const {fields, renderTableRowClass, model} = this.props.config
    const {title, createTitle, exportTitle, exportLeadsTitle, reportTitle, exportMediaCodeCustomers, importReturnDebitTitle, importCodesTitle, updateLeadsTitle, uploadDalsonLeadsTitle} = this.props
    const {data, page, totalCount, limits, pagesize, sort, order} = this.state

    return (
      <div>
        <Container updateTable={this.updateTable} title={title} createTitle={createTitle} exportTitle={exportTitle} exportLeadsTitle={exportLeadsTitle} reportTitle={reportTitle} exportMediaCodeCustomers={exportMediaCodeCustomers} importReturnDebitTitle={importReturnDebitTitle} importCodesTitle={importCodesTitle} updateLeadsTitle={updateLeadsTitle} uploadDalsonLeadsTitle={uploadDalsonLeadsTitle} config={this.props.config}>
          <TableSearch updateTable={this.updateTable} />
          <div className='table-responsive'>
            <table className='table col-xs-12 table-hover'>
              <TableHead tableFields={tableFields} fields={fields} updateTable={this.updateTable} sort={sort} order={order} />
              <TableBody {...this.props} updateTable={this.updateTable} page={page} model={model} tableFields={tableFields} fields={fields} data={data} renderTableRowClass={renderTableRowClass} config={this.props.config} configs={this.props.configs} />
            </table>
          </div>
          <TableNavigation updateTable={this.updateTable} totalCount={totalCount} page={page} pagesize={pagesize} limits={limits} />
          <div className='clearfix' />
        </Container>
      </div>
    )
  }
}
