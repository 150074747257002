import React from 'react'
import {translate as tl} from '../../../utils/translation'

const TableSearch = ({updateTable}) => {
  const handleOnChange = (e) => {
    updateTable({search: e.target.value, page: 1})
  }

  return (
    <div>
      <div className='pull-right'>
        <div className='form-group'>
          <div className='form-line'>
            <input onChange={(e) => handleOnChange(e)} placeholder={`${tl('backend_search_placeholder')}`} type='text' name='search' className='form-control' id='search' />
          </div>
        </div>
      </div>
      <div className='clearfix' />
    </div>
  )
}
export default TableSearch
