import React from 'react'
import {translate as tl} from '../../../../utils/translation'

const ViewRouteLog = ({row}) => {
  return (
    <span>
      <div className='modal fade' id={(row) ? `viewlog${row._id}` : 'viewLogModal'} tabIndex='-1' role='dialog'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button style={{position: 'relative', zIndex: 10}} type='button' className='close' data-dismiss='modal'>&times;</button>
            </div>
            <div className='modal-body'>
              <p>{!!row.error && (tl('backend_field_error') + ': ' + row.error)}</p>
              <p>{tl('backend_field_method')}: {row.method}</p>
              <p>{tl('backend_field_alias')}: {row.alias}</p>
              <p>{tl('backend_field_user')}: {row.user ? row.username : '-'}</p>
              <p>{tl('backend_field_ip')}: {row.ip}</p>
              <p>{tl('backend_field_created_at')}: {new Date(row.createdAt).toString()}</p>
              <p>{tl('baclend_field_statusCode')}: {row.statusCode}</p>
              <p>{tl('backend_field_response')}:</p>
              <div><pre>{JSON.stringify(row.response, null, 2)}</pre></div>
              <p>{tl('backend_field_header')}:</p>
              <div><pre>{JSON.stringify(row.header, null, 2)}</pre></div>
              <p>{tl('backend_field_body')}:</p>
              <div><pre>{JSON.stringify(row.body, null, 2)}</pre></div>

            </div>
          </div>
        </div>
      </div>
      <a>
        <i style={{ 'cursor': 'pointer' }} title={tl('backend_action_preview_log')} data-toggle='modal' data-target={`#viewlog${row._id}`} className='material-icons medium'>visibility</i>
      </a>
    </span>
  )
}
export default ViewRouteLog
