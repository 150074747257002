import React from 'react'
import api from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import {translate as tl} from '../../../../utils/translation'

const Deactivate = (props) => {
  const handleClick = async () => {
    const id = props.row._id
    const {model, updateTable, page} = props
    let {row} = props
    if (model === 'crm/customers') {
      row = {'action': 'deactivate'}
    } else {
      row['active'] = !row.active
    }
    try {
      const response = await api(`/${model}/${id}`, 'PUT', null, row)
      notifySuccess(response.data.message)
      updateTable({page})
    } catch (error) {
      handleError(error, props)
    }
  }

  return (
    <a>
      <i title={(props.row['active'] || props.row.status === 'active') ? `${tl('backend_action_deactivate')}` : `${tl('backend_action_activate')}`} style={{ 'cursor': 'pointer' }} onClick={(e) => { handleClick() }} className='material-icons medium'>{(props.row['active'] || props.row.status === 'active') ? 'lock_open' : 'lock_outline'}</i>
    </a>
  )
}
export default Deactivate
