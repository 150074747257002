import React from 'react'
import {translate as tl} from '../../../../utils/translation'

const Text = ({ headline, name, value, handleChange, isDisabled }) => {
  return (
    <div>
      <b>{tl(headline)}</b>
      <div className='form-group'>
        <div className='input-group'>
          <div className='form-line'>
            <input onChange={handleChange} type='password' name={name} value={value || ''} disabled={isDisabled} className='form-control' aria-invalid='false' />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Text
