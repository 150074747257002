import cookie from 'react-cookies'
import api from '../utils/api'

let companies

export default async function() {
  if(!companies) {
    companies = (await api('/core/companies', 'GET', {}, {}, undefined, {
      key: 'thunderchild-de'
    }, {}, 'thunderchild')).data.data.companies
  }
  
  return companies.find(company => company.key === cookie.load('__co'))
}

export async function getCompanies() {
  return companies
}