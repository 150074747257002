import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import getConfig from './config/app'

import Login from './pages/Login'
import Wrapper from './pages/Wrapper'

import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'

import { init as translationsInit } from './utils/translation'

const hist = createBrowserHistory()

const App = () => {

  const [ready, setReady] = useState(false)
  const [selectedLang, setSelectedLang] = useState(false)
  const [availableLang, setAvailableLang] = useState(false)


  const setupPage = (providedConfig) => {

    const favico = document.querySelector('#favicon')
    if (favico) favico.remove()

    document.title = ('Backend | ' + (providedConfig || { title: 'Thunderchild' }).title)

    const link = document.createElement('link')
    link.setAttribute('id', 'favicon')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = (providedConfig || { favicon: '/favicons/thunderchild.ico' }.favicon)

    document.getElementsByTagName('head')[0].appendChild(link)
  }

  useEffect(() => {

    const getAvailableLang = async () => {
      try {

        setReady(false)
        const availableLanguages = await translationsInit(selectedLang)
        setAvailableLang(availableLanguages)
        setReady(true)

      } catch ({ message }) {
        console.log("ERROR_App_availableLang", message)
      }
    }
    selectedLang && getAvailableLang()
  }, [selectedLang])


  useEffect(() => {

    const buildFirstload = async () => {
      try {

        const config = await getConfig()
        setupPage(config)
        setSelectedLang(cookie.load('__lang') || 'en')

      } catch ({ message }) {
        console.log("ERROR_App_load", message)
      }
    }
    buildFirstload()
  }, [])

  return (
    <Router history={hist}>
      {ready &&
        <Switch>
          <Route
            exact path='/login'
            render={(props) => <Login {...props} setupPage={setupPage} />}
          />
          <Route
            path='/'
            render={(props) =>
              <Wrapper
                {...props}
                setupPage={setupPage}
                setLanguage={setSelectedLang}
                availableLanguages={availableLang}
                language={selectedLang} />
            }>
          </Route>
        </Switch>
      }
    </Router>
  )
}

export default App