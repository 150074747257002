import React from 'react'
import api from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import {translate as tl} from '../../../../utils/translation'
import swal from 'sweetalert'

const ProductappointmentSendInformation = (props) => {
  const handleClick = async () => {
    const {row, updateTable, page} = props
    const willSendEmail = await swal({
      title: `${tl('backend_are_you_sure')}`,
      text: `${tl('backend_sendInformation_are_you_sure_you_want_to_send_email')}`,
      icon: 'warning',
      buttons: true,
      dangerMode: true
    })

    if (!willSendEmail) {
      return
    }

    try {
      const response = await api(`/shop_mediamarkt/productappointment/information/${row._id}`, 'GET')
      notifySuccess(response.data.message)
      updateTable({page})
    } catch (error) {
      handleError(error, props)
    }
  }

  return (
    <a>
      <i className='material-icons' style={{ 'cursor': 'pointer' }} title={tl('backend_action_productappointment_send_information')} onClick={(e) => { handleClick() }}>mail</i>
    </a>
  )
}
export default ProductappointmentSendInformation
