import React from 'react'
import api from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'

const ContactrequestDone = (props) => {
  const handleClick = async () => {
    const {row, updateTable, page} = props
    const data = {status: 'done'}
    try {
      const response = await api(`/contact/requests/${row._id}`, 'PUT', null, data)
      notifySuccess(response.data.message)
      updateTable({page})
    } catch (error) {
      handleError(error, props)
    }
  }

  const { status } = props.row

  if (status === 'done') {
    return (
      <a className='col-green'>
        <i title={status} className='material-icons'>done_all</i>
      </a>
    )
  }

  if (status === 'open') {
    return (
      <a className='col-grey'>
        <i className='material-icons' style={{ 'cursor': 'pointer' }} title={status} onClick={(e) => { handleClick() }}>done</i>
      </a>
    )
  }
  return ''
}
export default ContactrequestDone
