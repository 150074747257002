
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { translate as tl } from '../utils/translation'
import cookie from 'react-cookies'

const Header = (props) => {

	const { availableModules, language, config, children, availableCompanies, configs, company, availableLanguages, setLanguage, setCompany } = props

	const [headerIcons, setHeaderIcons] = useState(false)
	const [headerData, setHeaderData] = useState(false)

	//COMMON ELEMENTS
	const elements = {
		toggle: (name, icon) => <a className='dropdown-toggle' data-toggle='dropdown' role='button' style={{ cursor: 'pointer' }}>
			<i title={name} className='material-icons'>{icon}</i>
		</a>,

		option: (logo, title, alt, selected, callback, styles, index) => {

			const selectedStyles = { fontSize: '20px', marginBottom: '-10px', fontWeight: 'bold' }
			const logoStyles = { display: 'flex', flexDirection: 'row', flexGrow: 2, marginLeft: '20px', alignItems: 'center' }
			const isSelected = selected ? <i style={selectedStyles} className='material-icons language-selected'>chevron_right</i> : null

			return <li
				key={index}
				className='waves-effect waves-block language-select'
				style={styles}
				onClick={callback}
			>
				{isSelected}
				<div style={logoStyles}>
					<img
						className='icon-flag'
						alt={alt}
						src={logo}
					/>
					{title}
				</div>
			</li>
		}
	}

	useEffect(() => {
		const buildHederData = async () => {
			try {
				const asynCallbacks = []

				const icons = availableModules.reduce((mAccum, module) => {

					if (!module.pages || !module.pages.length) return mAccum

					const headers = module.pages.reduce((pAccum, page) => {

						if (!page.header || !page.header.length) return pAccum

						const headerIcons = page.header.reduce((iAccum, headerIcon) => {

							if (headerIcon.header && headerIcon.header.badgeAction) asynCallbacks.push(headerIcon.header.badgeAction())
							return [...iAccum, headerIcon]
						}, [])

						return [...pAccum, ...headerIcons]

					}, [])

					return [...mAccum, ...headers]

				}, []).sort((a, b) => a.header.order - b.header.order)

				if (asynCallbacks.length) {
					const values = await Promise.all(asynCallbacks)
					const newData = headerData ? Object.assign(headerData) : {}

					icons.forEch((icon, index) => {
						const data = icon.header && icon.header.badgeAction ? values[index] : false
						if (data) newData[icon.alias] = data
					})

					setHeaderData(newData)
				}

				icons.length && setHeaderIcons(icons)
			} catch ({ message }) {
				console.log("ERROR_HEADER_buildheaderdata", message)
			}
		}
		availableModules && buildHederData()
	}, [availableModules])


	const pageIcons = useMemo(() => {

		const formatHeaderIcons = () =>

			headerIcons.map((page, key) => {

				const path = page.path
				const name = tl(page.name)
				const icon = page.header.icon
				const content = headerData[page.alias]
				const callback = () => page.action
					? page.action()
					: window.$('.navbar-collapse').removeClass('in')

				return <li key={`${key}`}>
					<Link onClick={callback} to={path}>
						<i title={name} className='material-icons'>{icon}</i>
						<span key={key} className='label-count'>{content}</span>
					</Link>
				</li>
			})

		return headerIcons && formatHeaderIcons()

	}, [headerIcons])

	const logoutIcon = useMemo(() => {

		const logout = {
			title: tl('backend_logout'),
			path: '/login',
			icon: 'exit_to_app',
			action: () => cookie.remove('token', { path: '/' })
		}
		return <li className='pull-right'>
			<Link to={logout.path} onClick={logout.action}>
				<i
					title={logout.title}
					className='material-icons'>
					{logout.icon}
				</i>
			</Link>
		</li>
	}, [])

	const homeMenu = useMemo(() => {

		const buildHomeMenu = () =>
			<div className='navbar-header'>
				{children
					? [<a
						data-toggle='collapse'
						data-target='#navbar-collapse'
						aria-expanded='false'
						className='navbar-toggle collapsed'
					>
						{children}
					</a>,
					<a
						style={{ display: 'none', cursor: 'pointer' }}
						className='bars'
					>
						{children}
					</a>]
					: null
				}
				{config
					? <Link
						to={'/dashboard'}
						title={`${config.title} Backend`}
						className='navbar-brand no-top-bottom-padding'
					>
						<img src={config.logo} alt='logo' height='50' />
					</Link>
					: null}
			</div>

		return (config || children) && buildHomeMenu()
	}, [config, children])

	const companyOptions = useMemo(() => {

		const label = {
			name: 'backend_companies_menu_title',
			alias: 'label',
			icon: 'format_list_bulleted'
		}
		const openToggle = elements.toggle(tl(label.name), label.icon)
		const optionsTitle = tl(label.name)

		return (availableCompanies.length > 1)
			? <li className='dropdown'>
				{openToggle}
				<ul className='dropdown-menu'>
					<li className='header'>{optionsTitle}</li>
					<li className='body'>
						<div className='slimScrollDiv'>
							<ul className='menu'>
								{
									configs.length && availableCompanies.map((_company, index) => {

										const selected = (company === _company)
										const { logo, title } = configs.find(i => i.key === _company)
										const styles = {
											fontWeight: 'normal',
											display: 'flex',
											justifyContent: 'space-around',
											padding: '10px'
										}
										if (selected) styles.fontWeight = 'bold'
										const callback = () => selected ? {} : setCompany(_company, props)

										return elements.option(logo, title, _company, selected, callback, styles, index)
									})
								}
							</ul>
						</div>
					</li>
				</ul>
			</li>
			: null

	}, [availableCompanies, configs, company])

	const languageOptions = useMemo(() => {

		const languages = {
			name: 'backend_language_menu_title',
			alias: 'languages',
			icon: 'language',
			language_options: [
				{
					alias: 'en',
					name: 'backend_language_menu_english'
				}, {
					alias: 'de',
					name: 'backend_language_menu_german'
				}, {
					alias: 'fr',
					name: 'backend_language_menu_french'
				}, {
					alias: 'es',
					name: 'backend_language_menu_spanish'
				}, {
					alias: 'it',
					name: 'backend_language_menu_italian'
				}
			]
		}

		const filteredLanguages = languages.language_options.filter(language => availableLanguages.includes(language.alias))
		const openToggle = elements.toggle(tl(languages.name), languages.icon)
		const optionsTitle = tl(languages.name)

		return (filteredLanguages.length > 1)
			? <li className='dropdown'>
				{openToggle}
				<ul className='dropdown-menu'>
					<li className='header'>{optionsTitle}</li>
					<li className='body'>
						<div className='slimScrollDiv'>
							<ul className='menu'>
								{
									filteredLanguages.map((_language, index) => {

										const selected = (language === _language.alias)
										const title = tl(_language.name)
										const logo = `https:lipis.github.io/flag-icon-css/flags/4x3/${_language.alias.replace('en', 'gb')}.svg`
										const styles = {
											fontWeight: 'normal',
											display: 'flex',
											justifyContent: 'space-around',
											padding: '10px'
										}
										if (selected) styles.fontWeight = 'bold'
										const callback = () => selected ? {} : setLanguage(_language.alias)

										return elements.option(logo, title, title, selected, callback, styles, index)
									})
								}
							</ul>
						</div>
					</li>
				</ul>
			</li>
			: null

	}, [availableLanguages, language])

	return <nav className='navbar'>
		{homeMenu}
		<div className='container-fluid'>
			<div id='navbar-collapse' aria-expanded='false' className='navbar-collapse collapse'>
				<ul className='nav navbar-nav navbar-right'>
					{pageIcons}
					{languageOptions}
					{companyOptions}
					{logoutIcon}
				</ul>
			</div>
		</div>
	</nav>
}

export default Header