import React from 'react'
import Edit from './Edit'
import Delete from './Delete'
import Preview from './Preview'
import Deactivate from './Deactivate'
import SoftDelete from './SoftDelete'
import ResendConfirmation from './ResendConfirmation'
import PayoffConfirm from './PayoffConfirm'
import PayoffCancel from './PayoffCancel'
import ViewCustomer from './ViewCustomer'
import ViewUserLog from './ViewUserLog'
import ViewData from './ViewData'
import ViewRouteLog from './ViewRouteLog'
import ContactrequestDone from './ContactrequestDone'
import ViewOrder from './ViewOrder'
import ViewPermission from './ViewPermission'
import OrderConfirm from './OrderConfirm'
import ProductappointmentFull from './ProductappointmentFull'
import ProductappointmentSendInformation from './ProductappointmentSendInformation'
import Contact from './Contact'
import Link from './Link'
import Annotation from './Annotation'
import StepsAction from './StepsAction'
import { withRouter } from 'react-router-dom'
import ResendWelcomeMail from './ResendWelcomeMail'


const ActionsContainer = props => {
	const { actions, model, row, updateTable, page, config, configs, history } = props
	return (
		<span>
			{actions
				.filter(action => action.condition(row))
				.map((action, key) => {
					switch (action.type) {
						case 'edit':
							return <Edit {...props} config={config} model={model} updateTable={updateTable} row={row} key={row._id} />
						case 'delete':
							return <Delete {...props} updateTable={updateTable} page={page} model={model} row={row} key={key} />
						case 'preview':
							return <Preview row={row} key={key} />
						case 'deactivate':
							return <Deactivate {...props} updateTable={updateTable} page={page} model={model} row={row} key={key} />
						case 'softDelete':
							return <SoftDelete {...props} updateTable={updateTable} page={page} model={model} row={row} key={key} />
						case 'lead_resend_confirmation':
							return <ResendConfirmation {...props} updateTable={updateTable} page={page} model={model} row={row} key={key} />
						case 'payoff_confirm':
							return <PayoffConfirm {...props} updateTable={updateTable} page={page} model={model} row={row} key={key} />
						case 'payoff_cancel':
							return <PayoffCancel {...props} updateTable={updateTable} page={page} model={model} row={row} key={key} />
						case 'view_customer':
							return <ViewCustomer {...props} config={config} model={model} updateTable={updateTable} row={row} key={key} />
						case 'resend_welcome_mail':
							return <ResendWelcomeMail {...props} config={config} model={model} updateTable={updateTable} row={row} key={key} />
						case 'contactrequest_done':
							return (
								<ContactrequestDone
									{...props}
									config={config}
									model={model}
									updateTable={updateTable}
									row={row}
									key={key}
								/>
							)
						case 'view_userlog':
							return <ViewUserLog row={row} key={key} />
						case 'view_data':
							return <ViewData row={row} key={key} />
						case 'view_permission':
							return <ViewPermission row={row} key={key} configs={configs} />
						case 'view_route_log':
							return <ViewRouteLog row={row} key={key} />
						case 'view_order':
							return <ViewOrder {...props} config={config} model={model} updateTable={updateTable} row={row} key={key} />
						case 'order_confirm':
							return <OrderConfirm {...props} updateTable={updateTable} page={page} model={model} row={row} key={key} />
						case 'productappointment_full':
							return (
								<ProductappointmentFull
									{...props}
									updateTable={updateTable}
									page={page}
									model={model}
									row={row}
									key={key}
								/>
							)
						case 'productappointment_send_information':
							return (
								<ProductappointmentSendInformation
									{...props}
									updateTable={updateTable}
									page={page}
									model={model}
									row={row}
									key={key}
								/>
							)
						case 'contact':
							return <Contact row={row} key={key} updateTable={updateTable} />
						case 'link':
							return <Link row={row} key={key} />
						case 'steps':
							return <StepsAction row={row} history={history} config={config} />
						case 'annotation':
							return <Annotation {...props} updateTable={updateTable} page={page} model={model} row={row} key={key} />
						default:
							return false
					}
				})}
		</span>
	)
}
export default withRouter(ActionsContainer)
