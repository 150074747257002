import React, {Component} from 'react'
import EditForm from '../../form/EditForm'
import Modal from '../../modal/Modal'
import {translate as tl} from '../../../../utils/translation'

export default class EditModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loadEdit: false
    }
    this.toggleEdit = this.toggleEdit.bind(this)
  }

  async componentWillMount () {
    await this.setState({
      reload: this.props.reload,
      loadEdit: false
    })
  }

  toggleEdit (bool) {
    this.setState({
      loadEdit: bool
    })
  }

  render () {
    const {model, row, config, updateTable} = this.props
    return (
      <span>
        <Modal toggleEdit={this.toggleEdit} row={row} model={model}>
          {
            (this.state.loadEdit) ? <EditForm {...this.props} toggleEdit={this.toggleEdit} updateTable={updateTable} id={row._id} model={model} config={config} /> : null
          }
        </Modal>
        <a>
          <i 
            onClick={() => { 
              if(row.status === 'canceled') {
                return 
              }
              this.toggleEdit(true) 
            }} 
            style={{ 
              cursor: row.status === 'canceled' ? 'default' : 'pointer',
              color: row.status === 'canceled' ? 'gray' : 'inherit',
              opacity: row.status === 'canceled' ? 0.5 : 1,
            }} 
            title={tl('backend_action_edit')} 
            data-toggle='modal' 
            data-target={`#${row._id}`} 
            className='material-icons medium'
          >
              edit
          </i>
        </a>
      </span>
    )
  }
}
