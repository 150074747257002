import React from 'react'
import api from './api'
import cookie from 'react-cookies'
import rawTranslations from '../config/translations'

let translations
const availableLanguages = ['de', 'en']

export async function init (language) {
  
  try {
    translations = rawTranslations.map((entry) => {
      return {'key': entry.key, 'value': entry[language]}
    })

    cookie.save('__lang', language, { path: '/' })
  } catch (error) {
    throw error
  }

  return availableLanguages
}

export function translate (key, replacements) {
  const translation = translations.find(translation => translation.key === key)

  if (!translation) {
    return key
  }

  let value = translation.value

  if (replacements) {
    replacements.forEach(replacement => {
      if (replacement.html) {
        const string = value.replace(`{{${replacement.key}}}`, replacement.value)
        const d = React.createElement('div', {dangerouslySetInnerHTML: {__html: string}})
        value = d
      } else {
        value = value.replace(`{{${replacement.key}}}`, replacement.value)
      }
    })
  }

  return value
}
