import React, {Component} from 'react'
import api from '../../../utils/api'
import date from '../../../utils/formatDate'
import handleError from '../../../utils/handleError'
import {notifySuccess} from '../../../utils/notifications'
import {translate as tl} from '../../../utils/translation'
import autosize from 'autosize'
import Text from './field/Text'
import Password from './field/Password'
import Array from './field/Array'
import Textarea from './field/Textarea'
import WYSIWYG from './field/WYSIWYG'
import Select from './field/Select'
import Checkbox from './field/Checkbox'
import DatePicker from './field/DatePicker'
import ObjectArray from './field/ObjectArray'
import LocalStorage from 'local-storage'

export default class EditForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      item: {}
    }
    this.handleChange = this.handleChange.bind(this)
    this.saveData = this.saveData.bind(this)
    this.datePickerInit = this.datePickerInit.bind(this)
  }

  async componentWillMount () {
    const {$} = window

    let tempState = {}

    const {editFields, fields, model} = this.props.config
    const id = this.props.id || ''

    if (id) {
      try {
        const response = await api(`/${model}/${id}`, 'GET')

        tempState = Object.assign(tempState, {
          item: response.data.data
        })
      } catch (error) {
        handleError(error, this.props)
      }
    }

    const populateFields = editFields.map(field => fields.find(i => i.alias === field)).filter(y => y.type === 'select' && y.options_callback)

    const staticOptions = editFields.map(field => fields.find(i => i.alias === field)).filter(y => y.type === 'select' && y.options)

    if (staticOptions) {
      for (let field of staticOptions) {
        tempState = Object.assign(tempState, {
          [field.alias]: field.options
        })
      }
    }

    if (populateFields) {
      for (let field of populateFields) {
        try {
          const fieldResponse = await api(`/${field.options_callback[0]}`, 'GET', {
            fields: field.options_callback[1]
          })
          tempState = Object.assign(tempState, {
            [field.alias]: fieldResponse.data.data.map(item => {
              return {key: item._id, value: item[field.options_callback[1]]}
            })
          })
        } catch (error) {
          handleError(error, this.props)
        }
      }
    }
    this.setState(tempState)
    autosize($('textarea'))
  }

  handleChange (key, value) {
    let item = Object.assign({}, this.state.item)
    item[key] = value
    // console.log(key)
    // console.log(value)
    // console.log(item)
    this.setState({item})
  }

  datePickerInit () {
    const {$} = window
    const inputs = document.querySelectorAll('.date-picker')
    inputs.forEach(input => {
      $('.date-picker').bootstrapMaterialDatePicker({time: false, format: 'YYYY-MM-DD'})
        .on('change', (e, val) => {
          if(e.target.name !== input.name) {
            return 
          }
          let item = Object.assign({}, this.state.item)
          val = date(window.moment(val).format())
          input.value = val
          item[input.name] = input.value
          this.setState({item})
        })
    })
  }

  async saveData () {
    const {$} = window
    const {model} = this.props.config
    const {updateTable, toggleEdit} = this.props
    const id = this.state.item._id || ''
    const data = Object.assign({}, this.state.item)
    const method = (id) ? 'PUT' : 'POST'

    try {
      const response = await api(`/${model}/${id}`, method, null, data)
      notifySuccess(response.data.message)
      updateTable()
      $('.modal').modal('hide')
      toggleEdit(false)
    } catch (error) {
      const {status} = error.response || error
      if (status === 401) {
        $('.modal').modal('hide')
      }
      handleError(error, this.props)
    }
  }

  render () {
    const { editFields, fields } = this.props.config
    const {toggleEdit, row} = this.props
    const {item} = this.state

    return (
      <div className='form-group'>
        {(this.props.config.model === 'ard-faq/faqquestions' || this.props.config.model === 'ard-faq/faqnews') &&
          <button 
            className="btn btn-primary pull-right"
            onClick={() => {
              if(this.props.config.model === 'ard-faq/faqquestions') {
                LocalStorage.set('question', item['question'])
                LocalStorage.set('answer', item['answer'])
              } else {
                LocalStorage.set('question', item['title'])
                LocalStorage.set('answer', item['description'])
              }
              window.open(`/ard-mediathek/preview`, "_blank")}
            }>
            Preview
          </button>
        }
        {
          editFields.map((field, key) => {
            field = fields.find(f => field === f.alias)

            if (field.type === 'text') {
              return <Text key={key} headline={field.name} name={field.alias} handleChange={(e) => this.handleChange(field.alias, e.target.value)} value={item[`${field.alias}`]} isDisabled={field.isDisabled} />
            }
            
            if (field.type === 'password') {
              return <Password key={key} headline={field.name} name={field.alias} handleChange={(e) => this.handleChange(field.alias, e.target.value)} value={item[`${field.alias}`]} isDisabled={field.isDisabled} />
            }

            if (field.type === 'array') {
              return <Array key={key} headline={field.name} name={field.alias} handleChange={this.handleChange} value={item[`${field.alias}`]} isDisabled={field.isDisabled} />
            }

            if (field.type === 'wysiwyg') {
              return <WYSIWYG key={key} headline={field.name} name={field.alias} value={item[`${field.alias}`]} handleChange={(value) => this.handleChange(field.alias, value)} />
            }

            if (field.type === 'textarea') {
              return <Textarea key={key} headline={field.name} name={field.alias} value={item[`${field.alias}`]} handleChange={(e) => this.handleChange(field.alias, e.target.value)} />
            }

            if (field.type === 'select') {
              return <Select multiple={field.multiple} key={key} row={row} headline={field.name} alias={field.alias} options={this.state[`${field.alias}`]} create={this.props.create} values={item[`${field.alias}`]} blank={field.blank} handleChange={(values) => this.handleChange(field.alias, values)} />
            }

            if (field.type === 'checkbox') {
              return <Checkbox key={key} headline={field.name} name={field.alias} checked={item[`${field.alias}`]} handleChange={(e) => this.handleChange(field.alias, (!this.state.item[`${field.alias}`]))} />
            }

            if (field.type === 'date') {
              return <DatePicker key={key} headline={field.name} name={field.alias} checked={item[`${field.alias}`]} value={item[`${field.alias}`]} datePickerInit={this.datePickerInit} handleChange={(e) => this.handleChange(field.alias, e.target.value)} />
            }

            if (field.type === 'objectArray') {
              return <ObjectArray key={key} headline={field.name} fields={field.fields} name={field.alias} items={item[`${field.alias}`] || []} handleChange={this.handleChange} create={this.props.create} />
            }

            return ''
          })
        }
        <div className='form-group'>
          <a onClick={() => { toggleEdit(false) }} data-dismiss='modal' className='btn btn-primary'>{tl('backend_back_button')}</a>
          <button className='btn btn-primary pull-right' onClick={this.saveData}>{tl('backend_save_button')}</button>
        </div>
      </div>
    )
  }
}
