import React from 'react'
import {translate as tl} from '../../../../utils/translation'

const Textarea = ({ headline, handleChange, name, value }) => {
  return (
    <div>
      <b>{tl(headline)}</b>
      <div className='form-group'>
        <div className='input-group'>
          <div className='form-line'>
            <textarea type='text' style={{height: '200px'}} name={name} className='tinymce form-control' value={value || ''} onChange={handleChange} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Textarea
