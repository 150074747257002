import axios from 'axios'
import cookie from 'react-cookies'
import apiUrl from '../config/apiUrl'
import findConfig from '../config/app'
import {notifyError, notifySuccess} from './notifications'
import {translate as tl} from './translation'
import swal from 'sweetalert'

const api = async (path, method, params = {}, data = {}, apiKey, config, headers = {}, responseType) => {
  if(!config) {
    config = await findConfig()
  }

  try {
    document.querySelector('.loader').style.display = 'flex'

    return await axios({
      url: `${apiUrl}${path}`,
      method: method,
      params: params,
      data: data,
      headers: {
        authorization: cookie.load('token') || undefined,
        'access-token': apiKey || config.key,
        ...headers
      },
      responseType
    })
  } finally {
    document.querySelector('.loader').style.display = 'none'
  }
}
export default api

export const removeItem = async (model, id) => { 
  const willDelete = await swal({
    title: `${tl('backend_are_you_sure')}`,
    text: `${tl('backend_delete_are_you_sure_you_want_to_delete_item_x1', [{key: 1, value: model}])}`,
    icon: 'warning',
    buttons: true,
    dangerMode: true
  })

  if (!willDelete) {
    return
  }

  try {
    document.querySelector('.loader').style.display = 'flex'
    const response = await api(`/${model}/${id}`, 'DELETE')
    notifySuccess(response.data.message)
  } catch (error) {
    const {status, data} = error.response || error
    const message = data.message

    if (status === 401) {
      cookie.remove('token', {path: '/'})
      notifyError(error)
    } else {
      notifyError(message || error)
    }
  } finally {
    document.querySelector('.loader').style.display = 'none'
  }
}
