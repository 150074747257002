import React from 'react'
import {translate as tl} from '../../../../utils/translation'

const Checkbox = ({ headline, name, handleChange, checked }) => {
  return (
    <div className='form-group'>
      <label htmlFor={name}><input id={name} type='checkbox' name={name} checked={checked || false} onChange={handleChange} /> {tl(headline)}</label>
    </div>
  )
}
export default Checkbox
