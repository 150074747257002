import React, {Component} from 'react'
import api from '../../utils/api'
import handleError from '../../utils/handleError'
import {translate as tl} from '../../utils/translation'
import Chart from 'chart.js'

import Container from '../helpers/Container'

export default class mailStatistics extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      rows: [],
      date: new Date()
    }
  }

  async componentDidMount () {
    await this.getData()
    try {
      void new Chart(document.getElementById('line-chart-outgoingmails'), {
        type: 'line',
        data: {
          labels: this.state.rows.date,
          datasets: [
            {
              data: this.state.rows.countOutgoingmails,
              label: `${tl('backend_statistics_label_outgoingmails')}`,
              borderColor: '#008000',
              fill: false
            }, {
              data: this.state.rows.countOutgoingmailsOpen,
              label: `${tl('backend_statistics_label_outgoingmails_open')}`,
              borderColor: '#03A9F4',
              fill: false
            }, {
              data: this.state.rows.countOutgoingmailsError,
              label: `${tl('backend_statistics_label_outgoingmails_error')}`,
              borderColor: '#F26101',
              fill: false
            }, {
              data: this.state.rows.countOutgoingmailsProcessing,
              label: `${tl('backend_statistics_label_outgoingmails_processing')}`,
              borderColor: '#F26121',
              fill: false
            }, {
              data: this.state.rows.countOutgoingmailsBlacklisted,
              label: `${tl('backend_statistics_label_outgoingmails_blacklisted')}`,
              borderColor: '#000000',
              fill: false
            }
          ]
        },
        options: {}
      })
    } catch (err) {
      // do nothing
    }
  }

  async getData () {
    try {
      const {data: {data, date}} = await api('/email/statistics', 'GET')
      this.setState({
        data: data,
        date: new Date(date),
        rows: data.reduce((object, row) => {
          Object.keys(row).forEach(key => {
            object[key] = object[key] ? [...object[key], row[key]] : [row[key]]
          })
          return object
        }, {})
      })
    } catch (error) {
      handleError(error, this.props)
    }
  }

  render () {
    return (
      <div>
        <p>{tl('backend_statistics_updated')}: {this.state.date.toLocaleString()}</p>
        <Container title='backend_statistics_title_outgoingmails'>
          <canvas id='line-chart-outgoingmails' />
        </Container>
      </div>
    )
  }
}
