import React from 'react'
import { translate as tl } from '../../../../utils/translation'
import { dateWithTime } from '../../../../utils/formatDate'

const ViewUserLog = ({ row }) => {
	return (
		<span>
			<div className="modal fade" id={row ? `viewlog${row._id}` : 'viewLogModal'} tabIndex="-1" role="dialog">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<button style={{ position: 'relative', zIndex: 10 }} type="button" className="close" data-dismiss="modal">
								&times;
							</button>
						</div>
						<div className="modal-body">
							<p>
								<b>{row.method}</b>
							</p>
							<p>{row.url}</p>
							<p>
								<b>
									{row.firstName} {row.lastName}
								</b>{' '}
								({row.email})
							</p>
							<div>
								<p>{tl('backend_field_header')}</p>
								<pre>{row.header ? JSON.stringify(row.header, null, 2) : `${tl('backend_no_data_available')}`}</pre>
							</div>
							<div>
								<p>{tl('backend_field_body')}</p>
								<pre>{row.body ? JSON.stringify(row.body, null, 2) : `${tl('backend_no_data_available')}`}</pre>
							</div>
						</div>
					</div>
				</div>
			</div>
			<a>
				<i
					style={{ cursor: 'pointer' }}
					title={tl('backend_action_preview_log')}
					data-toggle="modal"
					data-target={`#viewlog${row._id}`}
					className="material-icons medium"
				>
					visibility
				</i>
			</a>
		</span>
	)
}
export default ViewUserLog
