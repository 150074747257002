import React from 'react'
import Card from './card/Card'
import CardBody from './card/CardBody'
import CardHeader from './card/CardHeader'
import {translate as tl} from '../../utils/translation'

const Container = ({updateTable, title, createTitle, exportTitle, exportLeadsTitle, reportTitle, exportMediaCodeCustomers, importReturnDebitTitle, importCodesTitle, updateLeadsTitle, uploadDalsonLeadsTitle, config, children}) => {
  return (
    <div className='container-fluid'>
      <div className='row clearfix'>
        <div className='col-xs-12'>
          <Card>
            <CardHeader updateTable={updateTable} title={tl(title)} createTitle={tl(createTitle)} exportTitle={tl(exportTitle)} exportLeadsTitle={tl(exportLeadsTitle)} reportTitle={tl(reportTitle)} exportMediaCodeCustomers={tl(exportMediaCodeCustomers)} importReturnDebitTitle={tl(importReturnDebitTitle)} importCodesTitle={tl(importCodesTitle)} updateLeadsTitle={tl(updateLeadsTitle)} uploadDalsonLeadsTitle={tl(uploadDalsonLeadsTitle)} config={config} />
            <CardBody>
              {children}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
}
export default Container
