import React from 'react'
import {translate as tl} from '../../../../utils/translation'

const ViewLog = ({row}) => {
  return (
    <span>
      <div className='modal fade' id={(row) ? `viewdata${row._id}` : 'viewDataModal'} tabIndex='-1' role='dialog'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button style={{position: 'relative', zIndex: 10}} type='button' className='close' data-dismiss='modal'>&times;</button>
            </div>
            <div className='modal-body'>
              <p>{tl('backend_field_email')}: {row.email}</p>
              <div><pre>{row.data ? (JSON.stringify(row.data, null, 2)) : `${tl('backend_no_data_available')}`}</pre></div>
            </div>
          </div>
        </div>
      </div>
      <a>
        <i style={{ 'cursor': 'pointer' }} title={tl('backend_action_preview_log')} data-toggle='modal' data-target={`#viewdata${row._id}`} className='material-icons medium'>visibility</i>
      </a>
    </span>
  )
}
export default ViewLog
