import React, {Component} from 'react'

export default class Scripts extends Component {
  componentDidMount () {
    const scripts = [
      '/js/admin.js'
    ]
    scripts.forEach((script) => {
      const element = document.createElement('script')
      element.src = script
      element.async = false

      document.querySelector('#scripts').appendChild(element)
    })
  }

  render () {
    return (
      <div id='scripts' />
    )
  }
}
