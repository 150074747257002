import React from 'react'
import {translate as tl} from '../../../../utils/translation'

const Preview = ({row}) => {
  const content = row.html
  const {$} = window
  return (
    <span>
      <div onClick={() => $('.modal').modal('hide')} className='modal fade' id='previewModal' tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-lg' id='preview-modal-dialog' role='document' style={{whiteSpace: 'normal'}} />
      </div>
      <a>
        <i onClick={() => { $('#preview-modal-dialog').html(content) }} style={{ 'cursor': 'pointer' }} title={tl('backend_action_preview')} data-toggle='modal' data-target='#previewModal' className='material-icons medium'>visibility</i>
      </a>
    </span>
  )
}
export default Preview
