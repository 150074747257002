import api from '../utils/api'
//import availableModules from '../modules'
import cookie from 'react-cookies'
import insertFunctions from './insertFunctions'

const modules = async () => {
  let company = cookie.load('__co')

  const {data: {data: configs}} = await api('/core/admin/configs', 'GET', {}, {}, 'thunderchild-de')
  const availableModules = configs.map(config => insertFunctions(config))

  if(!company) {
    company = 'thunderchild-de'
  }

  const {data: {data: response}} = await api('/core/modules', 'GET', {company: company}, {}, 'thunderchild-de')
  const am = availableModules.filter(module => response.includes(module.name))

  return am
}

export default modules
