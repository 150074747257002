import React from 'react'
import api from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import {translate as tl} from '../../../../utils/translation'

const PayoffConfirm = (props) => {
  const handleClick = async () => {
    const {row, updateTable, page} = props
    const data = {status: 'payoff'}
    try {
      const response = await api(`/cashback/payoffs/${row._id}`, 'PUT', null, data)
      notifySuccess(response.data.message)
      updateTable({page})
    } catch (error) {
      handleError(error, props)
    }
  }

  return (
    <a className='col-green'>
      <i className='material-icons' style={{ 'cursor': 'pointer' }} title={tl('backend_action_payoff_confirm')} onClick={(e) => { handleClick() }}>euro_symbol</i>
    </a>
  )
}
export default PayoffConfirm
