import React, {Component} from 'react'
import api from '../../utils/api'
import handleError from '../../utils/handleError'
import {translate as tl} from '../../utils/translation'
import date from '../../utils/formatDate'
// import {dateWithTime as date} from '../../utils/formatDate'

import Container from '../helpers/Container'

export default class GcsolutionEasymillionsStatistic extends Component {
  constructor (props) {
    super(props)

    this.state = {
      data: [],
      rows: [],
      date: new Date()
    }
  }

  async componentDidMount () {
    await this.getData()
  }

  async getData () {
    try {
      const {data: {data, date}} = await api('/gc-solution/easymillionsstatistics', 'GET')
      this.setState({
        data: data,
        date: new Date(date),
        rows: data.reduce((object, row) => {
          Object.keys(row).forEach(key => {
            object[key] = object[key] ? [...object[key], row[key]] : [row[key]]
          })
          return object
        }, {})
      })
    } catch (error) {
      handleError(error, this.props)
    }
  }

  toPercent = (value, total) => {
    return ((value / total) * 100).toFixed(2)
  }

  render () {
    return (
      <div>
        <p>{tl('backend_statistics_updated')}: {this.state.date.toLocaleString()}</p>
        <Container title={tl('backend_page_name_gc-solution_easymillions')}>
          <table className='table'>
            <thead>
              <tr>
                <th>{tl('backend_field_mktcode')}</th>
                <th>{tl('backend_field_dataDeposit')}</th>
                <th>{tl('backend_field_sumSendings')}</th>
                <th>{tl('backend_field_sumContacts')}</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.data.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td>{row.mktcode}</td>
                      <td>{date(row.DataDeposit)}</td>
                      <td>{row.sumSendings}</td>
                      <td>{row.sumContacts}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <div className='clearfix' />
        </Container>
      </div>
    )
  }
}
