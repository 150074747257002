import React from 'react'
import {translate as tl} from '../../../../utils/translation'
import { dateWithTime } from '../../../../utils/formatDate'
import api from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      row: this.props.row,
      message: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
  }

  async handleClick(request, response) {
    try {
      const res = await api(`/ard-faq/contactresponses`, 'POST', null, {request, response})
      this.setState({message: ''})
      notifySuccess(res.data.message)
    } catch (error) {
      handleError(error)
    }
  }

  async changeStatus(event, id) {
    try {
      const newRow = this.state.row
      newRow.status = event.target.value
      this.setState({row: newRow})
      const response = await api(`/ard-faq/contactrequests/message/${id}`, 'POST', null, {status: event.target.value})
      notifySuccess(response.data.message)
      this.props.updateTable({})
    } catch(error) {
      handleError(error)
    }
  }

  render() {
    return (
      <span>
        <div className='modal fade' id={(this.state.row) ? `viewlog${this.state.row._id}` : 'viewLogModal'} tabIndex='-1' role='dialog'>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button style={{position: 'relative', zIndex: 10}} type='button' className='close' data-dismiss='modal'>&times;</button>
              </div>
              <div className='modal-body'>
                <p>{tl('backend_field_name')}: {this.state.row.name}</p>
                <p>{tl('backend_field_email')}: {this.state.row.email}</p>
                <p>{tl('backend_field_subject')}: {this.state.row.subject}</p>
                <p>{tl('backend_field_status')}: {this.state.row.status}</p>
                <select value={this.state.status} onChange={(event) => this.changeStatus(event, this.state.row._id)}>
                  <option value="open">open</option>
                  <option value="waiting">waiting</option>
                  <option value="processing">processing</option>
                  <option value="done">done</option>
                </select>
                <hr />
                  <div style={{marginBottom: '18px'}}>
                    <p style={{marginBottom: '15px'}}>{`${tl('backend_field_sender')}: ${this.state.row.name}`}</p>
                    <div className="speech-bubble" style={{whiteSpace: 'pre-line'}}>
                      {this.state.row.request}
                    </div>
                  </div>
                <div>
                  <textarea value={this.state.message} onChange={this.handleChange} style={{display: 'inline-block', width: '80%', marginTop: '20px', height: '80px' }}></textarea>
                  <button onClick={() => this.handleClick(this.state.row._id, this.state.message)} className="btn btn-primary pull-right" style={{display: 'inline-block', marginTop: '70px'}}>{tl('backend_field_send')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a>
          <i style={{ 'cursor': 'pointer' }} title={tl('backend_action_preview_log')} data-toggle='modal' data-target={`#viewlog${this.state.row._id}`} className='material-icons medium'>visibility</i>
        </a>
      </span>
    )  
  }

  handleChange(event) {
    event.preventDefault()
    this.setState({message: event.target.value})
  }
}

export default Contact
