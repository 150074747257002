import React from 'react'
import api from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import {translate as tl} from '../../../../utils/translation'

const ProductappointmentFull = (props) => {
  const handleClick = async () => {
    const {row, updateTable, page} = props
    const data = {status: 'canceled', canceledReason: 'Ausgebucht'}
    try {
      const response = await api(`/shop_mediamarkt/productappointments/${row._id}`, 'PUT', null, data)
      notifySuccess(response.data.message)
      updateTable({page})
    } catch (error) {
      handleError(error, props)
    }
  }

  return (
    <a>
      <i className='material-icons' style={{ 'cursor': 'pointer' }} title={tl('backend_action_productappointment_full')} onClick={(e) => { handleClick() }}>pan_tool</i>
    </a>
  )
}
export default ProductappointmentFull
