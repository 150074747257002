import {translate as tl} from './translation'

export const notifyError = (error = `${tl('backend_error_try_again')}`) => {
  const {$} = window
  $.notify({
    icon: 'glyphicon glyphicon-warning-sign',
    message: error
  }, {
    type: 'danger',
    allow_dismiss: true,
    delay: 1000,
    z_index: 2031,
    animate: {
      enter: 'animated fadeInRight',
      exit: 'animated fadeOutRight'
    },
    placement: {
      from: 'bottom',
      align: 'right'
    }
  })
}

export const notifySuccess = (response = `${tl('backend_data_updated')}`) => {
  const {$} = window
  $.notify({
    icon: 'glyphicon glyphicon-ok-circle',
    message: response
  }, {
    type: 'success',
    allow_dismiss: true,
    delay: 1000,
    z_index: 2031,
    animate: {
      enter: 'animated fadeInRight',
      exit: 'animated fadeOutRight'
    },
    placement: {
      from: 'bottom',
      align: 'right'
    }
  })
}
