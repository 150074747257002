import functions from './functions'
import {dumpKeysRecursively} from 'recursive-keys'

const insertFunctions = (config) => {
  const configKeys = dumpKeysRecursively(config)
  const functionIdentifiers = ['data', 'badgeAction', 'renderTableRowClass', 'render', 'condition']

  const functionsPaths = configKeys.filter(configKey => functionIdentifiers.includes(configKey.split('.').pop()))

  functionsPaths.forEach(path => {
    let pointer = config
    //Set the pointer behind the function attribute
    path.split('.').slice(0, -1).forEach(configKey => {pointer = pointer[configKey]})
    //Replace the attribute value with the required function
    pointer[path.split('.').pop()] = findFunction(pointer[path.split('.').pop()].name, pointer[path.split('.').pop()].arguments, config)
  })

  return config
}

const findFunction = (name, args, config) => {
  const found = functions.find(func => func.name === name)
  if(!found) {
    throw new Error(config.name + ':Function ' + name + ' not found')
  }

  if(args) {
    if(!found.generateFunc) {
      throw new Error(config.name + ':Function ' + name + ' has no parameters')
    }

    return found.generateFunc(args)
  }

  if(!found.func) {
    throw new Error(config.name + ':Function ' + name + ' require parameters')
  }

  return found.func
}

export default insertFunctions
