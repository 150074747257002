import React, {Component} from 'react'
import {translate as tl} from '../../../../utils/translation'

export default class Array extends Component {
  constructor (props) {
    super(props)

    this.state = {
      value: props.value || ['']
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({value: nextProps.value || ['']})
  }

  handleOnChange (element, key) {
    const array = this.state.value
    array[key] = element
    this.setState({value: array})
    this.props.handleChange(this.props.name, this.state.value)
  }

  addElement (key) {
    const array = this.state.value
    array.splice(key + 1, 0, '')
    this.setState({value: array})
    this.props.handleChange(this.props.name, this.state.value)
  }

  deleteElement (key) {
    const array = this.state.value
    array.splice(key, 1)
    if (!array.length) {
      this.addElement(0)
    }
    this.setState({value: array})
    this.props.handleChange(this.props.name, this.state.value)
  }

  render () {
    const { headline, name, isDisabled } = this.props
    const {value} = this.state
    return (<div>
      <b>{tl(headline)}</b>
      <div className='form-group'>
        {value.map((element, key) => {
          return (
            <div key={key} className='input-group'>
              <div className='form-line'>
                <input type='text' name={name} onChange={(e) => this.handleOnChange(e.target.value, key)} value={element || ''} disabled={isDisabled} className='form-control' aria-invalid='false' />
              </div>
              <span className='input-group-addon'>
                <button onClick={(e) => this.addElement(key)} className='btn btn-info btn-xs waves-effect'><i style={{color: 'white'}} className='material-icons'>library_add</i></button>
              </span>
              <span className='input-group-addon'>
                <button onClick={(e) => this.deleteElement(key)} className='btn btn-danger btn-xs waves-effect'><i style={{color: 'white'}} className='material-icons'>delete_forever</i></button>
              </span>
            </div>
          )
        })
        }
      </div>
    </div>
    )
  }
}
