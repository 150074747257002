import React from 'react'
import api from '../../../../utils/api'
import {notifySuccess} from '../../../../utils/notifications'
import handleError from '../../../../utils/handleError'
import swal from 'sweetalert'
import {translate as tl} from '../../../../utils/translation'

const SoftDelete = (props) => {
  const handleClick = async () => {
    const {model, row, updateTable, page} = props
    row['softDeleted'] = true

    const willSoftDelete = await swal({
      title: 'Are you sure?',
      text: `This action can't be undone`,
      icon: 'warning',
      buttons: true,
      dangerMode: true
    })

    if (!willSoftDelete) {
      return
    }

    try {
      const response = await api(`/${model}/${row._id}`, 'PUT', null, row)
      notifySuccess(response.data.message)
      updateTable({page})
    } catch (error) {
      handleError(error, props)
    }
  }

  return (
    <a>
      <i title={tl('backend_action_softDelete')} style={{ 'cursor': 'pointer' }} onClick={(e) => { handleClick() }} className='material-icons medium'>delete</i>
    </a>
  )
}
export default SoftDelete
