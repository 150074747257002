import React, { useEffect, useMemo, useState } from 'react'
import { Route, Redirect, Link, Switch } from 'react-router-dom'
import cookie from 'react-cookies'
import handleError from '../utils/handleError'
import { translate as tl } from '../utils/translation'
import api from '../utils/api'
import modules from '../config/modulesFactory'
import setConfig, { getCompanies } from '../config/app'

import Header from '../statics/Header'
import Userinfo from '../statics/sidebar/Userinfo'
import Menu from '../statics/sidebar/Menu'
import Footer from '../statics/sidebar/Footer'

import Dashboard from './Dashboard'

import Scripts from '../statics/Scripts'
import Table from '../statics/helpers/table/Table'
import StepsLayout from '../layouts/steps/StepsLayout'
import FilesContainer from '../statics/files/FilesContainer'
import MailStatistics from '../statics/marketing/MailStatistics'
import CRMStatistics from '../statics/marketing/CRMStatistics'
import CrmEasymillionsStatistic from '../statics/marketing/CrmEasymillionsStatistic'
import EmailCampaignsStatistics from '../statics/marketing/EmailCampaignsStatisic'
import ShopMediamarktProductappointmentStatistic from '../statics/marketing/ShopMediamarktProductappointmentStatistic'
import CoreCompanies from '../statics/administration/CoreCompanies'
import GcsolutionEasymillionsStatistic from '../statics/marketing/GcsolutionEasymillionsStatistic'
import SearchCustomer from '../statics/customer-care/SearchCustomer'

const Wrapper = (props) => {

	const { setupPage } = props

	const [user, setUser] = useState(false)
	const [availableCompanies, setAvailableCompanies] = useState(false)
	const [selectedCompany, setSelectedCompany] = useState(false)
	const [selectedConfig, setSelectedConfig] = useState(false)
	const [availableModules, setAvailableModules] = useState(false)
	const [availableConfigs, setAvailableConfigs] = useState(false)

	//COMMON FUNCS
	const buildCompany = async (providedCompany) => {
		try {

			cookie.save('__co', providedCompany, { path: '/' })

			const [
				retrievedConfig,
				retrievedConfigs,
				retrievedModules
			] = await Promise.all([setConfig(), getCompanies(), modules()])


			setupPage(retrievedConfig)
			setSelectedCompany(retrievedConfig.key)
			setSelectedConfig(retrievedConfig)
			setAvailableConfigs(retrievedConfigs)

			const location = props.history?.location?.pathname
			const history = props.history

			if (providedCompany !== selectedCompany && location !== '/dashboard') {
				history.push('/')
			}

			setAvailableModules(retrievedModules)
		} catch (error) {
			handleError(error, props)
		}
	}

	//MEMORIZED ROUTES
	const allowedModulesRoutes = useMemo(() => {
		const buildModules = () => {

			const modulesRoutes = availableModules.reduce((accum, module, index) => {

				if (!module.pages) return accum

				const pagesRoutes = module.pages.reduce((_accum, page, key) => {

					const config = module.models.find(model => model.model === page.model)

					if (!page.path) return _accum
					const routes = [
						..._accum,
						<Route key={`${index}${key}`} exact path={page.path} render={() => {
							switch (page.type) {
								case 'table':
									return <Table
										title={page.name}
										{...props}
										createTitle={page.createTitle}
										exportTitle={page.exportTitle}
										exportLeadsTitle={page.exportLeadsTitle}
										reportTitle={page.reportTitle}
										exportMediaCodeCustomers={page.exportMediaCodeCustomers}
										importReturnDebitTitle={page.importReturnDebitTitle}
										importCodesTitle={page.importCodesTitle}
										updateLeadsTitle={page.updateLeadsTitle}
										uploadDalsonLeadsTitle={page.uploadDalsonLeadsTitle}
										config={config}
										configs={availableConfigs} />

								case 'files': return <FilesContainer title={page.name} {...props} config={config} />
								case 'mailStatistics': return <MailStatistics {...props} />
								case 'crmStatistics': return <CRMStatistics {...props} />
								case 'CrmEasymillionsStatistic': return <CrmEasymillionsStatistic {...props} />
								case 'EmailCampaignsStatistics': return <EmailCampaignsStatistics {...props} />
								case 'ShopMediamarktProductappointmentStatistic': return <ShopMediamarktProductappointmentStatistic {...props} />
								case 'CoreCompanies': return <CoreCompanies {...props} />
								case 'GcsolutionEasymillionsStatistic': return <GcsolutionEasymillionsStatistic {...props} />
								case 'searchCustomer': return <SearchCustomer {...props} />
								case 'steps': return <StepsLayout module={module} />
								default: return <div>{tl('backend_page_not_found')}</div>
							}
						}} />
					]
					return routes
				}, [])
				return [...accum, ...pagesRoutes]
			}, [])
			return modulesRoutes
		}

		return availableModules && buildModules()

	}, [availableModules, availableConfigs])

	//ON LOAD COMPO
	useEffect(() => {

		const getUserData = async () => {
			try {
				const { data: { data: user } } = await api('/core/my/user', 'GET', null, null, 'thunderchild-de')

				setUser(user)
				setAvailableCompanies(user.companies)

				if (user.companies) {
					const hasCurrentCompany = user.companies.find(company => company === cookie.load('__co'))
					const activeCompany = (hasCurrentCompany || user.companies[0])
					if (activeCompany) await buildCompany(activeCompany)
				}
			} catch (error) {
				handleError(error, props)
			}
		}
		getUserData()
	}, [])

	return <div>
		{availableModules
			? <div>
				<Header
					{...props}
					company={selectedCompany}
					setCompany={buildCompany}
					availableCompanies={availableCompanies}
					config={selectedConfig}
					configs={availableConfigs} />
				<section>
					<aside id='leftsidebar' className='sidebar'>
						<Userinfo {...props} user={user} />
						<Menu {...props} availableModules={availableModules} />
						<Footer config={selectedConfig} />
					</aside>
				</section>
				<section className='content'>
					<Switch>
						<Route exact path='/' render={() => <Redirect to={cookie.load('token') ? '/dashboard' : '/login'} />} />
						<Route exact path='/dashboard' render={() => <Dashboard availableModules={availableModules} config={selectedConfig} />} />
						{allowedModulesRoutes}
						<Route render={() => <div>
							<h2>{tl('backend_page_not_found')}</h2>
							<Link to='/'>{tl('backend_dashboard_label')}</Link>
						</div>
						} />
					</Switch>
				</section>
				<Scripts />
			</div>
			: null
		}
	</div>
}


export default Wrapper
