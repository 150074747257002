export default [
  {
    key: 'backend_dashboard_label',
    en: 'Dashboard',
    de: 'Dashboard'
  },
  {
    key: 'backend_content_management_label',
    en: 'Content Management',
    de: 'Inhaltsverwaltung'
  },
  {
    key: 'backend_customer_care_label',
    en: 'Customer Care',
    de: 'Kundenverwaltung'
  },
  {
    key: 'backend_marketing_label',
    en: 'Marketing',
    de: 'Marketing'
  },
  {
    key: 'backend_dashboard_title_crm',
    en: 'CRM',
    de: 'CRM'
  },
  {
    key: 'backend_pagination_x1_x2_x3',
    en: 'Showing {{1}} to {{2}} of {{3}},',
    de: 'Zeige {{1}} bis {{2}} von {{3}},'
  },
  {
    key: 'backend_dashboard_title_cashback',
    en: 'Cashback',
    de: 'Cashback'
  },
  {
    key: 'backend_dashboard_title_easymillions',
    en: 'Easymillions',
    de: 'EasyMillions'
  },
  {
    key: 'backend_dashboard_title_bonussystem',
    en: 'Bonussystem',
    de: 'Bonussystem'
  },
  {
    key: 'backend_dashboard_title_mail',
    en: 'Email',
    de: 'Email'
  },
  {
    key: 'backend_dashboard_title_tasks',
    en: 'Tasks',
    de: 'Aufgaben'
  },
  {
    key: 'backend_page_name_categories',
    en: 'Categories',
    de: 'Kategorien'
  },
  {
    key: 'backend_page_name_brands',
    en: 'Brands',
    de: 'Marken'
  },
  {
    key: 'backend_page_name_faq',
    en: 'FAQ',
    de: 'FAQ'
  },
  {
    key: 'backend_page_name_pages',
    en: 'Pages',
    de: 'Seiten'
  },
  {
    key: 'backend_page_name_testimonials',
    en: 'Testimonials',
    de: 'Kundenmeinungen'
  },
  {
    key: 'backend_page_name_translations',
    en: 'Translations',
    de: 'Weitere Texte'
  },
  {
    key: 'backend_page_name_mails',
    en: 'Mails',
    de: 'Mails'
  },
  {
    key: 'backend_page_name_raffles',
    en: 'Raffles',
    de: 'Gewinnspiele'
  },
  {
    key: 'backend_search_placeholder',
    en: 'Search',
    de: 'Suche'
  },
  {
    key: 'backend_page_name_bonussystemtransaction',
    en: 'Bonussystemtransactions',
    de: 'Bonussystemtransaktionen'
  },
  {
    key: 'backend_page_name_cashbacktransactions',
    en: 'Cashbacktransactions',
    de: 'Cashbacktransaktionen'
  },
  {
    key: 'backend_page_name_cashbackpayoffs',
    en: 'Cashbackpayoffs',
    de: 'Cashbackauszahlungen'
  },
  {
    key: 'backend_page_name_customers',
    en: 'Customers',
    de: 'Kunden'
  },
  {
    key: 'backend_page_name_tcucc_mailings',
    en: 'tcucc_mailings',
    de: 'tcucc_mailings'
  },
  {
    key: 'backend_page_name_tcucc_finals',
    en: 'tcucc_finals',
    de: 'tcucc_finals'
  },
  {
    key: 'backend_page_name_leads',
    en: 'Leads',
    de: 'Leads'
  },
  {
    key: 'backend_page_name_contact_requests',
    en: 'Contactrequests',
    de: 'Kontaktanfragen'
  },
  {
    key: 'backend_page_name_blacklistemails',
    en: 'Blacklist',
    de: 'Blacklist'
  },
  {
    key: 'backend_page_name_raffle_participants',
    en: 'Raffleparticipants',
    de: 'Gewinnspielteilnehmer'
  },
  {
    key: 'backend_page_name_CRM_statistics',
    en: 'CRM statistics',
    de: 'CRM Statistiken'
  },
  {
    key: 'backend_page_name_mail_statistics',
    en: 'Mail statistics',
    de: 'Mail Statistiken'
  },
  {
    key: 'backend_page_name_email_campaigns',
    en: 'Campaign statistics',
    de: 'Kampangen Statistiken'
  },
  {
    key: 'backend_page_name_tasks',
    en: 'Tasks',
    de: 'Aufgaben'
  },
  {
    key: 'backend_page_name_users',
    en: 'Users',
    de: 'Benutzer'
  },
  {
    key: 'backend_page_name_blocked_emails',
    en: 'Blocked emails',
    de: 'Blockierte E-Mails'
  },
  {
    key: 'backend_page_name_routeerrors',
    en: 'Routeerrors',
    de: 'Routeerrors'
  },
  {
    key: 'backend_page_name_participants',
    en: 'Participants',
    de: 'Teilnehmer'
  },
  {
    key: 'backend_page_name_core_companies',
    en: 'Companies',
    de: 'Firmen'
  },
  {
    key: 'backend_page_name_import_partner',
    en: 'Partner-Leads-Upload',
    de: 'Partner-Leads-Upload'
  },
  {
    key: 'backend_field_company',
    en: 'Company',
    de: 'Firma'
  },
  {
    key: 'backend_field_duration',
    en: 'Duration',
    de: 'Dauer'
  },
  {
    key: 'backend_field_method',
    en: 'Method',
    de: 'Methode'
  },
  {
    key: 'backend_field_error',
    en: 'Error',
    de: 'Fehler'
  },
  {
    key: 'backend_field_ip',
    en: 'IP',
    de: 'IP'
  },
  {
    key: 'backend_field_password',
    en: 'New password',
    de: 'Neues Passwort'
  },
  {
    key: 'backend_administration_label',
    en: 'Administration',
    de: 'Administration'
  },
  {
    key: 'backend_logout',
    en: 'Logout',
    de: 'Ausloggen'
  },
  {
    key: 'backend_form_username',
    en: 'Username',
    de: 'Benutzername'
  },
  {
    key: 'backend_form_password',
    en: 'Password',
    de: 'Passwort'
  },
  {
    key: 'backend_button_login',
    en: 'Login',
    de: 'Login'
  },
  {
    key: 'backend_environment',
    en: 'Environment',
    de: 'Umgebung'
  },
  {
    key: 'backend_field_name',
    en: 'Name',
    de: 'Name'
  },
  {
    key: 'backend_field_status',
    en: 'Status',
    de: 'Status'
  },
  {
    key: 'backend_field_created_at',
    en: 'Created At',
    de: 'Erstellt am'
  },
  {
    key: 'backend_field_purchased_at',
    en: 'Purchased At',
    de: 'Gekauft am'
  },
  {
    key: 'backend_field_actions',
    en: 'Actions',
    de: 'Aktionen'
  },
  {
    key: 'backend_previous',
    en: 'Previous',
    de: 'Vorherig'
  },
  {
    key: 'backend_next',
    en: 'Next',
    de: 'Nächste'
  },
  {
    key: 'backend_field_username',
    en: 'Username',
    de: 'Benutzername'
  },
  {
    key: 'backend_field_firstname',
    en: 'First name',
    de: 'Vorname'
  },
  {
    key: 'backend_field_lastname',
    en: 'Last name',
    de: 'Nachname'
  },
  {
    key: 'backend_dashboard_item_amount_of_bonustransactions',
    en: 'Amount of Bonustransactions',
    de: 'Anzahl Bonustransaktionen'
  },
  {
    key: 'backend_dashboard_item_total_bonuspoints',
    en: 'Total Bonuspoints',
    de: 'Gesamte Bonuspunkte'
  },
  {
    key: 'backend_field_type',
    en: 'Type',
    de: 'Art'
  },
  {
    key: 'backend_field_amount',
    en: 'Amount',
    de: 'Menge'
  },
  {
    key: 'backend_dashboard_item_amount_of_transactions',
    en: 'Amount of Transactions',
    de: 'Menge der Transaktionen'
  },
  {
    key: 'backend_dashboard_item_amount_of_payoffs',
    en: 'Amount of Payoffs',
    de: 'Menge der Auszahlungen'
  },
  {
    key: 'backend_dashboard_item_total_shop_sales',
    en: 'Total Shop Sales',
    de: 'Gesamte Shop Verkäufe'
  },
  {
    key: 'backend_dashboard_item_total_comission',
    en: 'Total Comission',
    de: 'Gesamte Vergütung'
  },
  {
    key: 'backend_dashboard_item_total_cashback',
    en: 'Total Cashback',
    de: 'Gesamtes Cashback'
  },
  {
    key: 'backend_dashboard_item_total_winning',
    en: 'Total Winning',
    de: 'Gesamter Gewinn'
  },
  {
    key: 'backend_dashboard_item_active_brands',
    en: 'Active Brands',
    de: 'Aktive Marken'
  },
  {
    key: 'backend_dashboard_title_core',
    en: 'Core',
    de: 'Core'
  },
  {
    key: 'backend_dashboard_item_cpu_usage_percent',
    en: 'CPU Usage',
    de: 'CPU Auslastung'
  },
  {
    key: 'backend_dashboard_item_diskspace_free',
    en: 'Free diskspace',
    de: 'Freier Speicher'
  },
  {
    key: 'backend_dashboard_item_diskspace_total',
    en: 'Total diskspace',
    de: 'Gesamter Speicher'
  },
  {
    key: 'backend_dashboard_item_memory_free',
    en: 'Free memory',
    de: 'Freier Arbeitsspeicher'
  },
  {
    key: 'backend_dashboard_item_memory_total',
    en: 'Total memory',
    de: 'Gesamter Arbeitsspeicher'
  },
  {
    key: 'backend_field_parent',
    en: 'Parent',
    de: 'Eltern'
  },
  {
    key: 'backend_field_title',
    en: 'Title',
    de: 'Titel'
  },
  {
    key: 'backend_field_alias',
    en: 'Alias',
    de: 'Alias'
  },
  {
    key: 'backend_field_description',
    en: 'Description',
    de: 'Beschreibung'
  },
  {
    key: 'backend_field_highlight',
    en: 'Highlight',
    de: 'Hervorhebung'
  },
  {
    key: 'backend_field_active',
    en: 'Active',
    de: 'Aktiv'
  },
  {
    key: 'backend_field_icon_image',
    en: 'Icon image',
    de: 'Icon Bild'
  },
  {
    key: 'backend_field_logo_image',
    en: 'Logo image',
    de: 'Logo Bild'
  },
  {
    key: 'backend_field_header_image',
    en: 'Header image',
    de: 'Header Bild'
  },
  {
    key: 'backend_back_button',
    en: 'Back',
    de: 'Zurück'
  },
  {
    key: 'backend_save_button',
    en: 'Save',
    de: 'Speichern'
  },
  {
    key: 'backend_modal_form_edit',
    en: 'Edit',
    de: 'Bearbeiten'
  },
  {
    key: 'backend_field_categories',
    en: 'Categories',
    de: 'Kategorien'
  },
  {
    key: 'backend_field_url',
    en: 'Url',
    de: 'Url'
  },
  {
    key: 'backend_yes',
    en: 'Yes',
    de: 'Ja'
  },
  {
    key: 'backend_no',
    en: 'No',
    de: 'Nein'
  },
  {
    key: 'backend_field_copyright',
    en: 'Copyright',
    de: 'Copyright'
  },
  {
    key: 'backend_field_ignore',
    en: 'Ignore',
    de: 'Ignorieren'
  },
  {
    key: 'backend_field_transactionId',
    en: 'ID',
    de: 'ID'
  },
  {
    key: 'backend_field_brand',
    en: 'Brand',
    de: 'Marke'
  },
  {
    key: 'backend_field_orderId',
    en: 'Order ID',
    de: 'Bestellnummer'
  },
  {
    key: 'backend_field_comission',
    en: 'Comission',
    de: 'Vergütung'
  },
  {
    key: 'backend_field_cashback',
    en: 'Cashback',
    de: 'Cashback'
  },
  {
    key: 'backend_field_payoffId',
    en: 'ID',
    de: 'ID'
  },
  {
    key: 'backend_field_payment_information',
    en: 'Payment Information',
    de: 'Zahlungsinformationen'
  },
  {
    key: 'backend_field_balance',
    en: 'Balance',
    de: 'Kontostand'
  },
  {
    key: 'backend_bank',
    en: 'Bank',
    de: 'Bank'
  },
  {
    key: 'backend_owner',
    en: 'Owner',
    de: 'Besitzer'
  },
  {
    key: 'backend_email',
    en: 'Email',
    de: 'E-Mail'
  },
  {
    key: 'backend_dashboard_item_amount_of_leads',
    en: 'Amount of Leads',
    de: 'Anzahl Leads'
  },
  {
    key: 'backend_dashboard_item_amount_of_leads_with_email',
    en: 'Amount of Leads with Email',
    de: 'Anzahl Leads mit E-Mail'
  },
  {
    key: 'backend_dashboard_item_amount_of_customers',
    en: 'Amount of Customers',
    de: 'Anzahl Kunden'
  },
  {
    key: 'backend_field_id',
    en: 'ID',
    de: 'ID'
  },
  {
    key: 'backend_field_email',
    en: 'Email',
    de: 'E-Mail'
  },
  {
    key: 'view_customer_title_basic_information',
    en: 'Basic Information',
    de: 'Basisinformationen'
  },
  {
    key: 'view_customer_title_cashback',
    en: 'Cashback',
    de: 'Cashback'
  },
  {
    key: 'view_customer_title_bonussystem',
    en: 'Bonussystem',
    de: 'Bonussystem'
  },
  {
    key: 'view_customer_title_contactmessages',
    en: 'Contact Messages',
    de: 'Kontaktnachrichten'
  },
  {
    key: 'backend_field_salutation',
    en: 'Salutation',
    de: 'Anrede'
  },
  {
    key: 'backend_field_address',
    en: 'Address',
    de: 'Adresse'
  },
  {
    key: 'backend_field_city',
    en: 'City',
    de: 'Ort'
  },
  {
    key: 'backend_field_postcode',
    en: 'Post code',
    de: 'PLZ'
  },
  {
    key: 'backend_field_country',
    en: 'Country',
    de: 'Land'
  },
  {
    key: 'backend_field_birthdate',
    en: 'Birthdate',
    de: 'Geburtsdatum'
  },
  {
    key: 'backend_field_phone',
    en: 'Phone',
    de: 'Telefonnummer'
  },
  {
    key: 'backend_field_mobile',
    en: 'Mobile',
    de: 'Handynummer'
  },
  {
    key: 'backend_field_bank_owner',
    en: 'Owner',
    de: 'Kontoinhaber'
  },
  {
    key: 'backend_field_iban',
    en: 'IBAN',
    de: 'IBAN'
  },
  {
    key: 'backend_field_bic',
    en: 'BIC',
    de: 'BIC'
  },
  {
    key: 'backend_field_paypal_owner',
    en: 'Owner',
    de: 'Inhaber'
  },
  {
    key: 'backend_field_newsletter_news',
    en: 'Newsletter News',
    de: 'Newsletter News'
  },
  {
    key: 'backend_field_newsletter_brand',
    en: 'Newsletter Brand',
    de: 'Newsletter Brand'
  },
  {
    key: 'backend_field_newsletter_special',
    en: 'Newsletter Special',
    de: 'Newsletter Special'
  },
  {
    key: 'backend_field_favorite_categories',
    en: 'Favorite Categories',
    de: 'Lieblingskategorien'
  },
  {
    key: 'backend_field_favorite_brands',
    en: 'Favorite Brands',
    de: 'Lieblingsmarken'
  },
  {
    key: 'backend_view_customer_title_cashbacktransactions',
    en: 'Cashbacktransactions',
    de: 'Transaktionen'
  },
  {
    key: 'backend_field_order_id',
    en: 'Order ID',
    de: 'Bestellnummer'
  },
  {
    key: 'backend_view_customer_title_cashbackpayoffs',
    en: 'Cashbackpayoffs',
    de: 'Auszahlungen'
  },
  {
    key: 'backend_view_customer_title_bonussystemtransactions',
    en: 'Bonussystemtransactions',
    de: 'Bonussystemtransaktionen'
  },
  {
    key: 'backend_view_customer_title_contactmessages',
    en: 'Contactmessages',
    de: 'Kontaktnachrichten'
  },
  {
    key: 'backend_field_message',
    en: 'Message',
    de: 'Nachricht'
  },
  {
    key: 'backend_customer_view_create_new_message',
    en: 'Create new message',
    de: 'Erstelle neue Nachricht'
  },
  {
    key: 'backend_export_pflegeexperteleads',
    en: 'Export Pflege-Experte-Leads',
    de: 'Exportiere Pflege-Experte-Leads'
  },
  {
    key: 'backend_export_participants',
    en: 'Export Participants',
    de: 'Exportiere Teilnehmer'
  },
  {
    key: 'backend_export_contacts',
    en: 'Export Contacts',
    de: 'Exportiere Kontakte'
  },
  {
    key: 'backend_message',
    en: 'Message',
    de: 'Nachricht'
  },
  {
    key: 'backend_statistics_updated',
    en: 'Last updated',
    de: 'Letzte Aktualisierung'
  },
  {
    key: 'backend_outgoing',
    en: 'Outgoing',
    de: 'Ausgehend'
  },
  {
    key: 'backend_incoming',
    en: 'Incoming',
    de: 'Eingehend'
  },
  {
    key: 'backend_clear_button',
    en: 'Clear',
    de: 'Clear'
  },
  {
    key: 'backend_page_name_crm_easymillions',
    en: 'EasyPlus.fr statistic',
    de: 'EasyPlus.fr Statistik'
  },
  {
    key: 'backend_page_name_files',
    en: 'Files',
    de: 'Dateien'
  },
  {
    key: 'backend_field_preview',
    en: 'Preview',
    de: 'Vorschau'
  },
  {
    key: 'backend_field_file',
    en: 'File',
    de: 'Dateien'
  },
  {
    key: 'backend_field_category',
    en: 'Category',
    de: 'Kategorien'
  },
  {
    key: 'backend_no_data_available',
    en: 'No data available',
    de: 'Keine Daten vorhanden'
  },
  {
    key: 'backend_page_name_blog_categories',
    en: 'Blog Categories',
    de: 'Blogkategorien'
  },
  {
    key: 'backend_page_name_blog_posts',
    en: 'Blog Posts',
    de: 'Blogeinträge'
  },
  {
    key: 'backend_field_blog_categories',
    en: 'Blog Categories',
    de: 'Blogkategorien'
  },
  {
    key: 'backend_field_excerpt',
    en: 'Excerpt',
    de: 'Auszug'
  },
  {
    key: 'backend_field_customer',
    en: 'Customer',
    de: 'Kunde'
  },
  {
    key: 'backend_field_evaluation',
    en: 'Evaluation',
    de: 'Evaluation'
  },
  {
    key: 'backend_field_image',
    en: 'Image',
    de: 'Bild'
  },
  {
    key: 'backend_create_title_testimonial',
    en: 'Create Testimonial',
    de: 'Erstelle Kundenmeinung'
  },
  {
    key: 'backend_modal_form_create',
    en: 'Create',
    de: 'Neuen Datensatz erstellen'
  },
  {
    key: 'backend_field_language',
    en: 'Language',
    de: 'Sprache'
  },
  {
    key: 'backend_field_key',
    en: 'Key',
    de: 'Schlüssel'
  },
  {
    key: 'backend_field_value',
    en: 'Value',
    de: 'Wert'
  },
  {
    key: 'backend_create_title_translation',
    en: 'Create Translation',
    de: 'Erstelle Übersetzung'
  },
  {
    key: 'backend_field_background_image',
    en: 'Background image',
    de: 'Hintergrundbild'
  },
  {
    key: 'backend_field_main_image',
    en: 'Main image',
    de: 'Hauptbild'
  },
  {
    key: 'backend_field_product_titles',
    en: 'Product titles',
    de: 'Produkttitel'
  },
  {
    key: 'backend_field_product_images',
    en: 'Product images',
    de: 'Produktbilder'
  },
  {
    key: 'backend_field_headline',
    en: 'Headline',
    de: 'Überschrift'
  },
  {
    key: 'backend_field_sub_headline',
    en: 'Subheadline',
    de: 'Unterüberschrift'
  },
  {
    key: 'backend_field_form_headline',
    en: 'Form headline',
    de: 'Überschrift des Formulars'
  },
  {
    key: 'backend_field_terms_and_conditions',
    en: 'Terms and conditions',
    de: 'Allgemeine Geschäftsbedingungen'
  },
  {
    key: 'backend_field_include_header',
    en: 'Include header',
    de: 'Header anzeigen'
  },
  {
    key: 'backend_field_include_footer',
    en: 'Include footer',
    de: 'Footer anzeigen'
  },
  {
    key: 'backend_action_preview',
    en: 'Preview',
    de: 'Vorschau'
  },
  {
    key: 'backend_action_edit',
    en: 'Edit',
    de: 'Bearbeiten'
  },
  {
    key: 'backend_action_deactivate',
    en: 'Deactivate',
    de: 'Deaktivieren'
  },
  {
    key: 'backend_action_activate',
    en: 'Activate',
    de: 'Aktivieren'
  },
  {
    key: 'backend_action_delete',
    en: 'Delete',
    de: 'Löschen'
  },
  {
    key: 'backend_action_softDelete',
    en: 'Delete',
    de: 'Löschen'
  },
  {
    key: 'backend_statistics_title_leads/customers',
    en: 'Leads/Customers',
    de: 'Leads/Kunden'
  },
  {
    key: 'backend_statistics_title_email/campaigns',
    en: 'Campaigns',
    de: 'Kampangen'
  },
  {
    key: 'backend_statistics_title_customers',
    en: 'Customers',
    de: 'Kunden'
  },
  {
    key: 'backend_statistics_label_leads_with_email',
    en: 'Leads with email',
    de: 'Leads mit E-Mail'
  },
  {
    key: 'backend_statistics_label_customer',
    en: 'Customer',
    de: 'Kunde'
  },
  {
    key: 'backend_statistics_label_contactrequest',
    en: 'Contactrequests',
    de: 'Kontaktanfragen'
  },
  {
    key: 'backend_field_date',
    en: 'Date',
    de: 'Datum'
  },
  {
    key: 'backend_field_leads',
    en: 'Leads',
    de: 'Leads'
  },
  {
    key: 'backend_field_customers',
    en: 'Customers',
    de: 'Kunden'
  },
  {
    key: 'backend_field_customersLeadCreatedAt',
    en: 'Customers (Lead creation date)',
    de: 'Kunden (Lead Erstellungsdatum)'
  },
  {
    key: 'backend_field_contactrequests',
    en: 'Contactrequests',
    de: 'Kontaktnachrichten'
  },
  {
    key: 'backend_statistics_label_outgoingmails',
    en: 'Outgoingmails',
    de: 'Ausgehende E-Mails'
  },
  {
    key: 'backend_statistics_label_outgoingmails_open',
    en: 'Outgoingmails (open)',
    de: 'Ausgehende E-Mails (offen)'
  },
  {
    key: 'backend_statistics_label_outgoingmails_error',
    en: 'Outgoingmails (error)',
    de: 'Ausgehende E-Mails (Fehler)'
  },
  {
    key: 'backend_statistics_label_outgoingmails_processing',
    en: 'Outgoingmails (processing)',
    de: 'Ausgehende E-Mails (In Bearbeitung)'
  },
  {
    key: 'backend_statistics_label_outgoingmails_blacklisted',
    en: 'Outgoingmails Blacklisted',
    de: 'Ausgehende E-Mails (Blacklist)'
  },
  {
    key: 'backend_field_welcome_mails',
    en: 'Welcome mails',
    de: 'Willkommensnachrichten'
  },
  {
    key: 'backend_page_name_products',
    en: 'Products',
    de: 'Produkte'
  },
  {
    key: 'backend_page_name_productappointments',
    en: 'Product Appointments',
    de: 'Produkttermine'
  },
  {
    key: 'backend_create_title_productappointments',
    en: 'Create Productappointment',
    de: 'Erstelle Productappointment'
  },
  {
    key: 'backend_page_name_orders',
    en: 'Orders',
    de: 'Bestellungen'
  },
  {
    key: 'backend_dashboard_item_amount_of_productcategories',
    en: 'Amount of Product Categories',
    de: 'Anzahl Produktkategorien'
  },
  {
    key: 'backend_dashboard_item_amount_of_products',
    en: 'Amount of Products',
    de: 'Anzahl Produkte'
  },
  {
    key: 'backend_dashboard_item_amount_of_orders',
    en: 'Amount of Orders',
    de: 'Anzahl Bestellungen'
  },
  {
    key: 'backend_dashboard_item_total_price_sum',
    en: 'Total Price Sum',
    de: 'Gesamtsumme der Bestellungen'
  },
  {
    key: 'backend_are_you_sure',
    en: 'Are you sure?',
    de: 'Sind Sie sicher?'
  },
  {
    key: 'backend_delete_are_you_sure_you_want_to_delete_item_x1',
    en: 'Are you sure you want to delete this item in {{1}},?',
    de: 'Sind Sie sicher, dass Sie den Gegenstand in {{1}}, löschen wollen?'
  },
  {
    key: 'backend_field_price',
    en: 'Price',
    de: 'Preis'
  },
  {
    key: 'backend_field_time',
    en: 'Time',
    de: 'Uhrzeit'
  },
  {
    key: 'backend_field_maximum',
    en: 'Maximum',
    de: 'Maximum'
  },
  {
    key: 'backend_field_productcategory',
    en: 'Product Category',
    de: 'Produktkategorie'
  },
  {
    key: 'backend_field_product',
    en: 'Product',
    de: 'Produkt'
  },
  {
    key: 'backend_field_information',
    en: 'Information',
    de: 'Information'
  },
  {
    key: 'backend_field_cancel_reason',
    en: 'Cancel reason',
    de: 'Abbruchgrund'
  },
  {
    key: 'backend_field_first_name',
    en: 'First name',
    de: 'Vorname'
  },
  {
    key: 'backend_field_last_name',
    en: 'Last name',
    de: 'Nachname'
  },
  {
    key: 'backend_field_street',
    en: 'Street',
    de: 'Straße'
  },
  {
    key: 'backend_field_date_of_birth',
    en: 'Date of birth',
    de: 'Geburtsdatum'
  },
  {
    key: 'backend_field_clubnumber',
    en: 'Club number',
    de: 'Clubnummer'
  },
  {
    key: 'backend_field_customer_number',
    en: 'Customernumber',
    de: 'Kundennummer'
  },
  {
    key: 'backend_dashboard_item_amount_of_sent_emails',
    en: 'Amount of sent emails',
    de: 'Anzahl gesendeter E-Mails'
  },
  {
    key: 'backend_dashboard_item_amount_of_opened_emails',
    en: 'Amount of opened emails',
    de: 'Anzahl geöffneter E-Mails'
  },
  {
    key: 'backend_dashboard_item_amount_of_clicked_emails',
    en: 'Amount of clicked emails',
    de: 'Anzahl geklickter E-Mails'
  },
  {
    key: 'backend_dashboard_item_amount_of_open_mails',
    en: 'Amount of Open Mails',
    de: 'Anzahl offener E-Mails'
  },
  {
    key: 'backend_dashboard_item_amount_of_processing_mails',
    en: 'Amount of Processing Mails',
    de: 'Anzahl verarbeitende E-Mails'
  },
  {
    key: 'backend_dashboard_item_amount_of_failed_mails',
    en: 'Amount of Failed Mails',
    de: 'Anzahl fehlgeschlagene E-Mails'
  },
  {
    key: 'backend_dashboard_item_amount_of_blocked_mails',
    en: 'Amount of Blocked Mails',
    de: 'Anzahl blockierter E-Mails'
  },
  {
    key: 'backend_dashboard_item_amount_of_blacklisted_mails',
    en: 'Amount of Blacklisted Mails',
    de: 'Anzahl Blacklisted E-Mails'
  },
  {
    key: 'backend_dashboard_item_amount_of_closed_tasks',
    en: 'Amount of Closed Tasks',
    de: 'Anzahl abgeschlossener Aufgaben'
  },
  {
    key: 'backend_dashboard_item_amount_of_tasks_in_progress',
    en: 'Amount of Tasks in Progress',
    de: 'Anzahl offener Aufgaben'
  },
  {
    key: 'backend_dashboard_item_amount_of_open_tasks',
    en: 'Amount of Open Tasks',
    de: 'Anzahl offener Aufgaben'
  },
  {
    key: 'backend_create_title_tasks',
    en: 'Create Task',
    de: 'Erstelle Aufgabe'
  },
  {
    key: 'backend_field_department',
    en: 'Department',
    de: 'Abteilung'
  },
  {
    key: 'backend_field_updated_at',
    en: 'Updated At',
    de: 'Aktualisiert am'
  },
  {
    key: 'backend_action_view_customer',
    en: 'View Customer',
    de: 'Zeige Kunden'
  },
  {
    key: 'backend_field_bank',
    en: 'Bank',
    de: 'Bank'
  },
  {
    key: 'backend_field_owner',
    en: 'Owner',
    de: 'Besitzer'
  },
  {
    key: 'backend_create_title_faq',
    en: 'Create faq',
    de: 'Erstelle FAQ'
  },
  {
    key: 'backend_create_title_category',
    en: 'Create Category',
    de: 'Erstelle Kategorie'
  },
  {
    key: 'backend_create_title_raffle',
    en: 'Create Raffle',
    de: 'Erstelle Gewinnspiel'
  },
  {
    key: 'backend_create_title_blacklistemail',
    en: 'Create Blacklistemail',
    de: 'Erstelle Blacklistemail'
  },
  {
    key: 'backend_authentication_error',
    en: 'Authentication error. Please login',
    de: 'Authentifizierungsfehler. Bitte einloggen'
  },
  {
    key: 'backend_error_try_again',
    en: 'Sorry there was an error. Please try again',
    de: 'Entschuldigung, ein Fehler ist aufgetreten. Bitte versuche es erneut'
  },
  {
    key: 'backend_data_updated',
    en: 'Data was updated',
    de: 'Daten aktualisiert'
  },
  {
    key: 'backend_login_correct',
    en: 'Logged in correctly',
    de: 'Erfolgreich angemeldet'
  },
  {
    key: 'backend_wrong_username_password',
    en: 'Wrong username or password',
    de: 'Falscher Nutzername oder Passwort'
  },
  {
    key: 'backend_logout_correct',
    en: 'Logged out correctly',
    de: 'Erfolgreich abgemeldet'
  },
  {
    key: 'backend_logout_error',
    en: 'Error during logout. Please try again',
    de: 'Fehler beim Abmelden. Bitte versuche es erneut'
  },
  {
    key: 'backend_field_raffle',
    en: 'Raffle',
    de: 'Gewinnspiel'
  },
  {
    key: 'backend_page_not_found',
    en: 'Page not found',
    de: 'Seite nicht gefunden'
  },
  {
    key: 'backend_dropzone_instructions',
    en: 'Drop files here or click to upload in this folder',
    de: 'Lege Dateien hier ab oder klicke, um Dateien in diesem Verzeichnis hochzuladen'
  },
  {
    key: 'backend_files',
    en: 'Files',
    de: 'Dateien'
  },
  {
    key: 'backend_are_you_sure_you_want_to_delete',
    en: 'Are you sure you want to delete',
    de: 'Bist du sicher, dass du das löschen willst?'
  },
  {
    key: 'backend_from',
    en: 'from',
    de: 'von'
  },
  {
    key: 'backend_action_copy_clipboard',
    en: 'Copy route to clipboard',
    de: 'Kopieren'
  },
  {
    key: 'backend_action_resend_confirmation',
    en: 'Resend confirmation',
    de: 'Bestätigung erneut senden'
  },
  {
    key: 'backend_action_preview_log',
    en: 'Preview log',
    de: 'Logvorschau'
  },
  {
    key: 'backend_action_payoff_confirm',
    en: 'Confirm payoff',
    de: 'Auszahlung bestätigen'
  },
  {
    key: 'backend_action_payoff_cancel',
    en: 'Cancel payoff',
    de: 'Auszahlungen abbrechen'
  },
  {
    key: 'backend_action_order_confirm',
    en: 'Confirm order',
    de: 'Bestellung bestätigen'
  },
  {
    key: 'backend_field_phone_fixed',
    en: 'Phone fixed',
    de: 'Telefonnummer'
  },
  {
    key: 'backend_field_phone_mobile',
    en: 'Phone mobile',
    de: 'Mobile Nummer'
  },
  {
    key: 'backend_dashboard_title_shop_mediamarkt',
    en: 'Shop Mediamarkt',
    de: 'Shop Mediamarkt'
  },
  {
    key: 'backend_view_order',
    en: 'View Order',
    de: 'Zeige Bestellung'
  },
  {
    key: 'backend_view_order_title_basic_information',
    en: 'Basic Information',
    de: 'Basisinformationen'
  },
  {
    key: 'backend_view_order_title_orders',
    en: 'Orders',
    de: 'Bestellungen'
  },
  {
    key: 'backend_field_count',
    en: 'Count',
    de: 'Anzahl'
  },
  {
    key: 'backend_field_discount',
    en: 'Discount',
    de: 'Rabatt'
  },
  {
    key: 'backend_language_menu_title',
    en: 'Choose Language',
    de: 'Sprache wählen'
  },
  {
    key: 'backend_language_menu_english',
    en: 'English',
    de: 'Englisch'
  },
  {
    key: 'backend_language_menu_german',
    en: 'German',
    de: 'Deutsch'
  },
  {
    key: 'backend_language_menu_french',
    en: 'French',
    de: 'Französisch'
  },
  {
    key: 'backend_language_menu_spanish',
    en: 'Spanish',
    de: 'Spanisch'
  },
  {
    key: 'backend_page_name_sms_statistics',
    en: 'SMS statistics',
    de: 'SMS Statistiken'
  },
  {
    key: 'backend_statistics_title_outgoingsmss',
    en: 'Smsstatistics',
    de: 'Sms Statistiken'
  },
  {
    key: 'backend_statistics_label_outgoingsms',
    en: 'Outgoingsmss',
    de: 'Ausgehende Sms'
  },
  {
    key: 'backend_statistics_label_outgoingsms_open',
    en: 'Outgoingsmss (open)',
    de: 'Ausgehende Sms (offen)'
  },
  {
    key: 'backend_statistics_label_outgoingsms_error',
    en: 'Outgoingsmss (error)',
    de: 'Ausgehende Sms (Fehler)'
  },
  {
    key: 'backend_statistics_label_outgoingsms_processing',
    en: 'Outgoingsmss (processing)',
    de: 'Ausgehende Sms (In Bearbeitung)'
  },
  {
    key: 'backend_statistics_title_outgoingmails',
    en: 'Mailstatistics',
    de: 'Mail Statistiken'
  },
  {
    key: 'backend_statistics_title_leads',
    en: 'Lead statistics',
    de: 'Lead statistics'
  },
  {
    key: 'customers_easymillions',
    en: 'EasyMillions customers',
    de: 'EasyMillions Kunden'
  },
  {
    key: 'backend_page_name_blacklistsms',
    de: 'Blacklistsms',
    en: 'Blacklistsms'
  },
  {
    key: 'backend_field_number',
    en: 'Number',
    de: 'Nummer'
  },
  {
    key: 'backend_companies_menu_title',
    en: 'Choose Company',
    de: 'Firma auswählen'
  },
  {
    key: 'backend_field_blacklisted_emails',
    en: 'Blacklistemails',
    de: 'Blacklistemails'
  },
  {
    key: 'backend_page_name_registrationcode',
    en: 'Registrationcodes',
    de: 'Registrierungscodes'
  },
  {
    key: 'backend_field_code',
    en: 'Code',
    de: 'Code'
  },
  {
    key: 'backend_field_used',
    en: 'Used',
    de: 'Benutzt'
  },
  {
    key: 'backend_field_place_of_use',
    en: 'Place of use',
    de: 'Nutzungsort'
  },
  {
    key: 'backend_field_user',
    en: 'User',
    de: 'Nutzer'
  },
  {
    key: 'backend_field_to_call',
    en: 'Who to call',
    de: 'Wen anrufen'
  },
  {
    key: 'backend_field_time_of_need',
    en: 'Time of need',
    de: 'Zeitpunkt'
  },
  {
    key: 'backend_field_care_level',
    en: 'Care level',
    de: 'Pflegestufe'
  },
  {
    key: 'backend_field_type_of_help',
    en: 'Type of help',
    de: 'Art der Hilfe'
  },
  {
    key: 'backend_field_frequency_of_care',
    en: 'Frequency of care',
    de: 'Häufigkeit der Pflege'
  },
  {
    key: 'backend_field_type_of_care',
    en: 'Type of care',
    de: 'Art der Pflege'
  },
  {
    key: 'backend_field_source',
    en: 'Source',
    de: 'Quelle'
  },
  {
    key: 'backend_page_name_landingpages',
    en: 'Landingpages',
    de: 'Landingpages'
  },
  {
    key: 'backend_page_name_pflegeexperteleads',
    en: 'Pflege-Experte-Leads',
    de: 'Pflege-Experte-Leads'
  },
  {
    key: 'backend_create_title_landingpages',
    en: 'Create Landingpage',
    de: 'Create Landingpage'
  },
  {
    key: 'backend_field_headerImage',
    en: 'Header Image',
    de: 'Header Image'
  },
  {
    key: 'backend_field_css',
    en: 'CSS',
    de: 'CSS'
  },
  {
    key: 'backend_statistics_title_productappointments',
    en: 'Productappointment statistic',
    de: 'Produkttermine Statistik'
  },
  {
    key: 'backend_page_name_shop_mediamarkt_statistic_productappointments',
    de: 'Produkttermine Statistik',
    en: 'Productappointment statistic'
  },
  {
    key: 'backend_field_html',
    en: 'HTML',
    de: 'HTML'
  },
  {
    key: 'backend_field_thumbnail',
    en: 'Thumbnail',
    de: 'Thumbnail'
  },
  {
    key: 'backend_field_previewImage',
    en: 'Preview Image',
    de: 'Vorschaubild'
  },
  {
    key: 'backend_sendInformation_are_you_sure_you_want_to_send_email',
    de: 'Sind Sie sich sicher, dass Sie eine E-Mail an alle Teilnehmer senden möchten?',
    en: 'Are you sure, that you want to send an email to all participants of this appointment?'
  },
  {
    key: 'view_customer_title_easymillions',
    de: 'Easymillions',
    en: 'Easymillions'
  },
  {
    key: 'backend_field_personalTips',
    en: 'personalTips',
    de: 'personalTips'
  },
  {
    key: 'backend_field_somioTips',
    en: 'somioTips',
    de: 'somioTips'
  },
  {
    key: 'backend_field_customerID',
    en: 'CustomerID',
    de: 'CustomerID'
  },
  {
    key: 'backend_field_customerId',
    en: 'CustomerID',
    de: 'CustomerID'
  },
  {
    key: 'backend_field_externalCustomerId',
    en: 'externalCustomerId',
    de: 'externalCustomerId'
  },
  {
    key: 'backend_field_salutation',
    en: 'salutation',
    de: 'salutation'
  },
  {
    key: 'backend_field_title',
    en: 'title',
    de: 'title'
  },
  {
    key: 'backend_field_firstName',
    en: 'firstName',
    de: 'firstName'
  },
  {
    key: 'backend_field_lastName',
    en: 'lastName',
    de: 'lastName'
  },
  {
    key: 'backend_field_birthName',
    en: 'birthName',
    de: 'birthName'
  },
  {
    key: 'backend_field_birthday',
    en: 'birthday',
    de: 'birthday'
  },
  {
    key: 'backend_field_addressLine1',
    en: 'addressLine1',
    de: 'addressLine1'
  },
  {
    key: 'backend_field_addressLine2',
    en: 'addressLine2',
    de: 'addressLine2'
  },
  {
    key: 'backend_field_zipCode',
    en: 'zipCode',
    de: 'zipCode'
  },
  {
    key: 'backend_field_city',
    en: 'city',
    de: 'city'
  },
  {
    key: 'backend_field_country',
    en: 'country',
    de: 'country'
  },
  {
    key: 'backend_field_UpdateCustomer',
    en: 'UpdateCustomer',
    de: 'UpdateCustomer'
  },
  {
    key: 'backend_field_status',
    en: 'status',
    de: 'status'
  },
  {
    key: 'backend_field_contract_contractID',
    en: 'contractID',
    de: 'contractID'
  },
  {
    key: 'backend_field_contract_contractPartner',
    en: 'contractPartner',
    de: 'contractPartner'
  },
  {
    key: 'backend_field_contract_mediaCode',
    en: 'mediaCode',
    de: 'mediaCode'
  },
  {
    key: 'backend_field_contract_contractType',
    en: 'contractType',
    de: 'contractType'
  },
  {
    key: 'backend_field_contract_paymentMethodType',
    en: 'paymentMethodType',
    de: 'paymentMethodType'
  },
  {
    key: 'backend_field_contract_basePrice',
    en: 'basePrice',
    de: 'basePrice'
  },
  {
    key: 'backend_field_contract_fee',
    en: 'fee',
    de: 'fee'
  },
  {
    key: 'backend_field_contract_entryFee',
    en: 'entryFee',
    de: 'entryFee'
  },
  {
    key: 'backend_field_contract_orderDate',
    en: 'orderDate',
    de: 'orderDate'
  },
  {
    key: 'backend_field_contract_orderCreateDate',
    en: 'orderCreateDate',
    de: 'orderCreateDate'
  },
  {
    key: 'backend_field_contract_startDate',
    en: 'startDate',
    de: 'startDate'
  },
  {
    key: 'backend_field_contract_endDate',
    en: 'endDate',
    de: 'endDate'
  },
  {
    key: 'backend_field_contract_confirmationDate',
    en: 'confirmationDate',
    de: 'confirmationDate'
  },
  {
    key: 'backend_field_contract_agentId',
    en: 'agentId',
    de: 'agentId'
  },
  {
    key: 'backend_field_contract_callCenter',
    en: 'callCenter',
    de: 'callCenter'
  },
  {
    key: 'backend_field_contract_phone',
    en: 'phone',
    de: 'phone'
  },
  {
    key: 'backend_field_contract_mobile',
    en: 'mobile',
    de: 'mobile'
  },
  {
    key: 'backend_field_contract_fax',
    en: 'fax',
    de: 'fax'
  },
  {
    key: 'backend_field_contract_email',
    en: 'email',
    de: 'email'
  },
  {
    key: 'backend_field_contract_marketingCode',
    en: 'marketingCode',
    de: 'marketingCode'
  },
  {
    key: 'backend_field_contract_marketingCampaign',
    en: 'marketingCampaign',
    de: 'marketingCampaign'
  },
  {
    key: 'backend_field_contract_notificationType',
    en: 'notificationType',
    de: 'notificationType'
  },
  {
    key: 'backend_field_contract_periods',
    en: 'periods',
    de: 'periods'
  },
  {
    key: 'backend_field_contract_totalWinAmount',
    en: 'totalWinAmount',
    de: 'totalWinAmount'
  },
  {
    key: 'backend_field_contract_totalDebitAmount',
    en: 'totalDebitAmount',
    de: 'totalDebitAmount'
  },
  {
    key: 'backend_field_IBAN',
    en: 'IBAN',
    de: 'IBAN'
  },
  {
    key: 'backend_field_BIC',
    en: 'BIC',
    de: 'BIC'
  },
  {
    key: 'backend_field_CancellationOnDate',
    en: 'CancellationOnDate',
    de: 'CancellationOnDate'
  },
  {
    key: 'backend_field_CancellationToDate',
    en: 'CancellationToDate',
    de: 'CancellationToDate'
  },
  {
    key: 'backend_field_UpdateContract',
    en: 'UpdateContract',
    de: 'UpdateContract'
  },
  {
    key: 'backend_field_blackList',
    en: 'blackList',
    de: 'blackList'
  },
  {
    key: 'view_customer_title_dalson',
    de: 'Dalson',
    en: 'Dalson'
  },
  {
    key: 'backend_field_idinsert',
    en: 'Idinsert',
    de: 'Idinsert'
  },
  {
    key: 'backend_field_dateofbirth',
    en: 'dateofbirth',
    de: 'dateofbirth'
  },
  {
    key: 'backend_field_tel',
    en: 'Tel',
    de: 'Tel'
  },
  {
    key: 'backend_field_zipcode',
    en: 'Zipcode',
    de: 'PLZ'
  },
  {
    key: 'backend_field_finalcc',
    en: 'finalcc',
    de: 'finalcc'
  },
  {
    key: 'backend_field_mktcmpcc',
    en: 'mktcmpcc',
    de: 'mktcmpcc'
  },
  {
    key: 'backend_field_surname',
    en: 'Surname',
    de: 'Vorname'
  },
  {
    key: 'backend_field_idmailingcc',
    en: 'Idmailingcc',
    de: 'Idmailingcc'
  },
  {
    key: 'backend_field_cc',
    en: 'Cc',
    de: 'Cc'
  },
  {
    key: 'backend_field_agentcc',
    en: 'Agentcc',
    de: 'Agentcc'
  },
  {
    key: 'backend_field_mktcodecc',
    en: 'Mktcodecc',
    de: 'Mktcodecc'
  },
  {
    key: 'backend_field_datafinalcc',
    en: 'Datafinalcc',
    de: 'Datafinalcc'
  },
  {
    key: 'backend_field_dataInsercio',
    en: 'DataInsercio',
    de: 'DataInsercio'
  },
  {
    key: 'backend_field_descfinal',
    en: 'Descfinal',
    de: 'Descfinal'
  },
  {
    key: 'backend_field_mktcode',
    en: 'Marketingcode',
    de: 'Marketingcode'
  },
  {
    key: 'backend_field_dataDeposit',
    en: 'Datadeposit',
    de: 'Datadeposit'
  },
  {
    key: 'backend_field_sumSendings',
    en: 'Sum of sendings',
    de: 'Summe der Sendungen'
  },
  {
    key: 'backend_field_sumContacts',
    en: 'Sum of contacts',
    de: 'Summe der Kontakte'
  },
  {
    key: 'backend_field_MarketingCampaign',
    en: 'MarketingCampaign',
    de: 'MarketingCampaign'
  },
  {
    key: 'backend_field_partner',
    en: 'Partner',
    de: 'Partner'
  },
  {
    key: 'backend_page_name_missing_missingtranslations',
    en: 'Missing Translations',
    de: 'Fehlende Texte'
  },
  {
    key: 'backend_field_opened_mails',
    en: 'Opened mails',
    de: 'Geöffnete E-Mails '
  },
  {
    key: 'backend_field_emails',
    en: 'Mails',
    de: 'E-Mails'
  },
  {
    key: 'backend_field_clicked_mails',
    en: 'Clicked mails',
    de: 'Geklickte E-Mails'
  },
  {
    key: 'backend_field_campaign',
    en: 'Campaign',
    de: 'Kampange'
  },
  {
    key: 'core_permission_denied',
    en: 'Permission denied',
    de: 'Zugriff verweigert'
  },
  {
    key: 'backend_field_statusCode',
    en: 'Status code',
    de: 'Status code'
  },
  {
    key: 'backend_page_name_emailsnippets',
    en: 'Emailsnippets',
    de: 'Emailsnippets'
  },
  {
    key: 'backend_page_name_easymillionscustomer',
    en: 'Easymillionscustomers',
    de: 'Easymillionscustomers'
  },
  {
    key: 'backend_page_name_vwunionmailings',
    en: 'vwunionmailings',
    de: 'vwunionmailings'
  },
  {
    key: 'backend_page_price_pool',
    en: 'Price Pool',
    de: 'Preispool'
  },
  {
    key: 'backend_page_total_winners',
    en: 'Total Winners',
    de: 'Anzahl Gewinner'
  },
  {
    key: 'backend_page_name_easymillionsconcursos',
    en: 'Easymillionsconcursos',
    de: 'Easymillionsconcursos'
  },
  {
    key: 'backend_page_name_gc-solution_easymillions',
    en: 'Easymillionsstatistic',
    de: 'Easymillionsstatistik'
  },
  {
    key: 'backend_field_place_and_age',
    en: 'Place and Number',
    de: 'Platz und Nummer'
  },
  {
    key: 'backend_field_backend_text',
    en: 'Text',
    de: 'Text'
  },
  {
    key: 'backend_create_title_categories',
    en: 'Create Category',
    de: 'Category erstellen'
  },
  {
    key: 'backend_create_title_blog',
    en: 'Create Blog',
    de: 'Blog erstellen'
  },
  {
    key: 'backend_field_start',
    en: 'Start Date',
    de: 'Start'
  },
  {
    key: 'backend_field_end',
    en: 'End Date',
    de: 'Ende'
  },
  {
    key: 'backend_page_name_contacts',
    en: 'Contacts',
    de: 'Kontakte'
  },
  {
    key: 'backend_field_medium',
    en: 'Medium',
    de: 'Medium'
  },
  {
    key: 'backend_field_confirmationSent',
    en: 'Confirmation Sent',
    de: 'Confirmation gesendet'
  },
  {
    key: 'backend_field_question',
    en: 'Question',
    de: 'Frage'
  },
  {
    key: 'backend_field_answer',
    en: 'Answer',
    de: 'Antwort'
  },
  {
    key: 'backend_field_likes',
    en: 'Helpful',
    de: 'Hilfreich'
  },
  {
    key: 'backend_field_dislikes',
    en: 'Not Helpful',
    de: 'Nicht Hilfreich'
  },
  {
    key: 'backend_field_subject',
    en: 'Subject',
    de: 'Thema'
  },
  {
    key: 'backend_page_name_contactmessages',
    en: 'Contactmessage',
    de: 'Kontakt Nachrichten'
  },
  {
    key: 'backend_field_sender',
    en: 'Sender',
    de: 'Absender'
  },
  {
    key: 'backend_field_send',
    en: 'Send',
    de: 'Senden'
  },
  {
    key: 'backend_dashboard_item_amount_of_faqs',
    en: 'FAQs',
    de: 'FAQs'
  },
  {
    key: 'backend_dashboard_item_amount_of_contacts',
    en: 'Contact Requests',
    de: 'Kontakt Anfragen'
  },
  {
    key: 'backend_dashboard_title_ard',
    en: 'ARD',
    de: 'ARD'
  },
  {
    key: 'backend_page_name_easymillionscustomers',
    en: 'Easymillionscustomer',
    de: 'Easymillionscustomer'
  },
  {
    key: 'backend_field_cashback_clicks',
    en: 'Cashback clicks',
    de: 'Cashback clicks'
  },
  {
    key: 'backend_dashboard_item_newsletterNewsCounter',
    en: 'NewsletterNews subscriber',
    de: 'Newsletter Abonenten'
  },
  {
    key: 'backend_field_last_login',
    en: 'Last Login',
    de: 'Letzter Login'
  },
  {
    key: 'backend_field_location',
    en: 'Location',
    de: 'Standort'
  },
  {
    key: 'backend_field_phoneNumber',
    en: 'Phone Number',
    de: 'Telefonnummer'
  },
  {
    key: 'backend_field_travelChoice',
    en: 'Choice of Travel',
    de: 'Anreise Auswahl'
  },
  {
    key: 'backend_field_programmChoice',
    en: 'Program Choice',
    de: 'Programm Auswahl'
  },
  {
    key: 'backend_field_overnightStay',
    en: 'Overnight Stay',
    de: 'Übernachtung'
  },
  {
    key: 'backend_field_foodOptions',
    en: 'Selected Food Options',
    de: 'Ausgewählte Essens Anmerkungen'
  },
  {
    key: 'backend_field_foodOptionsAdditional',
    en: 'Additional Food Options Information',
    de: 'Zusätzliche Essens Anmerkungen'
  },
  {
    key: 'backend_field_link',
    en: 'Link',
    de: 'Link'
  },
  {
    key: 'backend_page_name_shops',
    en: 'Shops',
    de: 'Shops'
  },
  {
    key: 'backend_page_name_jobs',
    en: 'Jobs',
    de: 'Jobs'
  },
  {
    key: 'backend_page_name_deals',
    en: 'Deals',
    de: 'Deals'
  },
  {
    key: 'backend_field_descriptionTitle',
    en: 'Title of Description',
    de: 'Titel der Beschreibung'
  },
  {
    key: 'backend_field_bannerImg',
    en: 'Banner Image Link',
    de: 'Link des Header Bilds'
  },
  {
    key: 'backend_field_imageLinks',
    en: 'Slider Image Links',
    de: 'Link der Slider Bildern'
  },
  {
    key: 'backend_field_shortTitle',
    en: 'Short Title',
    de: 'Verkuerzte Titel'
  },
  {
    key: 'backend_create_shop',
    en: 'Create Shop',
    de: 'Neuen Shop erstellen'
  },
  {
    key: 'backend_create_job',
    en: 'Create Job',
    de: 'Neuen Job erstellen'
  },
  {
    key: 'backend_create_deal',
    en: 'Create Deal',
    de: 'Neuen Deal erstellen'
  },
  {
    key: 'backend_field_shopDescription',
    en: 'Shop Description',
    de: 'Beschreibung des Shops'
  },
  {
    key: 'backend_field_taskTitle',
    en: 'Task Title',
    de: 'Titel der Tätigkeit'
  },
  {
    key: 'backend_field_taskDescription',
    en: 'Task Description',
    de: 'Beschreibung der Tätigkeit'
  },
  {
    key: 'backend_field_requirementTitle',
    en: 'Requirement Title',
    de: 'Titel der Voraussetzungen'
  },
  {
    key: 'backend_field_requirementDescription',
    en: 'Requirement Description',
    de: 'Beschreibung der Voraussetzungen'
  },
  {
    key: 'backend_field_contactInfo',
    en: 'Contact Info',
    de: 'Kontakt'
  },
  {
    key: 'backend_field_boxImageLink',
    en: 'Box Image Link',
    de: 'Link zum Bild in der Box'
  },
  {
    key: 'backend_field_startDate',
    en: 'Start Date',
    de: 'Startdatum des Angebot'
  },
  {
    key: 'backend_export_shops',
    en: 'Export Shops',
    de: 'Shops Exportieren'
  },
  {
    key: 'backend_export_jobs',
    en: 'Export Jobs',
    de: 'Jobs Exportieren'
  },
  {
    key: 'backend_export_deals',
    en: 'Export Deals',
    de: 'Angebote Exportieren'
  },
  {
    key: 'backend_create_faqquestions',
    en: 'FAQ Create Question',
    de: 'FAQ Frage erstellen'
  },
  {
    key: 'backend_page_name_faqcategories',
    en: 'FAQ Categories',
    de: 'FAQ Kategorien'
  },
  {
    key: 'backend_page_name_faqquestions',
    en: 'FAQ Questions',
    de: 'FAQ Fragen'
  },
  {
    key: 'backend_page_name_faqsuggestions',
    en: 'FAQ Suggestions',
    de: 'FAQ Verbesserungsvorschläge'
  },
  {
    key: 'backend_page_name_contactrequests',
    en: 'Contact Requests',
    de: 'Kontaktanfragen'
  },
  {
    key: 'backend_page_name_contactresponses',
    en: 'Contact Responses',
    de: 'Kontakt Nachrichten'
  },
  {
    key: 'backend_create_faqcategories',
    en: 'Create Category',
    de: 'Kategorie Erstellen'
  },
  {
    key: 'backend_create_faqquestion',
    en: 'Create Question',
    de: 'Frage Erstellen'
  },
  {
    key: 'backend_field_clicksDesktop',
    en: 'Clicks Desktop',
    de: 'Clicks Desktop'
  },
  {
    key: 'backend_field_clicksMobile',
    en: 'Clicks Mobile',
    de: 'Clicks Mobile'
  },
  {
    key: 'backend_field_clicksTV',
    en: 'Clicks TV',
    de: 'Clicks TV'
  },
  {
    key: 'backend_field_clicksTV',
    en: 'Clicks TV',
    de: 'Clicks TV'
  },
  {
    key: 'backend_create_faqsuggestion',
    en: 'Create Suggestion',
    de: 'Vorschlag Erstellen'
  },
  {
    key: 'backend_field_suggestion',
    en: 'Suggestion',
    de: 'Vorschlag'
  },
  {
    key: 'backend_field_faq',
    en: 'FAQ',
    de: 'FAQ'
  },
  {
    key: 'backend_create_contactrequest',
    en: 'Create Contactrequest',
    de: 'Kontaktnachricht erstellen'
  },
  {
    key: 'backend_field_response',
    en: 'Response',
    de: 'Antwort'
  },
  {
    key: 'backend_field_request',
    en: 'E-Mail',
    de: 'E-Mail'
  },
  {
    key: 'backend_dashboard_item_requests_count',
    en: 'Requests',
    de: 'Anfragen'
  },
  {
    key: 'backend_dashboard_item_requests_count_open',
    en: 'Open Requests',
    de: 'Offene Anfragen'
  },
  {
    key: 'backend_dashboard_item_requests_count_processing',
    en: 'Processing Requests',
    de: 'Anfragen in bearbeitung'
  },
  {
    key: 'backend_dashboard_item_requests_count_done',
    en: 'Requests Done',
    de: 'Anfragen erledigt'
  },
  {
    key: 'backend_field_detailsLink',
    en: 'Details Link',
    de: 'Details Link'
  },
  {
    key: 'backend_field_optionalDealInfo',
    en: 'Optional Info Link',
    de: 'Optionale Informations Link'
  },
  {
    key: 'backend_label_annotation',
    en: 'Annotation',
    de: 'Anmerkung'
  },
  {
    key: 'backend_page_name_foods',
    en: 'Food Shops',
    de: 'Food Shops'
  },
  {
    key: 'backend_export_foods',
    en: 'Export Food Shops',
    de: 'Food Shops exportieren'
  },
  {
    key: 'backend_create_foods',
    en: 'Create Food Shop',
    de: 'Food Shop erstellen'
  },
  {
    key: 'backend_page_name_shopsSlider',
    en: 'Home Shops Slider',
    de: 'Startseite Shops Slider'
  },
  {
    key: 'backend_create_shopsSlider',
    en: 'Add new Shops Image',
    de: 'Neuen Shop einfügen'
  },
  {
    key: 'backend_export_shopsSlider',
    en: 'Export Shops Slider',
    de: 'Shops Slider exportieren'
  },
  {
    key: 'backend_field_imageLink',
    en: 'Image Link',
    de: 'Bild URL'
  },
  {
    key: 'backend_field_subShops',
    en: 'Sub Shops',
    de: 'Unter-Shops'
  },
  {
    key: 'backend_field_shopUrl',
    en: 'Shop URL',
    de: 'Shop Link'
  },
  {
    key: 'backend_field_imageUrl',
    en: 'Image URL',
    de: 'Bild Link'
  },
  {
    key: 'backend_page_name_contactcategories',
    en: 'Contact Categories',
    de: 'Kontakt Kategorien'
  },
  {
    key: 'backend_page_name_contactinputs',
    en: 'Contact Inputs',
    de: 'Kontakt Eingaben'
  },
  {
    key: 'backend_page_name_contactselects',
    en: 'Contact Selects',
    de: 'Kontakt Auswahlen'
  },
  {
    key: 'backend_field_label',
    en: 'Label',
    de: 'Labels'
  },
  {
    key: 'backend_create_contactcategories',
    en: 'Create Contact Category',
    de: 'Kontakt Kategorie erstellen'
  },
  {
    key: 'backend_create_contactinputs',
    en: 'Create Contact Input',
    de: 'Kontakt Eingabe erstellen'
  },
  {
    key: 'backend_field_selections',
    en: 'Selections',
    de: 'Auswahlmöglichkeiten'
  },
  {
    key: 'backend_field_case',
    en: 'Case',
    de: 'Auswahl'
  },
  {
    key: 'backend_create_faqsuggestions',
    en: 'Create FAQ Suggestion',
    de: 'FAQ Verbesserungsvorschlag erstellen'
  },
  {
    key: 'backend_field_upper_category',
    en: 'Upper Category',
    de: 'Oberkategorie'
  },
  {
    key: 'backend_page_name_news',
    en: 'News',
    de: 'News'
  },
  {
    key: 'backend_export_news',
    en: 'Export News',
    de: 'News exportieren'
  },
  {
    key: 'backend_create_news',
    en: 'Create News',
    de: 'Neuen News erstellen'
  },
  {
    key: 'backend_page_name_pageContent',
    en: 'Page Content',
    de: 'Seiteninhalt'
  },
  {
    key: 'backend_field_pageUrl',
    en: 'Page URL',
    de: 'Url der Seite'
  },
  {
    key: 'backend_field_additionalContents',
    en: 'Page Content',
    de: 'Seiteninhalte'
  },
  {
    key: 'backend_export_pageContent',
    en: 'Export Page Content',
    de: 'Seiteninhalt exportieren'
  },
  {
    key: 'backend_create_pageContent',
    en: 'Create Page Content',
    de: 'Neue Seite erstellen'
  },
  {
    key: 'backend_field_content',
    en: 'Content',
    de: 'Inhalt'
  },
  {
    key: 'backend_field_contentTitle',
    en: 'Content Title',
    de: 'Überschrift'
  },
  {
    key: 'backend_field_previewText',
    en: 'Preview Text',
    de: 'Textvorschau'
  },
  {
    key: 'backend_field_popupImageLink',
    en: 'Popup Image Url',
    de: 'Popup Bild Link'
  },
  {
    key: 'backend_dashboard_title_outletcenterselb',
    en: 'Outlet Center Selb',
    de: 'Outlet Center Selb'
  },
  {
    key: 'backend_dashboard_number_of_shops',
    en: 'Number of Shops',
    de: 'Anzahl Shops'
  },
  {
    key: 'backend_dashboard_number_of_foods',
    en: 'Number of Foods',
    de: 'Anzahl Food'
  },
  {
    key: 'backend_dashboard_number_of_deals',
    en: 'Number of Deals',
    de: 'Anzahl Angebote'
  },
  {
    key: 'backend_dashboard_number_of_jobs',
    en: 'Number of Jobs',
    de: 'Anzahl Jobs'
  },
  {
    key: 'backend_dashboard_number_of_news',
    en: 'Number of News',
    de: 'Anzahl News'
  },
  {
    key: 'backend_field_optionalDealPage',
    en: 'Optional deal page',
    de: 'Optional Angebotsseite'
  },
  {
    key: 'backend_page_name_pageHome',
    en: 'Content - Home Page',
    de: 'Inhalt - Home Seite'
  },
  {
    key: 'backend_export_pageHome',
    en: 'Export Home Content',
    de: 'Home Inhalt exportieren'
  },
  {
    key: 'backend_create_pageHome',
    en: 'Create Home Content',
    de: 'Home Inhalt erstellen'
  },
  {
    key: 'backend_field_bannerSlider',
    en: 'Banner Slider',
    de: 'Banner Slider'
  },
  {
    key: 'backend_field_redTextBanner',
    en: 'Red Banner Content',
    de: 'Roter Banner Inhalt'
  },
  {
    key: 'backend_field_shopsSlider',
    en: 'Shops Slider',
    de: 'Shops Slider'
  },
  {
    key: 'backend_field_desktopImage',
    en: 'Desktop Image',
    de: 'Desktop Bild'
  },
  {
    key: 'backend_field_mobileImage',
    en: 'Mobile Image',
    de: 'Mobile Bild'
  },
  {
    key: 'backend_field_upperText',
    en: 'Upper Text',
    de: 'Text oben'
  },
  {
    key: 'backend_field_lowerText',
    en: 'Lower Text',
    de: 'Text unten'
  },
  {
    key: 'backend_field_optionalImage',
    en: 'Optional Image',
    de: 'Optionales Bild'
  },
  {
    key: 'backend_page_name_pageGewinnspiel',
    en: 'Content - Gewinnspiel Page',
    de: 'Inhalt - Gewinnspiel Seite'
  },
  {
    key: 'backend_export_pageGewinnspiel',
    en: 'Export Gewinnspiel Page',
    de: 'Gewinnspiel Seite exportieren'
  },
  {
    key: 'backend_create_pageGewinnspiel',
    en: 'Create Gewinnspiel Content',
    de: 'Gewinnspiel Inhalt erstellen'
  },
  {
    key: 'backend_field_textBullet',
    en: 'Text Bullet',
    de: 'Unterpunkt'
  },
  {
    key: 'backend_page_name_pageVermietung',
    en: 'Content - Vermietung Page',
    de: 'Inhalt - Vermietung Seite'
  },
  {
    key: 'backend_export_pageVermietung',
    en: 'Export Vermietung Page',
    de: 'Vermietungsseite exportieren'
  },
  {
    key: 'backend_create_pageVermietung',
    en: 'Create Vermietung Page',
    de: 'Vermietungsseite erstellen'
  },
  {
    key: 'backend_field_promotionContent',
    en: 'Promotion Content',
    de: 'Optionales Angebot'
  },
  {
    key: 'backend_field_optionalTitle',
    en: 'Optional Title',
    de: 'Überschrift'
  },
  {
    key: 'backend_field_optionalPhrase',
    en: 'Optional Phrase',
    de: 'Optionaler Titel'
  },
  {
    key: 'backend_create_title_mail',
    en: 'Create email',
    de: 'Email erstellen'
  },
  {
    key: 'backend_page_name_faqnews',
    en: 'FAQ Announcements',
    de: 'Meldungen'
  },
  {
    key: 'backend_create_faqnews',
    en: 'Create announcement',
    de: 'Neue Meldung'
  },
  {
    key: 'backend_create_title_mail',
    en: 'Create email',
    de: 'Email erstellen'
  },
  {
    key: 'backend_field_clicks',
    en: 'Clicks',
    de: 'Clicks'
  },
  {
    key: 'backend_dashboard_item_amount_of_faqquestions',
    en: 'FAQ Questions',
    de: 'FAQ Fragen'
  },
  {
    key: 'backend_dashboard_item_amount_of_faqquestions_active',
    en: 'FAQ Questions Active',
    de: 'FAQ Fragen aktiv'
  },
  {
    key: 'backend_dashboard_item_amount_of_faqcategories',
    en: 'FAQ Categories',
    de: 'FAQ Kategorien'
  },
  {
    key: 'backend_dashboard_item_amount_of_faqsuggestions',
    en: 'FAQ Suggestions',
    de: 'FAQ Vorschläge'
  },
  {
    key: 'backend_dashboard_item_amount_of_contactinputs',
    en: 'Contact Inputs',
    de: 'Kontakt Eingaben'
  },
  {
    key: 'backend_dashboard_item_amount_of_contactselectes',
    en: 'Contact Selects',
    de: 'Kontakt Auswahlen'
  },
  {
    key: 'backend_dashboard_item_amount_of_contactcategories',
    en: 'Contact Categories',
    de: 'Kontakt Kategorien'
  },
  {
    key: 'backend_page_name_logs',
    en: 'Logs',
    de: 'Logs'
  },
  {
    key: 'backend_field_header',
    en: 'Header',
    de: 'Header'
  },
  {
    key: 'backend_field_body',
    en: 'Body',
    de: 'Body'
  },
  {
    key: 'backend_field_url',
    en: 'URL',
    de: 'URL'
  },
  {
    key: 'backend_field_method',
    en: 'Method',
    de: 'Method'
  },
  {
    key: 'backend_field_affiliate',
    en: 'Partner',
    de: 'Partner'
  },
  {
    key: 'backend_page_name_ongoingmedialeads',
    en: 'Ongoingmedia Leads',
    de: 'Ongoingmedia Leads'
  },
  {
    key: 'import_leads',
    en: 'Import Leads',
    de: 'Import Leads'
  },
  {
    key: 'import_registrationcode',
    en: 'Import Codes',
    de: 'Import Codes'
  },
  {
    key: 'export_leads',
    en: 'Export Leads',
    de: 'Export Leads'
  },
  {
    key: 'update_leads',
    en: 'Update Leads',
    de: 'Update Leads'
  },
  {
    key: 'backend_create_title_leads',
    en: 'Create Leads',
    de: 'Lead erstellen'
  },
  {
    key: 'backend_upload_leads_title',
    en: 'Upload Leads',
    de: 'Leads hochladen'
  },
  {
    key: 'backend_field_customer_id',
    en: 'Customer ID',
    de: 'Kunden ID'
  },
  {
    key: 'backend_field_time',
    en: 'Time',
    de: 'Zeit'
  },
  {
    key: 'backend_field_salutation',
    en: 'Salutation',
    de: 'Anrede'
  },
  {
    key: 'backend_field_firstname',
    en: 'Firstname',
    de: 'Vorname'
  },
  {
    key: 'backend_field_lastname',
    en: 'Lastname',
    de: 'Nachname'
  },
  {
    key: 'backend_field_date',
    en: 'Date',
    de: 'Datum'
  },
  {
    key: 'backend_field_postcode',
    en: 'Postcode',
    de: 'PLZ'
  },
  {
    key: 'backend_field_city',
    en: 'City',
    de: 'Stadt'
  },
  {
    key: 'backend_field_country',
    en: 'Country',
    de: 'Land'
  },
  {
    key: 'backend_field_address1',
    en: 'Address 1',
    de: 'Adresse 1'
  },
  {
    key: 'backend_field_address2',
    en: 'Address 2',
    de: 'Adresse 2'
  },
  {
    key: 'backend_field_address3',
    en: 'Address 3',
    de: 'Adresse 3'
  },
  {
    key: 'backend_field_address4',
    en: 'Address 4',
    de: 'Adresse 4'
  },
  {
    key: 'backend_field_phoneFixed',
    en: 'Phone Fixed',
    de: 'Telefon'
  },
  {
    key: 'backend_field_phoneMobile',
    en: 'Phone Mobile',
    de: 'Mobil'
  },
  {
    key: 'backend_field_email',
    en: 'Email',
    de: 'E-Mail'
  },
  {
    key: 'backend_field_list',
    en: 'List',
    de: 'Liste'
  },
  {
    key: 'backend_field_campaign',
    en: 'Campaign',
    de: 'Kampagne'
  },
  {
    key: 'backend_field_campaign',
    en: 'Campaign',
    de: 'Kampagne'
  },
  {
    key: 'backend_field_source',
    en: 'Source',
    de: 'Quelle'
  },
  {
    key: 'backend_field_maxigains',
    en: 'Maxigains',
    de: 'Maxigains'
  },
  {
    key: 'backend_field_costPer000',
    en: 'CostPer000',
    de: 'CostPer000'
  },
  {
    key: 'backend_field_segment',
    en: 'Segment',
    de: 'Segment'
  },
  {
    key: 'backend_unfilled',
    en: 'Unfilled',
    de: 'Unausgefüllt'
  },
  {
    key: 'backend_field_priority',
    en: 'Priority',
    de: 'Priorität'
  },
  {
    key: 'backend_page_name_leadProcess',
    en: 'Lead process',
    de: 'Lead process'
  },
  {
    key: 'backend_create_title_leadProcess',
    en: 'Create lead process',
    de: 'Create lead process'
  },
  {
    key: 'backend_field_ignoreHeader',
    en: 'Skip first line',
    de: 'Überspringe die erste Zeile'
  },
  {
    key: 'backend_field_leadsTotalCount',
    en: 'Successfully uploaded {{count}} leads',
    de: 'Erfolgreich {{count}} leads hochgeladen'
  },
  {
    key: 'backend_field_terminated_at',
    en: 'Termination date',
    de: 'Kündigungsdatum'
  },
  {
    key: 'backend_field_terminated_at',
    en: 'Termination date',
    de: 'Kündigungsdatum'
  },
  {
    key: 'backend_field_mediaCode',
    en: 'Media Code',
    de: 'Media Code'
  },
  {
    key: 'create_report',
    en: 'Export Report',
    de: 'Export Report'
  },
  {
    key: 'backend_field_objection_date',
    en: 'Objection Date',
    de: 'Wiederspruchsdatum'
  },
  {
    key: 'backend_action_send_welcome_mail',
    en: 'Send Welcome Email',
    de: 'Willkommensmail Verschicken'
  },
  {
    key: 'message_email_sucessfully_sent',
    en: 'The email was sent successfully',
    de: 'Die E-Mail wurde erfolgreich versendet'
  }, 
  {
    key: 'backend_field_welcomeEmailStatus',
    en: 'Welcome Email Status',
    de: 'Willkommensmail Status'
  },
  {
    key: 'backend_field_interfaceId',
    en: 'InterfaceID',
    de: 'InterfaceId'
  },
  {
    key: 'return_debit',
    en: 'Import Return Debit',
    de: 'Rücklastschriften Importieren'
  },
  {
    key: 'return_debit_special',
    en: 'Import Special Return Debit',
    de: 'Spezial Rücklastschriften Importieren'
  },
  {
    key: 'export_media_code_customers',
    en: 'Export Customers',
    de: 'Kunden Exportieren'
  },
  {
    key: 'view_customer_title_notes',
    en: 'Notes',
    de: 'Notizen'
  },
  {
    key: 'view_customer_title_new_note',
    en: 'New Note',
    de: 'Neue Notiz'
  },
  {
    key: 'backend_view_customer_note_created',
    en: 'Note created',
    de: 'Notiz erstellt'
  },
  {
    key: 'backend_dashboard_title_easyplus',
    en: 'EasyPlus MediaCode',
    de: 'EasyPlus MediaCode'
  },
  {
    key: 'backend_dashboard_item_amount_of_mediaCode_customers',
    en: 'Customers',
    de: 'Kunden'
  },
  {
    key: 'backend_dashboard_item_amount_of_active_mediaCode_customers',
    en: 'Active Customers',
    de: 'Aktive Kunden'
  },
  {
    key: 'backend_dashboard_item_amount_of_deactivated_mediaCode_customers',
    en: 'Terminated Customers',
    de: 'Gekündigte Kunden'
  },
  {
    key: 'backend_dashboard_item_amount_of_deleted_mediaCode_customers',
    en: 'Deleted Customers',
    de: 'Gelöschte Kunden'
  },
  {
    key: 'backend_dashboard_item_amount_of_active_debits',
    en: 'Active Debits',
    de: 'Aktive Lastschriften'
  },
  {
    key: 'backend_dashboard_item_amount_of_rejected_debits',
    en: 'Rejected Debits',
    de: 'Rücklastschriften'
  },
  {
    key: 'backend_dashboard_item_amount_of_sent_welcome_mails',
    en: 'Sent Welcome Mails',
    de: 'Versendete Willkommensmails'
  },
  {
    key: 'backend_dashboard_item_amount_of_opened_welcome_mails',
    en: 'Opened Welcome Mails',
    de: 'Geöffnete Willkommensmails'
  },
  {
    key: 'backend_dashboard_item_amount_of_clicked_welcome_mails',
    en: 'Clicked Welcome Mails',
    de: 'Geklickte Willkommensmails'
  },
  {
    key: 'backend_dashboard_item_amount_of_errored_welcome_mails',
    en: 'Errored Welcome Mails',
    de: 'Fehlerhafte Willkommensmails'
  },
  {
    key: 'backend_dashboard_item_amount_of_blocked_welcome_mails',
    en: 'Blocked Welcome Mails',
    de: 'Blockierte Willkommensmails'
  },
  {
    key: 'backend_dashboard_item_amount_of_blacklisted_welcome_mails',
    en: 'Blacklisted Welcome Mails',
    de: 'Blacklisted Willkommensmails'
  },
  {
    key: 'backend_dashboard_item_amount_of_customers_without_bic',
    en: 'Customer Without BIC',
    de: 'Kunden ohne BIC'
  },
  {
    key: 'backend_field_ip_address',
    en: 'IP Address',
    de: 'IP-Adresse'
  },
  {
    key: 'backend_page_name_media_codes',
    en: 'Media Codes',
    de: 'Media Codes'
  },
  {
    key: 'backend_field_start_date',
    en: 'Start',
    de: 'Start'
  },
  {
    key: 'backend_field_end_date',
    en: 'End',
    de: 'Ende'
  },
  {
    key: 'backend_field_end_of_free_charge',
    en: 'Free Charge End',
    de: 'Gratiszeit Ende'
  },
  {
    key: 'backend_field_next_booking_date',
    en: 'Next Booking Date',
    de: 'Nächte Buchung'
  },
  {
    key: 'backend_field_supplier',
    en: 'Supplier',
    de: 'Lieferant'
  }
]