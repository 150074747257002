
import React, { useEffect, useState } from 'react'
import currency from '../utils/formatCurrency'
import { translate as tl } from '../utils/translation'

const Dashboard = ({ availableModules, config }) => {

  const [allowedValues, setAllowedValues] = useState(false)
  const [allowedModules, setAllowedModules] = useState(false)

  useEffect(() => {

    const buildDashboard = async () => {

      try {

        const modulesWithDashboard = availableModules
          .filter(module => module.dashboard)
          .sort((a, b) => a.dashboard.order - b.dashboard.order)

        const modulesWithDashboardData = modulesWithDashboard.filter(module => module.dashboard.data)

        const asyncCallbacks = []
        const paramsToAdd = []
        const valuesToSet = {}

        modulesWithDashboardData.forEach(dashboardData => {
          const { dashboard: { items, data } = {} } = dashboardData

          if (items && items.length) {

            if (items.every(item => !item.data)) {
              asyncCallbacks.push(data())
              items.forEach(item => paramsToAdd.push(item.count))
              return
            }

            items.forEach(item => {
              asyncCallbacks.push(item.data ? item.data() : data())
              paramsToAdd.push(item.count)
            })
          }
        })

        if (asyncCallbacks.length) {
          const callbacksData = await Promise.all(asyncCallbacks)

          const normalizedData = callbacksData.reduce((accum, values) => {
            const keys = Object.keys(values)
            keys.forEach(key => accum[key] = values[key])
            return { ...accum }
          }, {})

          paramsToAdd.forEach(param => valuesToSet[param] = normalizedData[param])
        }

        setAllowedValues(valuesToSet)
        setAllowedModules(modulesWithDashboard)

      } catch ({ message }) {
        console.log("ERROR_buildDashboard()", message)
      }
    }

    availableModules && buildDashboard()
  }, [availableModules])


  const getFormattedData = (type, name) => {
    if (type === 'currency') return currency(allowedValues[name], config)
    if (type === 'percent') return (allowedValues[name] >= 0) ? `${allowedValues[name].toFixed(2)} %` : ''
    return allowedValues[name]
  }

  return allowedModules
    ? <div className='container-fluid'>
      {allowedModules.map((module, key) => {

        const headerTitle = tl((module.dashboard.title || ''))

        return <div key={key} className='row clearfix'>
          <div className='container-fluid'>
            <div className='block-header'>
              <h2>{headerTitle}</h2>
            </div>
          </div>

          {module.dashboard.items.map((item, key) => {

            const { title, type, count, icon } = item

            const textLabel = tl(title)
            const numberValue = getFormattedData(type, count)

            return <div key={key} className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
              <div className='info-box-3 hover-zoom-effect'>
                <div className='icon'>
                  <i className='material-icons col-light-blue'>{icon}</i>
                </div>
                <div className='content'>
                  <div className='text'>{textLabel}</div>
                  <div className='number'>{numberValue}</div>
                </div>
              </div>
            </div>

          })}
        </div>
      })}

    </div>
    : null
}

export default Dashboard